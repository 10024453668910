import { get } from 'lodash';
import { Resource as ResourceType } from '../config/types';
import Resource from '../models/Resource';
import { config } from './config';

/**
 * Gets the authorized resources based on the provided data and options.
 * @param data - The data containing authorized resources information.
 * @returns An array of authorized resources.
 */
export const getAuthorizedResources = (
  data: Record<string, any>
): Resource[] => {
  const authorizedResourceNames = get(
    data,
    'authorized_resources'
  ) as ResourceType['name'][];

  return config.resources.filter((resource: Resource) =>
    authorizedResourceNames.includes(resource.name)
  );
};
