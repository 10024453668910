import React, { FunctionComponent } from 'react';
import { ReferenceField, ImageField, TextField } from 'react-admin';
import { CompanyFieldProps } from './types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  fields: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: '40px',
    },
  },
});

const CompanyField: FunctionComponent<CompanyFieldProps> = (
  props: CompanyFieldProps
) => {
  const { source, reference, record } = props;
  const classes = useStyles();

  if (!record) return null;

  return (
    <div className={classes.fields}>
      <ReferenceField
        source={source}
        reference={reference}
        record={record}
        basePath={`/${reference}`}
      >
        <ImageField source={'logo_url'} />
      </ReferenceField>
      <ReferenceField
        source={source}
        reference={reference}
        record={record}
        basePath={`/${reference}`}
      >
        <TextField source={'name'} />
      </ReferenceField>
    </div>
  );
};
export default CompanyField;
