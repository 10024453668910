import React, { ComponentType, FunctionComponent } from 'react';
import { useRef } from 'react';
import useConfig from '../../hooks/useConfig';

/**
 * Sign in as client or expert from backoffice admin in user view/edit page
 *
 * @param Component
 */
function withRedirectSignInAs(
  Component: ComponentType<any>
): FunctionComponent {
  const Extension: FunctionComponent<Record<string, any>> = (props) => {
    const ref = useRef<HTMLAnchorElement>(null);
    const config = useConfig();

    function afterUpdate(data: Record<string, any>) {
      if (data) {
        const href = encodeURI(
          `${config.uiUrl}${props.basePath}/${props.data.id}?token=${data.token}&scopes=${data.scopes}`
        );

        if (ref && ref.current) {
          const element = ref.current;
          element.setAttribute('href', href);
          element.setAttribute('target', '_blank');
          element.click();
        }
      }
    }

    return (
      <>
        <Component {...props} afterUpdate={afterUpdate} />
        <a ref={ref} />
      </>
    );
  };

  return Extension;
}

export default withRedirectSignInAs;
