import { Resource as ResourceType } from '../types';

export const legalInformation: ResourceType = {
  name: 'legal_informations',
  label: 'resource.legal_informations.label',
  hideResource: false,
  attributes: [
    {
      key: 'company_id',
      label: 'resource.legal_informations.field.company_id.label',
      type: 'reference',
      reference: 'companies',
    },
    {
      key: 'corporate_name',
      label: 'resource.legal_informations.field.corporate_name.label',
      type: 'text',
    },
    {
      key: 'siret',
      label: 'resource.legal_informations.field.siret.label',
      type: 'text',
    },
    {
      key: 'vat_number',
      label: 'resource.legal_informations.field.vat_number.label',
      type: 'text',
    },
    {
      key: 'vat_rate',
      label: 'resource.legal_informations.field.vat_rate.label',
      type: 'number',
    },
    {
      key: 'street',
      label: 'resource.legal_informations.field.street.label',
      type: 'text',
    },
    {
      key: 'zipcode',
      label: 'resource.legal_informations.field.zipcode.label',
      type: 'text',
    },
    {
      key: 'city',
      label: 'resource.legal_informations.field.city.label',
      type: 'text',
    },
    {
      key: 'created_at',
      label: 'resource.legal_informations.field.created_at.label',
      type: 'date',
    },
    {
      key: 'iban',
      label: 'resource.legal_informations.field.iban.label',
      type: 'text',
    },
    {
      key: 'bic',
      label: 'resource.legal_informations.field.bic.label',
      type: 'text',
    },
    {
      key: 'nationality',
      label: 'resource.legal_informations.field.nationality.label',
      type: 'country',
    },
    {
      key: 'payment_terms',
      label: 'resource.legal_informations.field.payment_terms.label',
      type: 'wysiwyg',
    },
    {
      key: 'gender',
      label: 'resource.legal_informations.field.gender.label',
      type: 'select',
      choices: [
        {
          id: 'female',
          name: 'resource.legal_informations.field.gender.choices.female.label',
        },
        {
          id: 'male',
          name: 'resource.legal_informations.field.gender.choices.male.label',
        },
        {
          id: 'other',
          name: 'resource.legal_informations.field.gender.choices.other.label',
        },
      ],
    },
    {
      key: 'first_name',
      label: 'resource.users.field.first_name.label',
      type: 'text',
    },
    {
      key: 'last_name',
      label: 'resource.users.field.last_name.label',
      type: 'text',
    },
    {
      key: 'birth_date',
      label: 'resource.legal_informations.field.birth_date.label',
      type: 'date',
    },
    {
      key: 'country_of_residence',
      label: 'resource.legal_informations.field.country_of_residence.label',
      type: 'country',
    },
    {
      key: 'description',
      label: 'resource.legal_informations.field.description.label',
      type: 'text',
    },
    {
      key: 'country',
      label: 'resource.legal_informations.field.country.label',
      type: 'country',
    },
    {
      key: 'banking_holder_name',
      label: 'resource.legal_informations.field.banking_holder_name.label',
      type: 'text',
    },
    {
      key: 'banking_agency',
      label: 'resource.legal_informations.field.banking_agency.label',
      type: 'text',
    },
    {
      key: 'billing_first_name',
      label: 'resource.users.field.first_name.label',
      type: 'text',
    },
    {
      key: 'billing_last_name',
      label: 'resource.users.field.last_name.label',
      type: 'text',
    },
    {
      key: 'billing_mail',
      label: 'resource.users.field.email.label',
      type: 'email',
    },
    {
      key: 'billing_phone',
      label: 'resource.users.field.phone.label',
      type: 'phone',
    },
    {
      key: 'billing_street',
      label: 'resource.legal_informations.field.billing_street.label',
      type: 'text',
    },
    {
      key: 'billing_zipcode',
      label: 'resource.legal_informations.field.zipcode.label',
      type: 'text',
    },
    {
      key: 'billing_city',
      label: 'resource.legal_informations.field.city.label',
      type: 'text',
    },
    {
      key: 'billing_country',
      label: 'resource.legal_informations.field.country.label',
      type: 'country',
    },
    {
      key: 'id',
      label: 'resource.legal_informations.field.id.label',
      type: 'text',
    },
    {
      key: 'updated_at',
      label: 'resource.legal_informations.field.updated_at.label',
      type: 'date',
    },
    {
      key: 'days_delayed',
      label: 'resource.legal_informations.field.days_delayed.label',
      type: 'number',
    },
    {
      key: 'end_month',
      label: 'resource.legal_informations.field.end_month.label',
      type: 'boolean',
    },
    {
      key: 'precise_date',
      label: 'resource.legal_informations.field.precise_date.label',
      type: 'number',
    },
    {
      key: 'leader_id',
      label: 'resource.legal_information.field.user_id.label',
      type: 'reference',
      reference: 'users',
    },
    {
      key: 'legal_form',
      label: 'resource.legal_informations.field.legal_form.label',
      type: 'select',
    },
    {
      key: 'company_created_at',
      label: 'resource.legal_informations.field.company_created_at.label',
      type: 'date',
    },
    {
      key: 'rcs',
      label: 'resource.legal_informations.field.rcs.label',
      type: 'text',
    },
    {
      key: 'naf_ape',
      label: 'resource.legal_informations.field.naf_ape.label',
      type: 'text',
    },
    {
      key: 'n_1_revenues',
      label: 'resource.legal_informations.field.n_1_revenues.label',
      type: 'number',
    },
    {
      key: 'leader_kind',
      type: 'text',
    },
  ],
  pages: {
    list: {
      hasEditButton: true,
      search: {
        enabled: true,
        label: 'filter.search.label',
      },
      filters: [
        {
          source: 'company_id',
          type: 'reference',
          child: {
            type: 'autocomplete',
            // Options to pass to ReferenceInput
            options: {
              perPage: 10,
              allowEmpty: false,
            },
          },
        },
      ],
      fields: [
        {
          source: 'leader_id',
          child: {
            source: 'users.company_name',
          },
        },
        {
          source: 'corporate_name',
        },
        {
          source: 'siret',
        },
        {
          source: 'vat_number',
        },
        {
          source: 'vat_rate',
        },
        {
          source: 'street',
          sortable: false,
        },
        {
          source: 'zipcode',
        },
        {
          source: 'city',
        },
        {
          source: 'created_at',
        },
      ],
    },
    edit: {
      left: {
        tabs: [
          {
            key: 'information',
            title: 'resource.legal_informations.tab.information.title',
            sections: [
              {
                key: 'legal_representative',
                title:
                  'resource.legal_informations.section.legal_representative.title',
                layout: [
                  ['first_name', 'last_name'],
                  ['gender', 'birth_date'],
                  ['nationality'],
                  ['country_of_residence'],
                ],
                inputs: {
                  first_name: {
                    width: 6,
                    validators: [{ key: 'required' }],
                  },
                  last_name: {
                    width: 6,
                    validators: [{ key: 'required' }],
                  },
                  gender: {
                    width: 6,
                    validators: [{ key: 'required' }],
                  },
                  birth_date: {
                    width: 6,
                    validators: [{ key: 'required' }],
                  },
                  nationality: {
                    validators: [{ key: 'required' }],
                  },
                  country_of_residence: {
                    validators: [{ key: 'required' }],
                  },
                },
              },
              {
                key: 'company_details',
                title:
                  'resource.legal_informations.section.company_details.title',
                layout: [
                  ['legal_form', 'company_created_at'],
                  ['corporate_name', 'siret'],
                  ['rcs', 'naf_ape'],
                  ['description', 'vat_number'],
                  ['vat_rate'],
                  ['street', 'zipcode'],
                  ['city', 'country'],
                ],
                inputs: {
                  legal_form: {
                    width: 6,
                    validators: [{ key: 'required' }],
                    rules: {
                      hide: [
                        {
                          property: 'leader_kind',
                          op: 'eq',
                          value: 'client',
                        },
                      ],
                    },
                    extensions: [
                      {
                        key: 'limitedList',
                        params: {
                          listKey: 'legal_form.list',
                        },
                      },
                    ],
                  },
                  company_created_at: {
                    width: 6,
                    validators: [{ key: 'required' }],
                    rules: {
                      hide: [
                        {
                          property: 'leader_kind',
                          op: 'eq',
                          value: 'client',
                        },
                      ],
                    },
                  },
                  corporate_name: {
                    width: 6,
                    validators: [{ key: 'required' }],
                  },
                  siret: {
                    width: 6,
                    validators: [{ key: 'required' }],
                  },
                  rcs: {
                    width: 6,
                    rules: {
                      hide: [
                        {
                          property: 'leader_kind',
                          op: 'eq',
                          value: 'client',
                        },
                      ],
                    },
                  },
                  naf_ape: {
                    width: 6,
                    rules: {
                      hide: [
                        {
                          property: 'leader_kind',
                          op: 'eq',
                          value: 'client',
                        },
                      ],
                    },
                  },
                  description: {
                    validators: [{ key: 'required' }],
                  },
                  vat_number: {
                    validators: [{ key: 'required' }],
                  },
                  vat_rate: {
                    validators: [{ key: 'required' }],
                    rules: {
                      hide: [
                        {
                          property: 'leader_kind',
                          op: 'eq',
                          value: 'client',
                        },
                      ],
                    },
                  },
                  street: {
                    width: 6,
                    validators: [{ key: 'required' }],
                  },
                  zipcode: {
                    width: 6,
                    validators: [{ key: 'required' }],
                  },
                  city: {
                    width: 6,
                    validators: [{ key: 'required' }],
                  },
                  country: {
                    width: 6,
                    validators: [{ key: 'required' }],
                  },
                },
              },
              {
                key: 'billing_contact',
                title:
                  'resource.legal_informations.section.billing_contact.title',
                layout: [
                  ['billing_first_name', 'billing_last_name'],
                  ['billing_mail', 'billing_phone'],
                  ['billing_street', 'billing_zipcode'],
                  ['billing_city', 'billing_country'],
                  ['payment_terms'],
                ],
                inputs: {
                  billing_first_name: {
                    width: 6,
                    validators: [{ key: 'required' }],
                  },
                  billing_last_name: {
                    width: 6,
                    validators: [{ key: 'required' }],
                  },
                  billing_mail: {
                    width: 6,
                    validators: [{ key: 'required' }, { key: 'email' }],
                  },
                  billing_phone: {
                    width: 6,
                    validators: [{ key: 'required' }],
                  },
                  billing_street: {
                    width: 6,
                    validators: [{ key: 'required' }],
                  },
                  billing_zipcode: {
                    width: 6,
                    validators: [{ key: 'required' }],
                  },
                  billing_city: {
                    width: 6,
                    validators: [{ key: 'required' }],
                  },
                  billing_country: {
                    width: 6,
                    validators: [{ key: 'required' }],
                  },
                  payment_terms: {
                    rules: {
                      hide: [
                        {
                          property: 'leader_kind',
                          op: 'eq',
                          value: 'expert',
                        },
                      ],
                    },
                  },
                },
              },
              {
                key: 'bank_details',
                title: 'resource.legal_informations.section.bank_details.title',
                layout: [
                  ['banking_holder_name', 'banking_agency'],
                  ['iban', 'bic'],
                ],
                rules: {
                  hide: [
                    {
                      property: 'leader_kind',
                      op: 'eq',
                      value: 'client',
                    },
                  ],
                },
                inputs: {
                  banking_holder_name: {
                    validators: [{ key: 'required' }],
                  },
                  banking_agency: {
                    validators: [{ key: 'required' }],
                  },
                  iban: {
                    validators: [{ key: 'required' }, { key: 'iban' }],
                  },
                  bic: {
                    validators: [{ key: 'required' }, { key: 'bic' }],
                  },
                },
              },
              {
                key: 'payment_period',
                title:
                  'resource.legal_informations.section.payment_period.title',
                layout: [['days_delayed', 'end_month'], ['precise_date']],
                rules: {
                  hide: [
                    {
                      property: 'leader_kind',
                      op: 'eq',
                      value: 'expert',
                    },
                  ],
                },
                inputs: {
                  days_delayed: {
                    validators: [
                      { key: 'minValue', value: 0 },
                      { key: 'maxValue', value: 60 },
                    ],
                  },
                  end_month: {},
                  precise_date: {
                    validators: [
                      { key: 'minValue', value: 0 },
                      { key: 'maxValue', value: 31 },
                    ],
                    rules: {
                      disabled: [
                        {
                          property: 'end_month',
                          op: 'eq',
                          value: false,
                        },
                      ],
                    },
                  },
                },
              },
            ],
          },
        ],
      },
      right: [
        {
          sections: [
            {
              key: 'details',
              title: 'resource.legal_informations.section.details.title',
              layout: [
                ['id'],
                ['created_at'],
                ['updated_at'],
                ['leader_id'],
                ['company_name'],
              ],
              inputs: {
                id: {
                  disabled: true,
                },
                created_at: {
                  disabled: true,
                },
                updated_at: {
                  disabled: true,
                },
              },
              fields: {
                leader_id: {
                  addLabel: true,
                  label: 'resource.legal_informations.field.leader_id.label',
                  child: {
                    type: 'text',
                    source: 'users.full_name',
                  },
                },
                company_name: {
                  source: 'leader_id',
                  addLabel: true,
                  link: false,
                  label: 'resource.legal_informations.field.company_name.label',
                  child: {
                    type: 'text',
                    source: 'users.company_name',
                  },
                },
              },
            },
          ],
        },
      ],
    },
  },
};
