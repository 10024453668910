import React, { FunctionComponent } from 'react';
import { RecommendationFieldProps } from './types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  comment: {
    fontSize: '0.875rem',
  },
});

const RecommendationField: FunctionComponent<RecommendationFieldProps> = (
  props
) => {
  const { source, record } = props;
  const classes = useStyles();

  if (!record || !source || !record[source]) return null;
  const recommendationArray = record[source].split('\n');

  return (
    <div>
      {recommendationArray.map((comment: string, index: number) => (
        <div key={`${comment}_${index}`} className={classes.comment}>
          {comment}
        </div>
      ))}
    </div>
  );
};

export default RecommendationField;
