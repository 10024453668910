import React, {
  Fragment,
  FunctionComponent,
  useState,
  createElement,
  useEffect,
} from 'react';
import { first } from 'lodash';
import {
  Button as RaButton,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
  useTranslate,
  useUpdate,
} from 'react-admin';
import ConfirmationModal from '../../../modals/ConfirmationModal';
import { getIconComponentFromName } from '../../../../helpers/icons';
import checkConditions from '../../../../helpers/conditions';
import { makeStyles } from '@material-ui/core/styles';
import getUrlPaths from '../../../../helpers/getUrlPath';
import { getErrorMessage } from '../../../../helpers/error';
import { UpdateButtonProps } from './types';

const useStyles = makeStyles({
  button: {
    marginLeft: '10px',
  },
});

const UpdateButton: FunctionComponent<UpdateButtonProps> = (props) => {
  const {
    selectedIds = [],
    resource,
    values,
    label,
    extraUrl,
    withConfirmation,
    confirmation,
    icon,
    data,
    afterUpdate = null,
    disabled: disabledRules,
  } = props;
  const classes = useStyles();

  if (typeof resource === 'undefined') {
    throw new Error(`Missing prop resource for UpdateButton component`);
  }

  const t = useTranslate();
  const [showDialog, setShowDialog] = useState(false);

  const [update, { data: result, loading, error, loaded }] = (function () {
    const ids = getUrlPaths(selectedIds, extraUrl);
    if (Array.isArray(selectedIds)) {
      return useUpdateMany(resource, ids, values, {
        onSuccess: () => {
          refresh();
          notify('notify.success.update');
          unselectAll(resource);
        },
        onFailure: (errors: Record<string, any>) => {
          if (Array.isArray(errors) && errors.length) {
            errors.forEach((error) => {
              const errorMessage = getErrorMessage(error);
              if (typeof errorMessage !== 'undefined') {
                notify(`notify.error.${errorMessage}`, 'error', {
                  id: error.id,
                });
              }
            });
          } else {
            notify('notify.error.update', 'error');
          }
        },
      });
    } else {
      const id = first(ids);

      return useUpdate(resource, id, values);
    }
  })();

  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();

  function handleClick() {
    update();
    setShowDialog(false);
  }

  function handleOpenModal() {
    setShowDialog(true);
  }

  function handleCloseModal() {
    setShowDialog(false);
  }

  const disabled =
    disabledRules && data
      ? checkConditions(
          {
            rules: disabledRules,
            satisfy: 'ALL',
          },
          data
        )
      : false;

  if (error && !Array.isArray(error)) {
    const errorMessage = getErrorMessage(error);
    if (typeof errorMessage !== 'undefined') {
      notify(`notify.error.${errorMessage}`, 'error');
    }
  }

  useEffect(() => {
    if (loaded && afterUpdate && result && !Array.isArray(selectedIds)) {
      afterUpdate(result);
    }
  }, [loaded, result]);

  const IconComponent = getIconComponentFromName(icon);

  return (
    <Fragment>
      <RaButton
        label={label}
        disabled={loading || disabled}
        variant={withConfirmation ? 'contained' : 'outlined'}
        onClick={withConfirmation ? handleOpenModal : update}
        className={classes.button}
        {...(IconComponent && { startIcon: createElement(IconComponent) })}
      />
      <ConfirmationModal
        open={showDialog}
        title={confirmation?.title && t(confirmation.title)}
        handleClick={handleClick}
        handleClose={handleCloseModal}
      >
        {confirmation?.content && t(`${confirmation.content}`, data)}
      </ConfirmationModal>
    </Fragment>
  );
};

export default UpdateButton;
