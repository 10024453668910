import { Resource as ResourceType } from '../types';

export const invitation: ResourceType = {
  name: 'invitations',
  label: 'resource.invitations.label',
  hideResource: false,
  attributes: [
    {
      key: 'id',
      label: 'resource.invitations.field.id.label',
      type: 'text',
    },
    {
      key: 'created_at',
      label: 'resource.invitations.field.date.label',
      type: 'date',
    },
    {
      key: 'status',
      label: 'resource.invitations.field.status.label',
      type: 'select',
      choices: [
        {
          id: 'pending',
          name: 'resource.invitations.field.choices.pending.label',
        },
        {
          id: 'accepted',
          name: 'resource.invitations.field.choices.accepted.label',
        },
        {
          id: 'refused',
          name: 'resource.invitations.field.choices.refused.label',
        },
        {
          id: 'archived',
          name: 'resource.invitations.field.choices.archived.label',
        },
      ],
    },
    {
      key: 'recipient_id',
      label: 'resource.invitations.field.recipient_id.label',
      type: 'reference',
      reference: 'companies',
    },
    {
      key: 'invitationable_id',
      label: 'resource.invitations.field.invitationable_id.label',
      type: 'reference',
      reference: 'missions',
    },
    {
      key: 'company_id',
      label: 'resource.invitations.field.company_id.label',
      type: 'reference',
      reference: 'companies',
    },
    {
      key: 'recipient_leader_id',
      label: 'resource.invitations.field.recipient_id.label',
      type: 'reference',
      reference: 'users',
    },
    {
      key: 'user_id',
      label: 'resource.missions.field.company_id.label',
      type: 'reference',
      reference: 'users',
    },
  ],
  pages: {
    list: {
      hasEditButton: false,
      search: {
        enabled: true,
        label: 'filter.search.label',
      },
      bulkActionButtons: [],
      filters: [
        {
          source: 'status',
          alwaysOn: true,
        },
        {
          source: 'recipient_id',
          type: 'reference',
          child: {
            type: 'autocomplete',
            optionText: 'name',
            // Options to pass to ReferenceInput
            options: {
              perPage: 10,
              allowEmpty: false,
            },
          },
        },
        {
          source: 'invitationable_id',
          type: 'reference',
          child: {
            type: 'autocomplete',
            optionText: 'name',
            // Options to pass to ReferenceInput
            options: {
              perPage: 10,
              allowEmpty: false,
            },
          },
        },
        {
          source: 'company_id',
          type: 'reference',
          child: {
            type: 'autocomplete',
            optionText: 'name',
            // Options to pass to ReferenceInput
            options: {
              perPage: 10,
              allowEmpty: false,
            },
          },
        },
      ],
      fields: [
        {
          source: 'recipient_leader_id',
          sortable: false,
          child: {
            source: 'users.company_name',
          },
        },
        {
          source: 'invitationable_id',
          sortable: false,
          label: 'resource.invitations.field.invitationable_id.label',
          child: {
            source: 'missions.name',
          },
        },
        {
          source: 'user_id',
          sortable: false,
          child: {
            source: 'users.company_name',
          },
        },
        {
          source: 'status',
          sortable: false,
        },
        {
          source: 'created_at',
        },
      ],
    },
    edit: {
      actions: [],
      left: {
        tabs: [],
      },
      right: [],
    },
  },
};
