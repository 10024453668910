import React, { FunctionComponent, useState, useEffect } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { get } from 'lodash';
import { useForm } from 'react-final-form';
import { useTranslate } from 'react-admin';
import { LocationInputProps } from './types';
import { shouldRenderComponent } from '../../helpers/location';

// Documentation of the library (react-google-places-autocomplete is based on react-select library)
// https://tintef.github.io/react-google-places-autocomplete/docs/examples
// https://react-select.com/styles#the-unstyled-prop
const LocationInput: FunctionComponent<LocationInputProps> = (
  props: LocationInputProps
) => {
  const { source, label, record } = props;
  const locations = get(record, 'locations', []);

  if (shouldRenderComponent(record, locations)) {
    return null;
  }

  const [selectedPlaces, setSelectedPlaces] = useState<Record<string, any>[]>(
    () =>
      locations.map((location: { address: string; uid: any }) => ({
        label: location.address,
        value: {
          place_id: location.uid,
          description: location.address,
        },
      }))
  );
  const form = useForm();
  const t = useTranslate();

  useEffect(() => {
    handleForm();
  }, [selectedPlaces]);

  // To change the value of the input in the form
  const handleForm = () => {
    form.change(
      source,
      selectedPlaces.map((place) => place.label)
    );
  };

  const handleChange = (
    places: { label: string; value: Record<string, any> }[]
  ) => {
    setSelectedPlaces(places);
  };

  return (
    <div>
      <GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        autocompletionRequest={{ types: ['(regions)'] }}
        selectProps={{
          onChange: handleChange,
          // To select multiple locations
          isMulti: true,
          name: source,
          placeholder: t(`${label}`),
          noOptionsMessage: () => t('location_input.no_results'),
          ...(record.locations && {
            defaultValue: selectedPlaces,
          }),
          styles: {
            // CSS to make this input similar to material-ui's input
            control: (
              baseStyles: Record<string, any>,
              state: Record<string, any>
            ) => ({
              ...baseStyles,
              borderColor: state.isHover || state.isFocused ? 'grey' : '',
              zIndex: 10,
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
              minHeight: '48px',
              border: 'none',
              borderBottom:
                state.isHover || state.isFocused
                  ? '1px solid rgba(0, 0, 0, 0.42)'
                  : '1px solid rgba(0, 0, 0, 0.42)',
              borderBottomLeftRadius: '0px',
              borderBottomRightRadius: '0px',
              borderTopLeftRadius: '4px',
              borderTopRightRadius: '4px',
              marginTop: '5px',
              marginBottom: '25px',
              boxShadow: 'none',
            }),
            // To fix zIndex menu
            menu: (provided: Record<string, any>) => ({
              ...provided,
              zIndex: 10,
            }),
            // To customize the backgroundColor of the options
            option: (
              provided: Record<string, any>,
              state: Record<string, any>
            ) => ({
              ...provided,
              backgroundColor:
                state.isHover || state.isFocused
                  ? 'rgba(0, 0, 0, 0.04)'
                  : 'white',
            }),
          },
        }}
      />
    </div>
  );
};
export default LocationInput;
