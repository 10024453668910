import UpdateButton from '../components/BulkActionButtons/components/UpdateButton';
import ModalFormButton from '../components/BulkActionButtons/components/ModalFormButton';
import RedirectButton from '../components/BulkActionButtons/components/RedirectButton';
import { ActionButtonType } from '../config/types/page';

export default class ActionButtonFactory {
  public static buttonMap = {
    modal: ModalFormButton,
    update: UpdateButton,
    redirect: RedirectButton,
  };

  static getButtonComponent<T extends ActionButtonType>(
    type: T
  ): typeof ActionButtonFactory.buttonMap[T] {
    if (Object.keys(ActionButtonFactory.buttonMap).includes(type))
      return ActionButtonFactory.buttonMap[type];
    throw new Error(`Button type ${type} does not exists`);
  }
}
