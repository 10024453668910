export default [
  { name: 'fields.countries.ad', id: 'ad' },
  { name: 'fields.countries.ae', id: 'ae' },
  { name: 'fields.countries.af', id: 'af' },
  { name: 'fields.countries.ag', id: 'ag' },
  { name: 'fields.countries.ai', id: 'ai' },
  { name: 'fields.countries.al', id: 'al' },
  { name: 'fields.countries.am', id: 'am' },
  { name: 'fields.countries.ao', id: 'ao' },
  { name: 'fields.countries.aq', id: 'aq' },
  { name: 'fields.countries.ar', id: 'ar' },
  { name: 'fields.countries.as', id: 'as' },
  { name: 'fields.countries.at', id: 'at' },
  { name: 'fields.countries.au', id: 'au' },
  { name: 'fields.countries.aw', id: 'aw' },
  { name: 'fields.countries.ax', id: 'ax' },
  { name: 'fields.countries.az', id: 'az' },
  { name: 'fields.countries.ba', id: 'ba' },
  { name: 'fields.countries.bb', id: 'bb' },
  { name: 'fields.countries.bd', id: 'bd' },
  { name: 'fields.countries.be', id: 'be' },
  { name: 'fields.countries.bf', id: 'bf' },
  { name: 'fields.countries.bg', id: 'bg' },
  { name: 'fields.countries.bh', id: 'bh' },
  { name: 'fields.countries.bi', id: 'bi' },
  { name: 'fields.countries.bj', id: 'bj' },
  { name: 'fields.countries.bl', id: 'bl' },
  { name: 'fields.countries.bm', id: 'bm' },
  { name: 'fields.countries.bn', id: 'bn' },
  { name: 'fields.countries.bo', id: 'bo' },
  { name: 'fields.countries.bq', id: 'bq' },
  { name: 'fields.countries.br', id: 'br' },
  { name: 'fields.countries.bs', id: 'bs' },
  { name: 'fields.countries.bt', id: 'bt' },
  { name: 'fields.countries.bv', id: 'bv' },
  { name: 'fields.countries.bw', id: 'bw' },
  { name: 'fields.countries.by', id: 'by' },
  { name: 'fields.countries.bz', id: 'bz' },
  { name: 'fields.countries.ca', id: 'ca' },
  { name: 'fields.countries.cc', id: 'cc' },
  { name: 'fields.countries.cd', id: 'cd' },
  { name: 'fields.countries.cf', id: 'cf' },
  { name: 'fields.countries.cg', id: 'cg' },
  { name: 'fields.countries.ch', id: 'ch' },
  { name: 'fields.countries.ci', id: 'ci' },
  { name: 'fields.countries.ck', id: 'ck' },
  { name: 'fields.countries.cl', id: 'cl' },
  { name: 'fields.countries.cm', id: 'cm' },
  { name: 'fields.countries.cn', id: 'cn' },
  { name: 'fields.countries.co', id: 'co' },
  { name: 'fields.countries.cr', id: 'cr' },
  { name: 'fields.countries.cu', id: 'cu' },
  { name: 'fields.countries.cv', id: 'cv' },
  { name: 'fields.countries.cw', id: 'cw' },
  { name: 'fields.countries.cx', id: 'cx' },
  { name: 'fields.countries.cy', id: 'cy' },
  { name: 'fields.countries.cz', id: 'cz' },
  { name: 'fields.countries.de', id: 'de' },
  { name: 'fields.countries.dj', id: 'dj' },
  { name: 'fields.countries.dk', id: 'dk' },
  { name: 'fields.countries.dm', id: 'dm' },
  { name: 'fields.countries.do', id: 'do' },
  { name: 'fields.countries.dz', id: 'dz' },
  { name: 'fields.countries.ec', id: 'ec' },
  { name: 'fields.countries.ee', id: 'ee' },
  { name: 'fields.countries.eg', id: 'eg' },
  { name: 'fields.countries.eh', id: 'eh' },
  { name: 'fields.countries.er', id: 'er' },
  { name: 'fields.countries.es', id: 'es' },
  { name: 'fields.countries.et', id: 'et' },
  { name: 'fields.countries.fi', id: 'fi' },
  { name: 'fields.countries.fj', id: 'fj' },
  { name: 'fields.countries.fk', id: 'fk' },
  { name: 'fields.countries.fm', id: 'fm' },
  { name: 'fields.countries.fo', id: 'fo' },
  { name: 'fields.countries.fr', id: 'fr' },
  { name: 'fields.countries.ga', id: 'ga' },
  { name: 'fields.countries.gb', id: 'gb' },
  { name: 'fields.countries.gd', id: 'gd' },
  { name: 'fields.countries.ge', id: 'ge' },
  { name: 'fields.countries.gf', id: 'gf' },
  { name: 'fields.countries.gg', id: 'gg' },
  { name: 'fields.countries.gh', id: 'gh' },
  { name: 'fields.countries.gi', id: 'gi' },
  { name: 'fields.countries.gl', id: 'gl' },
  { name: 'fields.countries.gm', id: 'gm' },
  { name: 'fields.countries.gn', id: 'gn' },
  { name: 'fields.countries.gp', id: 'gp' },
  { name: 'fields.countries.gq', id: 'gq' },
  { name: 'fields.countries.gr', id: 'gr' },
  { name: 'fields.countries.gs', id: 'gs' },
  { name: 'fields.countries.gt', id: 'gt' },
  { name: 'fields.countries.gu', id: 'gu' },
  { name: 'fields.countries.gw', id: 'gw' },
  { name: 'fields.countries.gy', id: 'gy' },
  { name: 'fields.countries.hk', id: 'hk' },
  { name: 'fields.countries.hm', id: 'hm' },
  { name: 'fields.countries.hn', id: 'hn' },
  { name: 'fields.countries.hr', id: 'hr' },
  { name: 'fields.countries.ht', id: 'ht' },
  { name: 'fields.countries.hu', id: 'hu' },
  { name: 'fields.countries.id', id: 'id' },
  { name: 'fields.countries.ie', id: 'ie' },
  { name: 'fields.countries.il', id: 'il' },
  { name: 'fields.countries.im', id: 'im' },
  { name: 'fields.countries.in', id: 'in' },
  { name: 'fields.countries.io', id: 'io' },
  { name: 'fields.countries.iq', id: 'iq' },
  { name: 'fields.countries.ir', id: 'ir' },
  { name: 'fields.countries.is', id: 'is' },
  { name: 'fields.countries.it', id: 'it' },
  { name: 'fields.countries.je', id: 'je' },
  { name: 'fields.countries.jm', id: 'jm' },
  { name: 'fields.countries.jo', id: 'jo' },
  { name: 'fields.countries.jp', id: 'jp' },
  { name: 'fields.countries.ke', id: 'ke' },
  { name: 'fields.countries.kg', id: 'kg' },
  { name: 'fields.countries.kh', id: 'kh' },
  { name: 'fields.countries.ki', id: 'ki' },
  { name: 'fields.countries.km', id: 'km' },
  { name: 'fields.countries.kn', id: 'kn' },
  { name: 'fields.countries.kp', id: 'kp' },
  { name: 'fields.countries.kr', id: 'kr' },
  { name: 'fields.countries.kw', id: 'kw' },
  { name: 'fields.countries.ky', id: 'ky' },
  { name: 'fields.countries.kz', id: 'kz' },
  { name: 'fields.countries.la', id: 'la' },
  { name: 'fields.countries.lb', id: 'lb' },
  { name: 'fields.countries.lc', id: 'lc' },
  { name: 'fields.countries.li', id: 'li' },
  { name: 'fields.countries.lk', id: 'lk' },
  { name: 'fields.countries.lr', id: 'lr' },
  { name: 'fields.countries.ls', id: 'ls' },
  { name: 'fields.countries.lt', id: 'lt' },
  { name: 'fields.countries.lu', id: 'lu' },
  { name: 'fields.countries.lv', id: 'lv' },
  { name: 'fields.countries.ly', id: 'ly' },
  { name: 'fields.countries.ma', id: 'ma' },
  { name: 'fields.countries.mc', id: 'mc' },
  { name: 'fields.countries.md', id: 'md' },
  { name: 'fields.countries.me', id: 'me' },
  { name: 'fields.countries.mf', id: 'mf' },
  { name: 'fields.countries.mg', id: 'mg' },
  { name: 'fields.countries.mh', id: 'mh' },
  { name: 'fields.countries.mk', id: 'mk' },
  { name: 'fields.countries.ml', id: 'ml' },
  { name: 'fields.countries.mm', id: 'mm' },
  { name: 'fields.countries.mn', id: 'mn' },
  { name: 'fields.countries.mo', id: 'mo' },
  { name: 'fields.countries.mp', id: 'mp' },
  { name: 'fields.countries.mq', id: 'mq' },
  { name: 'fields.countries.mr', id: 'mr' },
  { name: 'fields.countries.ms', id: 'ms' },
  { name: 'fields.countries.mt', id: 'mt' },
  { name: 'fields.countries.mu', id: 'mu' },
  { name: 'fields.countries.mv', id: 'mv' },
  { name: 'fields.countries.mw', id: 'mw' },
  { name: 'fields.countries.mx', id: 'mx' },
  { name: 'fields.countries.my', id: 'my' },
  { name: 'fields.countries.mz', id: 'mz' },
  { name: 'fields.countries.na', id: 'na' },
  { name: 'fields.countries.nc', id: 'nc' },
  { name: 'fields.countries.ne', id: 'ne' },
  { name: 'fields.countries.nf', id: 'nf' },
  { name: 'fields.countries.ng', id: 'ng' },
  { name: 'fields.countries.ni', id: 'ni' },
  { name: 'fields.countries.nl', id: 'nl' },
  { name: 'fields.countries.no', id: 'no' },
  { name: 'fields.countries.np', id: 'np' },
  { name: 'fields.countries.nr', id: 'nr' },
  { name: 'fields.countries.nu', id: 'nu' },
  { name: 'fields.countries.nz', id: 'nz' },
  { name: 'fields.countries.om', id: 'om' },
  { name: 'fields.countries.pa', id: 'pa' },
  { name: 'fields.countries.pe', id: 'pe' },
  { name: 'fields.countries.pf', id: 'pf' },
  { name: 'fields.countries.pg', id: 'pg' },
  { name: 'fields.countries.ph', id: 'ph' },
  { name: 'fields.countries.pk', id: 'pk' },
  { name: 'fields.countries.pl', id: 'pl' },
  { name: 'fields.countries.pm', id: 'pm' },
  { name: 'fields.countries.pn', id: 'pn' },
  { name: 'fields.countries.pr', id: 'pr' },
  { name: 'fields.countries.ps', id: 'ps' },
  { name: 'fields.countries.pt', id: 'pt' },
  { name: 'fields.countries.pw', id: 'pw' },
  { name: 'fields.countries.py', id: 'py' },
  { name: 'fields.countries.qa', id: 'qa' },
  { name: 'fields.countries.re', id: 're' },
  { name: 'fields.countries.ro', id: 'ro' },
  { name: 'fields.countries.rs', id: 'rs' },
  { name: 'fields.countries.ru', id: 'ru' },
  { name: 'fields.countries.rw', id: 'rw' },
  { name: 'fields.countries.sa', id: 'sa' },
  { name: 'fields.countries.sb', id: 'sb' },
  { name: 'fields.countries.sc', id: 'sc' },
  { name: 'fields.countries.sd', id: 'sd' },
  { name: 'fields.countries.se', id: 'se' },
  { name: 'fields.countries.sg', id: 'sg' },
  { name: 'fields.countries.sh', id: 'sh' },
  { name: 'fields.countries.si', id: 'si' },
  { name: 'fields.countries.sj', id: 'sj' },
  { name: 'fields.countries.sk', id: 'sk' },
  { name: 'fields.countries.sl', id: 'sl' },
  { name: 'fields.countries.sm', id: 'sm' },
  { name: 'fields.countries.sn', id: 'sn' },
  { name: 'fields.countries.so', id: 'so' },
  { name: 'fields.countries.sr', id: 'sr' },
  { name: 'fields.countries.ss', id: 'ss' },
  { name: 'fields.countries.st', id: 'st' },
  { name: 'fields.countries.sv', id: 'sv' },
  { name: 'fields.countries.sx', id: 'sx' },
  { name: 'fields.countries.sy', id: 'sy' },
  { name: 'fields.countries.sz', id: 'sz' },
  { name: 'fields.countries.tc', id: 'tc' },
  { name: 'fields.countries.td', id: 'td' },
  { name: 'fields.countries.tf', id: 'tf' },
  { name: 'fields.countries.tg', id: 'tg' },
  { name: 'fields.countries.th', id: 'th' },
  { name: 'fields.countries.tj', id: 'tj' },
  { name: 'fields.countries.tk', id: 'tk' },
  { name: 'fields.countries.tl', id: 'tl' },
  { name: 'fields.countries.tm', id: 'tm' },
  { name: 'fields.countries.tn', id: 'tn' },
  { name: 'fields.countries.to', id: 'to' },
  { name: 'fields.countries.tr', id: 'tr' },
  { name: 'fields.countries.tt', id: 'tt' },
  { name: 'fields.countries.tv', id: 'tv' },
  { name: 'fields.countries.tw', id: 'tw' },
  { name: 'fields.countries.tz', id: 'tz' },
  { name: 'fields.countries.ua', id: 'ua' },
  { name: 'fields.countries.ug', id: 'ug' },
  { name: 'fields.countries.um', id: 'um' },
  { name: 'fields.countries.us', id: 'us' },
  { name: 'fields.countries.uy', id: 'uy' },
  { name: 'fields.countries.uz', id: 'uz' },
  { name: 'fields.countries.va', id: 'va' },
  { name: 'fields.countries.vc', id: 'vc' },
  { name: 'fields.countries.ve', id: 've' },
  { name: 'fields.countries.vg', id: 'vg' },
  { name: 'fields.countries.vi', id: 'vi' },
  { name: 'fields.countries.vn', id: 'vn' },
  { name: 'fields.countries.vu', id: 'vu' },
  { name: 'fields.countries.wf', id: 'wf' },
  { name: 'fields.countries.ws', id: 'ws' },
  { name: 'fields.countries.ye', id: 'ye' },
  { name: 'fields.countries.yt', id: 'yt' },
  { name: 'fields.countries.za', id: 'za' },
  { name: 'fields.countries.zm', id: 'zm' },
  { name: 'fields.countries.zw', id: 'zw' },
];
