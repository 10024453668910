import React, { FunctionComponent } from 'react';
import { ActionButtonsProps } from './types';
import ActionButtonFactory from '../../factories/ActionButtonFactory';
import Resource from '../../models/Resource';
import { Box as MuiBox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExtractPropsFromFC } from '../../types';
import useConfig from '../../hooks/useConfig';
import withExtensions from '../../extensions/withExtensions';
import { useRecordContext } from 'react-admin';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: '24px',
  },
  actionInTabsList: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: '-24px',
  },
});

const ActionButtons: FunctionComponent<ActionButtonsProps> = (props) => {
  const config = useConfig();
  const { resource, page, ...restProps } = props;
  const record = useRecordContext();
  const classes = useStyles();

  // The actionButtons can be retrieved from the Resource model for edit and create page OR
  // it can be passed as props for list with special buttons (other than create and export)
  const actionButtons = (() => {
    if (props.actions) return props.actions;
    const resourceInstance: Resource = config.getResource(resource as string);

    return page && resourceInstance.getActionsButtons(page);
  })();

  return (
    <MuiBox
      className={props.fromTabs ? classes.actionInTabsList : classes.root}
    >
      {actionButtons &&
        actionButtons.map((actionButton) => {
          const {
            type,
            name,
            extensions,
            resourceName,
            resourceId,
            ...otherProps
          } = actionButton;
          const Button = withExtensions(extensions || [])(
            ActionButtonFactory.getButtonComponent(type)
          );

          return (
            <Button
              key={name}
              resource={resourceName ? resourceName : resource}
              selectedIds={
                record && resourceId ? record[resourceId] : props.data?.id
              }
              {...restProps}
              {...(otherProps as ExtractPropsFromFC<typeof Button>)}
            />
          );
        })}
    </MuiBox>
  );
};

export default ActionButtons;
