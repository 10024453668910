import { Resource as ResourceType } from '../types';

export const cgu: ResourceType = {
  name: 'cgus',
  label: 'resource.cgvus.label',
  hideResource: false,
  attributes: [
    {
      key: 'id',
      label: 'resource.cgvu.field.id.label',
      type: 'text',
    },
    {
      key: 'title',
      label: 'resource.cgvu.field.title.label',
      type: 'text',
    },
    {
      key: 'version',
      label: 'resource.cgvu.field.version.label',
      type: 'text',
    },
    {
      key: 'effective_at',
      label: 'resource.cgvu.field.effective_at.label',
      type: 'date',
    },
    {
      key: 'status',
      label: 'resource.cgvu.field.status.label',
      type: 'select',
      choices: [
        {
          id: 'online',
          name: 'resource.cgvu.field.status.choices.online.label',
        },
        {
          id: 'offline',
          name: 'resource.cgvu.field.status.choices.offline.label',
        },
      ],
    },
    {
      key: 'created_at',
      label: 'resource.cgvu.field.created_at.label',
      type: 'date',
    },
    {
      key: 'updated_at',
      label: 'resource.cgvu.field.updated_at.label',
      type: 'date',
    },
    {
      key: 'changelog',
      label: 'resource.cgvu.field.changelog.label',
      type: 'wysiwyg',
    },
    {
      key: 'text',
      label: 'resource.cgvu.field.text.label',
      type: 'wysiwyg',
    },
  ],
  pages: {
    list: {
      hasCreateButton: true,
      hasEditButton: true,
      search: {
        enabled: true,
        label: 'filter.search.label',
      },
      filters: [
        {
          source: 'status',
          alwaysOn: true,
        },
      ],
      bulkActionButtons: [
        {
          name: 'validate',
          type: 'update',
          label: 'resource.cgvu.bulk_action_buttons.validate.label',
          values: {
            validated: true,
          },
          withConfirmation: true,
          confirmation: {
            content: 'modal.bulk_cgvu.content',
          },
        },
        {
          name: 'unvalidate',
          type: 'update',
          label: 'resource.cgvu.bulk_action_buttons.unvalidate.label',
          values: {
            validated: false,
          },
        },
      ],
      fields: [
        {
          source: 'title',
          sortable: false,
        },
        {
          source: 'version',
          sortable: true,
        },
        {
          source: 'effective_at',
          sortable: true,
        },
        {
          source: 'status',
          sortable: true,
        },
        {
          source: 'created_at',
          sortable: true,
        },
      ],
    },
    edit: {
      actions: [
        {
          name: 'validate',
          type: 'update',
          label: 'resource.cgvu.bulk_action_buttons.validate.label',
          values: { validated: true },
          extraUrl: '/',
          withConfirmation: true,
          confirmation: {
            content: 'modal.bulk_cgvu.content',
          },
        },
        {
          name: 'unvalidate',
          type: 'update',
          label: 'resource.cgvu.bulk_action_buttons.unvalidate.label',
          values: { validated: false },
          extraUrl: '/',
        },
      ],
      left: {
        tabs: [
          {
            key: 'information',
            title: 'resource.cgvu.tab.information.title',
            sections: [
              {
                key: 'cgvu',
                title: 'resource.cgvu.section.title',
                layout: [
                  ['title'],
                  ['version'],
                  ['effective_at'],
                  ['status'],
                  ['changelog'],
                  ['text'],
                ],
                inputs: {
                  title: {
                    validators: [{ key: 'required' }],
                  },
                  version: {
                    validators: [{ key: 'required' }],
                  },
                  effective_at: {
                    validators: [{ key: 'required' }],
                  },
                  status: {
                    disabled: true,
                    validators: [{ key: 'required' }],
                  },
                  changelog: {},
                  text: {
                    validators: [{ key: 'required' }],
                  },
                },
              },
            ],
          },
        ],
      },
      right: [
        {
          sections: [
            {
              key: 'details',
              title: 'resource.cgvu.section.details.title',
              layout: [['id'], ['created_at', 'updated_at']],
              inputs: {
                id: {
                  disabled: true,
                },
                created_at: {
                  disabled: true,
                  width: 6,
                },
                updated_at: {
                  disabled: true,
                  width: 6,
                },
              },
            },
          ],
        },
      ],
    },
    create: {
      left: {
        tabs: [
          {
            key: 'information',
            title: 'resource.cgvu.tab.information.title',
            sections: [
              {
                key: 'cgvu',
                title: 'resource.cgvu.section.title',
                layout: [
                  ['title'],
                  ['version'],
                  ['effective_at'],
                  ['status'],
                  ['changelog'],
                  ['text'],
                ],
                inputs: {
                  title: {
                    validators: [{ key: 'required' }],
                  },
                  version: {},
                  effective_at: {},
                  status: {
                    disabled: true,
                    validators: [{ key: 'required' }],
                    initialValue: 'offline',
                  },
                  changelog: {},
                  text: {},
                },
              },
            ],
          },
        ],
      },
    },
  },
};
