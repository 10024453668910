import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { InputProps } from './types';
import InputFactory from './../../factories/InputFactory';
import ValidatorFactory, {
  TwoParamValidator,
  OneParamValidator,
} from './../../factories/ValidatorFactory';
import withExtensions from '../../extensions/withExtensions';
import {
  useRecordContext,
  useResourceContext,
  useNotify,
  Validator as RaValidator,
} from 'react-admin';
import { ExtractPropsFromFC } from '../../types';
import { InputValidator } from '../../config/types/field';
import checkRules from '../../helpers/rules';
import { useFormState } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';

const useStyles = makeStyles({
  multipleSelectFilter: {
    minWidth: '191px',
    maxWidth: '500px',
  },
});

const Input: FunctionComponent<InputProps> = (props) => {
  const {
    type,
    child,
    extensions,
    source,
    validators,
    rules,
    optionsFilter,
    ...restProps
  } = props;
  const className = get(optionsFilter, 'className', null);
  const classes = useStyles();
  const ComponentRef = useRef(
    withExtensions(extensions || [])(InputFactory.getInputComponent(type))
  );
  const Component = ComponentRef.current;
  const resource = useResourceContext();
  const record = useRecordContext();
  const formState = useFormState();
  const formValues = formState.values;
  const notify = useNotify();
  const [validatorCount, setValidatorCount] = useState(0);

  const validate = (validators || []).reduce(
    (acc: RaValidator[], validator: InputValidator): RaValidator[] => {
      const shouldAddValidator = (() => {
        if (validator.rules) {
          return checkRules(validator.rules, formValues, 'ALL');
        }

        return true;
      })();

      if (!shouldAddValidator) return acc;

      const validatorFunction = (() => {
        if (validator.value !== undefined) {
          return (
            ValidatorFactory.getValidator(validator.key) as TwoParamValidator
          )(
            validator.value,
            validator.message ?? `resource.validators.${validator.key}.message`
          );
        }

        return (
          ValidatorFactory.getValidator(validator.key) as OneParamValidator
        )(`resource.validators.${validator.key}.message`);
      })();

      return [...acc, validatorFunction];
    },
    []
  );

  // Set validator count to rerender input if count has changed,
  // See comment below on key attribute
  useEffect(() => {
    if (validatorCount !== validate.length) {
      setValidatorCount(validate.length);
    }
  }, [validatorCount, validate.length]);

  const optionsChild = (function () {
    if (child && child.options) {
      return child.options;
    }

    return {};
  })();

  const childProps = (function () {
    if (child && child.type) {
      return {
        ...child,
        source,
        /* TODO try to get rid of key attribute */
        key: '',
      };
    }

    return null;
  })();

  // Check if input has conditions to be displayed
  try {
    if (rules && rules.hide && checkRules(rules.hide, formValues)) return null;
  } catch (e) {
    notify(`"Hide" rule failed for field ${source}: ${e.message}`, 'error');
  }

  const disabled = (() => {
    try {
      return !!(
        rules &&
        rules.disabled &&
        checkRules(rules.disabled, formValues)
      );
    } catch (e) {
      notify(
        `"Disabled" rule failed for field ${source}: ${e.message}`,
        'error'
      );

      return false;
    }
  })();

  return (
    <Component
      source={source}
      validate={validate}
      resource={resource}
      record={record}
      disabled={disabled}
      child={child}
      {...optionsChild}
      {...(restProps as ExtractPropsFromFC<typeof type>)}
      className={get(classes, className)}
      /* Key attribute is here to rerender input when validate attribute (validators) changes
       * For more information see https://final-form.org/docs/react-final-form/types/FieldProps#validate
       */
      key={`${source}-${validatorCount}`}
    >
      {childProps && <Input {...childProps} />}
    </Component>
  );
};
export default Input;
