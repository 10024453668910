import React, { FunctionComponent } from 'react';
import { isEmpty } from 'lodash';
import { HistoryUpdatedField } from '../../../../../../config/types/history';
import { translationField } from '../../../../../../helpers/history';
import { ArrayFieldProps } from './type';
import { useTranslate } from 'react-admin';

const showArray = (
  arrayFields: string[],
  translation_key: HistoryUpdatedField | string
) =>
  arrayFields.reduce((previousValue, currentValue) => {
    if (isEmpty(previousValue)) {
      return translationField(currentValue, translation_key);
    } else {
      return `${previousValue}, ${translationField(
        currentValue,
        translation_key
      )}`;
    }
  }, '');

const ArrayField: FunctionComponent<ArrayFieldProps> = (props) => {
  const { details } = props;
  const t = useTranslate();

  return (
    <span>
      {t(`${details.translation_key}.details`)}
      {details.args !== undefined && (
        <>
          {details.args.before !== null && (
            <>
              {details.args !== undefined &&
              details.args.before &&
              !isEmpty(details.args.before)
                ? showArray(details.args.before, details.translation_key)
                : t('history.company.field_updated.no_tags')}
              {' → '}
            </>
          )}

          {details.args.after && !isEmpty(details.args.after)
            ? showArray(details.args.after, details.translation_key)
            : t('history.company.field_updated.no_tags')}
        </>
      )}
    </span>
  );
};

export default ArrayField;
