import { isBoolean } from 'lodash';
import React, { FunctionComponent } from 'react';
import { useTranslate } from 'react-admin';
import { BoolFieldProps } from './type';

const BoolField: FunctionComponent<BoolFieldProps> = (props) => {
  const t = useTranslate();
  const { details } = props;

  const before =
    details.args !== undefined && isBoolean(details.args.before)
      ? t(`field.boolean.${details.args.before ? 'yes' : 'no'}`)
      : t('history.company.field_updated.no_tags');
  const after =
    details.args !== undefined && isBoolean(details.args.after)
      ? t(`field.boolean.${details.args.after ? 'yes' : 'no'}`)
      : t('history.company.field_updated.no_tags');

  return (
    <span>{t(`${details.translation_key}.details`, { before, after })}</span>
  );
};

export default BoolField;
