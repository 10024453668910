import {
  Attribute,
  FieldType,
  NonObjectAttribute,
} from '../config/types/field';
import {
  Extension,
  Resource as ResourceType,
  Tab,
  SubMenu,
  SubMenuItem,
} from '../config/types';
import { Field, ObjectOf, Filter } from '../types';
import {
  LeftPanel,
  ListPage,
  Pages,
  RightPanel,
  Search,
  ActionButton,
  PageAction,
} from '../config/types/page';
import Config from './Config';
import { FilterPayload, SortPayload } from 'react-admin';

export default class Resource {
  private readonly _name: string;
  private readonly _label: string;
  private readonly _hideResource: boolean;
  private readonly subMenu: SubMenu | undefined;
  private readonly attributes: ObjectOf<Attribute>;
  private readonly pages: Pages | undefined;
  private readonly config: Config;

  constructor(resource: ResourceType, config: Config) {
    this._name = resource.name;
    this._label = resource.label;
    this._hideResource = resource.hideResource;
    this.subMenu = resource.subMenu;
    this.pages = resource.pages;
    this.config = config;

    // Transform array of Attributes into object of Attribute
    // To simplify attribute searches
    this.attributes = resource.attributes.reduce(
      (acc: ObjectOf<Attribute>, curr: Attribute) => ({
        ...acc,
        [curr.key]: curr,
      }),
      {}
    );
  }

  hasAttribute(attributeKey: string): boolean {
    const sourceParts = attributeKey.split('.');

    return Object.keys(this.attributes).includes(sourceParts[0]);
  }

  getAttribute(attributeKey: string): Attribute {
    const sourceParts = attributeKey.split('.');

    if (this.hasAttribute(attributeKey)) {
      const attribute = this.attributes[sourceParts[0]];

      if (
        sourceParts[1] &&
        attribute.type === 'object' &&
        attribute.shape &&
        Array.isArray(this.attributes[sourceParts[0]].shape)
      ) {
        const subAttribute = attribute.shape.find(
          (attr) => attr.key === sourceParts[1]
        );
        if (subAttribute) return subAttribute;

        throw new Error(
          `Sub Attribute "${sourceParts[1]}" does not exists in attribute "${sourceParts[0]}" for resource "${this._name}"`
        );
      }

      return this.attributes[sourceParts[0]];
    }
    throw new Error(
      `Attribute "${attributeKey}" does not exists for resource "${this._name}"`
    );
  }

  getAttributesOfType(type: FieldType): Attribute[] {
    return Object.values(this.attributes).filter(
      (attribute) => attribute.type === type
    );
  }

  // For list displayed inside tabs, the fields array comes from another part of the config
  getFieldsArray(list?: ListPage | null): Field[] {
    if (list) {
      return this.getFields(list.fields);
    }

    return this.getFields(
      typeof this.pages !== 'undefined' ? this.pages.list.fields : []
    );
  }

  getFields(fields: any[]): Field[] {
    return typeof fields !== 'undefined'
      ? fields.map((field) => {
          const attribute =
            field.source &&
            (this.getAttribute(field.source) as NonObjectAttribute);

          const key = (attribute && attribute.key) || field.key;
          // Override type attribute by type precised in fields pages
          const type = field.type || (attribute && attribute.type);

          if (!key) {
            throw new Error(
              `Attribute key can't be undefined for field in resource "${this._name}"`
            );
          }

          if (!type) {
            throw new Error(
              `Attribute type can't be undefined for field in resource "${this._name}"`
            );
          }

          const returnedField = {
            ...(attribute && attribute),
            ...field,
            key,
            type,
          };

          if (field.child?.source) {
            const childAttribute = this.config.getAttribute(
              field.child.source
            ) as NonObjectAttribute;

            return {
              ...returnedField,
              child: {
                ...childAttribute,
                ...field.child,
              },
              sortBy: field.child?.source,
            };
          }

          return returnedField;
        })
      : [];
  }

  get bulkActionButtons(): ActionButton[] {
    if (typeof this.pages === 'undefined') return [];

    return this.pages.list.bulkActionButtons || [];
  }

  get search(): Search | undefined {
    if (typeof this.pages === 'undefined') return;

    return this.pages.list.search;
  }

  get filters(): Filter[] {
    if (typeof this.pages === 'undefined') return [];

    const filters = this.pages.list.filters;
    if (!filters?.length) return [];

    // filters can reference other resource attribute
    return filters.map((filter) => {
      const attribute = (() => {
        if (filter.source) {
          if (filter.source.includes('.')) {
            return this.config.getAttribute(filter.source);
          }

          return this.getAttribute(filter.source);
        }

        if (filter.name && filter.type) {
          return {
            type: filter.type,
            source: filter.name,
            key: filter.name,
          };
        }

        throw new Error(
          `If 'source' property is not defined, then both properties 'name' and 'type' must be defined for filter in Resource ${this._name}`
        );
      })();

      const returnedFilter = {
        ...(attribute as NonObjectAttribute),
        ...filter,
      };

      if (filter.child?.source) {
        const childAttribute = this.config.getAttribute(filter.child.source);

        return {
          ...returnedFilter,
          child: {
            ...childAttribute,
            ...filter.child,
          },
        };
      }

      return returnedFilter;
    });
  }

  get listExtensions(): Extension[] {
    if (typeof this.pages === 'undefined') return [];

    return this.pages.list.extensions || [];
  }

  get hasFilter(): boolean {
    if (typeof this.pages === 'undefined') return false;

    const { filters, search } = this.pages.list;

    return !!filters?.length || !!search?.enabled;
  }

  get listPage(): ListPage | undefined {
    if (typeof this.pages === 'undefined') return undefined;

    return this.pages.list;
  }

  get name(): string {
    return this._name;
  }

  get label(): string {
    return this._label;
  }

  get hideResource(): boolean {
    return this._hideResource;
  }

  get sort(): SortPayload {
    if (typeof this.pages === 'undefined')
      return { field: 'created_at', order: 'DESC' };

    return this.pages.list.sort || { field: 'created_at', order: 'DESC' };
  }

  get filterDefaultValues(): FilterPayload | undefined {
    if (typeof this.pages === 'undefined') return;

    return this.pages.list.filterDefaultValues;
  }

  // ------------- Pages -------------------

  get deleteButton(): any {
    if (typeof this.pages === 'undefined') return null;

    return (
      (typeof this.pages.edit !== 'undefined' &&
        this.pages.edit.deleteButton) ||
      null
    );
  }

  getActionsButtons(page: PageAction): ActionButton[] {
    if (typeof this.pages === 'undefined') return [];

    const resourcePage = this.pages[page];
    if (resourcePage) {
      return resourcePage.actions || [];
    }

    return [];
  }

  getListActionsButtons(list: ListPage): ActionButton[] {
    if (typeof list.actions === 'undefined') return [];

    return list.actions;
  }

  getLeftPanel(page: PageAction | undefined): LeftPanel | undefined {
    if (typeof this.pages === 'undefined' || typeof page === 'undefined')
      return undefined;

    const resourcePage = this.pages[page];

    if (resourcePage) {
      return resourcePage.left;
    }
    throw new Error(`left panel is undefined for page ${page}`);
  }

  get rightPanel(): RightPanel | undefined {
    if (typeof this.pages === 'undefined') return undefined;

    if (typeof this.pages.edit !== 'undefined') return this.pages.edit.right;
  }

  getTabs(page: PageAction | undefined): Tab[] {
    if (typeof this.pages === 'undefined') return [];

    const leftPanel = this.getLeftPanel(page);

    return typeof leftPanel !== 'undefined' ? leftPanel.tabs : [];
  }

  hasCreatePage(): boolean {
    if (typeof this.pages === 'undefined') return false;

    return !!this.pages.list.hasCreateButton;
  }

  hasEditButton(): boolean {
    if (typeof this.pages === 'undefined') return false;

    return !!this.pages.list.hasEditButton;
  }

  getEditAndCreateBasePath(): string | undefined {
    if (typeof this.pages === 'undefined') return undefined;

    return this.pages.list.editAndCreateBasePath || undefined;
  }

  getExportKindType(): string | undefined {
    if (typeof this.pages === 'undefined') return undefined;

    return this.pages.list.exportKindType || undefined;
  }

  getSubMenuItems(): SubMenuItem[] | undefined {
    return this.subMenu?.items;
  }

  getSubMenuName(): string | undefined {
    return this.subMenu?.name;
  }

  get hasSubMenu(): boolean {
    return typeof this.subMenu === 'undefined' ? false : true;
  }
}
