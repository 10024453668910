import React, { FunctionComponent } from 'react';
import { useTranslate, useRecordContext, useNotify } from 'react-admin';
import {
  Grid as MuiGrid,
  Typography as MuiTypography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SectionProps } from './types';
import { Section as SectionType } from '../../config/types';
import {
  BasicInput,
  Field as FieldType,
  NonObjectAttribute,
} from '../../config/types/field';
import Input from '../Input';
import Field from '../Field';
import List from '../List';
import checkRules from '../../helpers/rules';
import History from '../History';
import RelatedRecord from '../RelatedRecord';

const useStyles = makeStyles({
  root: {
    paddingBottom: '24px',
    overflowX: 'scroll',
  },
});

const Sections: FunctionComponent<SectionProps> = (props) => {
  const t = useTranslate();
  const classes = useStyles();
  const { sections, resource } = props;
  const record = useRecordContext();
  const notify = useNotify();

  /**
   * Build form section rows
   *
   * @param section
   */
  function buildLayout(section: SectionType): JSX.Element[] {
    const { inputs, layout, fields = {} } = section;

    return layout.reduce((acc: any[], row: string[]) => {
      const inputItems = row.map((inputOrFieldKey) => {
        const [element, Component] = (function (): [
          BasicInput | FieldType | null,
          typeof Input | typeof Field | null
        ] {
          if (inputs[inputOrFieldKey]) return [inputs[inputOrFieldKey], Input];
          if (fields[inputOrFieldKey]) return [fields[inputOrFieldKey], Field];

          return [null, null];
        })();

        if (!Component || !element) return null;

        const source = element.source || inputOrFieldKey;

        const attribute = ((): NonObjectAttribute => {
          if (source && !resource.hasAttribute(source) && element.type)
            return {
              key: source,
              type: element.type,
            };

          return resource.getAttribute(source) as NonObjectAttribute;
        })();

        return (
          <MuiGrid key={inputOrFieldKey} item xs={12} sm={element.width || 12}>
            <Component
              {...attribute}
              {...(element as typeof Component)}
              source={source}
              fullWidth
            />
          </MuiGrid>
        );
      });

      return [...acc, ...inputItems];
    }, []);
  }

  function renderLayout() {
    return sections.map((section: SectionType) => {
      try {
        if (
          section.rules &&
          section.rules.hide &&
          checkRules(section.rules.hide, record)
        )
          return null;
      } catch (e) {
        notify(
          `"Hide" rule failed for Section ${section.title}: ${e.message}`,
          'error'
        );
      }

      // Logic for a list inside a tabs
      if (section.list && section.list.resource_to_fetch) {
        const { list, key } = section;

        return (
          <MuiGrid key={key} item xs={6} sm={12} spacing={1}>
            {sections.length > 1 && (
              <MuiTypography variant="h5" key={section.title} noWrap>
                {t(section.title)}
              </MuiTypography>
            )}
            <List
              basePath={`/${list.resource_to_fetch}`}
              resource={list.resource_to_fetch}
              fromTabs
              list={list}
              className={classes.root}
            />
          </MuiGrid>
        );
      }

      if (section.history) {
        return (
          <MuiGrid key={section.key} container>
            <History
              resource={props.resource}
              {...(section.filterResource && {
                filterResource: section.filterResource,
              })}
              {...(section.filterAttribute && {
                filterAttribute: section.filterAttribute,
              })}
            />
          </MuiGrid>
        );
      }

      return (
        <MuiGrid key={section.key} container spacing={1}>
          <MuiGrid item xs={12}>
            <MuiTypography variant="h5" key={section.title} noWrap>
              {t(section.title)}
            </MuiTypography>
          </MuiGrid>
          {section.subTitles &&
            section.subTitles.length > 0 &&
            section.subTitles?.map((item) => (
              <MuiGrid key={item.key} item xs={6} sm={item.width}>
                <MuiTypography variant="h6" noWrap>
                  {t(item.subTitle)}
                </MuiTypography>
              </MuiGrid>
            ))}
          {section.relatedRecord ? (
            <RelatedRecord relatedRecord={section.relatedRecord} />
          ) : (
            <>{buildLayout(section)}</>
          )}
        </MuiGrid>
      );
    });
  }

  return <>{renderLayout()}</>;
};

export default Sections;
