import React, { ComponentType, FunctionComponent, useCallback } from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';

/**
 * Translation of choice names
 *
 * @param Component
 * @param params
 */

const useStyles = makeStyles({
  chip: {
    // To make maxWidth dynamic according to the field width
    maxWidth: (config: Record<string, any>) => `${33 * config.width}px`,
  },
});

function withTranslation(
  Component: ComponentType<any>,
  params: Record<string, any> | undefined
): FunctionComponent {
  const Extension: FunctionComponent<Record<string, any>> = (props) => {
    const t = useTranslate();
    const { optionText } = props;
    const width = get(props, 'width', 12);
    const classes = useStyles({ width });

    const optionRenderer = useCallback((choice: Record<string, any>) => {
      if (choice) return t(choice[optionText]);

      return null;
    }, []);

    return (
      <Component
        {...props}
        optionText={optionRenderer}
        classes={{ chip: classes.chip }}
      />
    );
  };

  Extension.displayName = `withExtension(Translation)`;

  return Extension;
}

export default withTranslation;
