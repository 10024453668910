import React, { ComponentType, FunctionComponent } from 'react';
import { isEmpty, isUndefined, get } from 'lodash';

/**
 * Add validation for categories to limit the maximum of selected choices
 * Choices follow this pattern: {category} - {subCategory}
 * @param Component
 * @param params
 */

function withCatLengthValidation(
  Component: ComponentType<any>,
  params: Record<string, any> | undefined
): FunctionComponent {
  const Extension: FunctionComponent<Record<string, any>> = (props) => {
    if (isEmpty(params) || !params?.max) return null;

    const checkCategoryLength = (value: string[]) => {
      if (isUndefined(value)) return null;
      const categories = props.choices
        .filter((choice: Record<string, any>) =>
          value.includes(get(choice, 'id'))
        )
        .map(
          (choice: Record<string, any>) =>
            choice.sub_categories_name_phrase.split(' - ')[0]
        );

      const uniqueCategories = new Set(categories);

      if (uniqueCategories.size > params?.max) {
        return params?.message ?? 'resource.validators.category_length.message';
      }

      return undefined;
    };

    return (
      <Component
        {...props}
        validate={(v: string[]) => checkCategoryLength(v)}
      />
    );
  };

  Extension.displayName = `withExtension(CatLengthValidation)`;

  return Extension;
}

export default withCatLengthValidation;
