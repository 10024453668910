import React, { FunctionComponent } from 'react';
import { WysiwygInputProps } from './types';
import RichTextInput from 'ra-input-rich-text';

const WysiwygInput: FunctionComponent<WysiwygInputProps> = (
  props: WysiwygInputProps
) => (
  // To customize this component, see the docs: https://github.com/marmelab/react-admin/tree/master/packages/ra-input-rich-text

  <RichTextInput
    {...props}
    {...(props.disabled && {
      options: { readOnly: true },
    })}
  />
);
export default WysiwygInput;
