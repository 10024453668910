import React, {
  useState,
  useEffect,
  FunctionComponent,
  createElement,
} from 'react';
import {
  AutocompleteInput as RaAutocompleteInput,
  useNotify,
  useTranslate,
} from 'react-admin';
import {
  Button as MuiButton,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  TextField as MuiTextField,
  makeStyles,
} from '@material-ui/core';
import { TagInputProps } from './types';
import { httpClient } from '../../providers/dataProvider';
import useConfig from '../../hooks/useConfig';
import { getIconComponentFromName } from '../../helpers/icons';

const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  addButton: {
    zIndex: 5,
    position: 'absolute',
    right: '45px',
    top: '16px',
    backgroundColor: (config: Record<string, any>) =>
      config.palette.secondary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: (config: Record<string, any>) =>
        config.palette.primary.main,
    },
  },
});

const TagInput: FunctionComponent<TagInputProps> = (props: TagInputProps) => {
  const [tag, setTag] = useState<{ id?: string; name: string }[]>([]);
  const [value, setValue] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const t = useTranslate();
  const notify = useNotify();
  const config = useConfig();
  const classes = useStyles(config.theme);
  const { source, ...rest } = props;

  // TODO: to make this component usable for all tags, add the tagKind in the config
  const tagKind = 'document';
  const addIcon = getIconComponentFromName('Add');

  const loadHistory = () => {
    setLoading(true);
    httpClient(
      `${config.apiUrl}/tags?filter={"kind": "${tagKind}", "q": ""}&sort=["created_at","DESC"]`
    )
      .then(({ json: data }: any) => {
        setLoading(false);
        setTag(data);
      })
      .catch(() => {
        notify('notify.error.tag', 'error');
      });
  };

  useEffect(() => {
    loadHistory();
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const newTag = { name: value, id: value, kind: tagKind };
    setTag([...tag, newTag]);
    setValue('');
    setOpen(false);

    return newTag;
  };

  const newTagRenderer = () => (
    <MuiDialog open={open} onClose={() => setOpen(false)}>
      <form onSubmit={handleSubmit}>
        <MuiDialogContent>
          <MuiTextField
            label={t(`${props.label}`)}
            value={value}
            onChange={(event) => setValue(event.target.value)}
            autoFocus
          />
        </MuiDialogContent>
        <MuiDialogActions>
          <MuiButton type="submit">{t('modal.confirm.validate')}</MuiButton>
          <MuiButton onClick={() => setOpen(false)}>
            {t('modal.confirm.cancel')}
          </MuiButton>
        </MuiDialogActions>
      </form>
    </MuiDialog>
  );

  return (
    <div className={classes.root}>
      {newTagRenderer()}
      {!loading && (
        <MuiButton
          onClick={() => setOpen(true)}
          variant="contained"
          size="small"
          className={classes.addButton}
        >
          {addIcon && createElement(addIcon)}
        </MuiButton>
      )}
      <RaAutocompleteInput
        source={source}
        choices={tag}
        {...rest}
        clearAlwaysVisible
        resettable
        optionValue="name"
      />
    </div>
  );
};

export default TagInput;
