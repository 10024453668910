import { Resource as ResourceType } from '../types';

export const charge: ResourceType = {
  name: 'charges',
  label: 'resource.charges.label',
  hideResource: true,
  attributes: [
    {
      key: 'id',
      label: 'resource.charges.field.id.label',
      type: 'text',
    },
    {
      key: 'charge_id',
      label: 'resource.charges.field.id.label',
      type: 'text',
    },
    {
      key: 'quotation_id',
      label: 'resource.charges.field.quotation_id.label',
      type: 'reference',
      reference: 'quotations',
    },
    {
      key: 'title',
      label: 'resource.charges.field.title.label',
      type: 'text',
    },
    {
      key: 'charge_title',
      label: 'resource.charges.field.title.label',
      type: 'text',
    },
    {
      key: 'unit',
      label: 'resource.charges.field.unit.label',
      type: 'select',
      choices: [
        {
          id: 'units',
          name: 'resource.charges.field.unit.choices.units.label',
        },
        {
          id: 'days',
          name: 'resource.charges.field.unit.choices.days.label',
        },
        {
          id: 'forfeit',
          name: 'resource.charges.field.unit.choices.forfeit.label',
        },
        {
          id: 'hours',
          name: 'resource.charges.field.unit.choices.hours.label',
        },
      ],
    },
    {
      key: 'quantity',
      label: 'resource.charges.field.quantity.label',
      type: 'number',
    },
    {
      key: 'admin_quantity',
      label: 'resource.charges.field.admin_quantity.label',
      type: 'number',
    },
    {
      key: 'cost_per_unit',
      label: 'resource.charges.field.cost_per_unit.label',
      type: 'number',
    },
    {
      key: 'admin_cost_per_unit',
      label: 'resource.charges.field.admin_cost_per_unit.label',
      type: 'number',
    },
    {
      key: 'created_at',
      label: 'resource.charges.field.created_at.label',
      type: 'date',
    },
    {
      key: 'updated_at',
      label: 'resource.charges.field.updated_at.label',
      type: 'date',
    },
    {
      key: 'proposal_id',
      label: 'resource.charges.field.proposal_id.label',
      type: 'reference',
      reference: 'proposals',
    },
    {
      key: 'contract_milestone_id',
      label: 'resource.charges.field.contract_milestone_id.label',
      type: 'reference',
      reference: 'contract_milestones',
    },
    {
      key: 'milestone_id',
      label: 'resource.charges.field.milestone_id.label',
      type: 'reference',
      reference: 'milestones',
    },
  ],
  pages: {
    list: {
      hasEditButton: true,
      search: {
        enabled: true,
        label: 'filter.search.label',
      },
      fields: [
        {
          source: 'id',
        },
        {
          source: 'quotation_id',
          child: {
            source: 'quotations.id',
          },
        },
        {
          source: 'title',
        },
        {
          source: 'unit',
        },
        {
          source: 'quantity',
        },
        {
          source: 'cost_per_unit',
        },
        {
          source: 'admin_cost_per_unit',
        },
        {
          source: 'created_at',
        },
        {
          source: 'updated_at',
        },
      ],
    },
    edit: {
      multipleRedirect: [
        {
          entityType: 'Quotation',
          page: 'edit',
          basePath: '/proposals',
          recordId: 'proposal_id',
        },
        {
          entityType: 'ContractMilestone',
          page: 'edit',
          basePath: '/contract_milestones',
          recordId: 'contract_milestone_id',
        },
      ],
      left: {
        tabs: [
          {
            key: 'information',
            title: 'resource.charges.tab.information.title',
            sections: [
              {
                key: 'entity',
                title: 'resource.charges.section.information.title',
                layout: [
                  ['title', 'unit'],
                  ['quantity', 'admin_quantity'],
                  ['cost_per_unit', 'admin_cost_per_unit'],
                ],
                inputs: {
                  title: {
                    validators: [{ key: 'required' }],
                    width: 6,
                  },
                  unit: {
                    validators: [{ key: 'required' }],
                    width: 6,
                  },
                  quantity: {
                    validators: [
                      { key: 'required' },
                      {
                        key: 'minValue',
                        value: 0,
                      },
                    ],
                    width: 6,
                    step: 0.5,
                  },
                  admin_quantity: {
                    validators: [
                      { key: 'required' },
                      {
                        key: 'minValue',
                        value: 0,
                      },
                    ],
                    width: 6,
                    step: 0.5,
                  },
                  cost_per_unit: {
                    validators: [
                      { key: 'required' },
                      {
                        key: 'minValue',
                        value: 0,
                      },
                    ],
                    width: 6,
                  },
                  admin_cost_per_unit: {
                    validators: [
                      { key: 'required' },
                      {
                        key: 'minValue',
                        value: 0,
                      },
                    ],
                    width: 6,
                  },
                },
              },
            ],
          },
        ],
      },
      right: [
        {
          sections: [
            {
              key: 'details',
              title: 'resource.charges.section.details.title',
              layout: [
                ['id'],
                ['created_at', 'updated_at'],
                ['proposal_id'],
                ['contract_milestone_id'],
                ['milestone_id'],
              ],
              inputs: {
                id: {
                  disabled: true,
                },
                created_at: {
                  disabled: true,
                  width: 6,
                },
                updated_at: {
                  disabled: true,
                  width: 6,
                },
              },
              fields: {
                proposal_id: {
                  addLabel: true,
                  label: 'resource.charges.field.proposal_id.label',
                  child: {
                    source: 'proposal.guid',
                    type: 'text',
                  },
                  rules: {
                    hide: [
                      {
                        property: 'proposal_id',
                        op: 'eq',
                        value: null,
                      },
                    ],
                  },
                },
                contract_milestone_id: {
                  addLabel: true,
                  label: 'resource.charges.field.contract_milestone_id.label',
                  child: {
                    source: 'contract_milestone.title',
                    type: 'translatedText',
                  },
                  rules: {
                    hide: [
                      {
                        property: 'contract_milestone_id',
                        op: 'eq',
                        value: null,
                      },
                    ],
                  },
                },
                milestone_id: {
                  addLabel: true,
                  label: 'resource.charges.field.milestone_id.label',
                  child: {
                    source: 'milestone.title',
                    type: 'translatedText',
                  },
                  rules: {
                    hide: [
                      {
                        property: 'milestone_id',
                        op: 'eq',
                        value: null,
                      },
                    ],
                  },
                },
              },
            },
          ],
        },
      ],
    },
  },
};
