import React, { useState, useEffect, FunctionComponent } from 'react';
import { makeStyles, Button as MuiButton } from '@material-ui/core';
import { useTranslate, useRecordContext, useNotify } from 'react-admin';
import { HistoryProps } from './types';
import { httpClient } from '../../providers/dataProvider';
import useConfig from '../../hooks/useConfig';
import { Histories } from '../../config/types/history';
import HistoryItems from './HistoryItems';
import CreateNote from './CreateNote';

const useStyles = makeStyles({
  loadMoreWrapper: {
    height: '400px',
    overflowY: 'scroll',
    padding: '5px 0px',
    width: '100%',
  },
  root: {
    padding: '0px',
  },
  loadMoreButton: {
    display: 'block',
    margin: '20px auto 0px auto',
  },
});

const History: FunctionComponent<HistoryProps> = (props) => {
  const t = useTranslate();
  const classes = useStyles();
  const [history, setHistory] = useState<Histories>([]);
  const { resource } = props;
  const config = useConfig();
  const record = useRecordContext();
  const notify = useNotify();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const loadHistory = () => {
    setLoading(true);
    httpClient(
      `${
        config.apiUrl
      }/histories?filter={"entity_id": "${recordFilterAttribute}", "entity_type": "${
        props.filterResource ? props.filterResource : resource.name
      }"}&limit=${limit}&offset=${offset}&sort=["created_at","DESC"]`
    )
      .then(({ json: data }: any) => {
        setCount(data.count);
        if (offset === 0) {
          setHistory([...data.items]);
        } else {
          setHistory([...history, ...data.items]);
        }
        setLoading(false);
      })
      .catch(() => {
        notify('notify.error.history', 'error');
      });
  };

  const remainingItems = count - history.length;

  const recordFilterAttribute = props.filterAttribute
    ? record[props.filterAttribute]
    : record.id;

  useEffect(() => {
    if (typeof recordFilterAttribute !== 'undefined') {
      loadHistory();
    }
  }, [offset, recordFilterAttribute]);

  const loadMore = (): void => {
    setOffset(offset + limit);
  };

  if (history.length === 0) {
    return <div>{t('history.empty')}</div>;
  }

  // If a note is added the offset is reset, and the fetch restart from the beginning
  const updateHistory = (): void => {
    if (offset === 0) {
      loadHistory();
    } else {
      setOffset(0);
    }
  };

  return (
    <div className={classes.loadMoreWrapper}>
      <CreateNote
        resource={resource}
        updateHistory={updateHistory}
        {...(props.filterResource && {
          filterResource: props.filterResource,
        })}
        {...(props.filterAttribute && {
          filterAttribute: props.filterAttribute,
        })}
      />
      <HistoryItems
        history={history}
        updateHistory={updateHistory}
        {...props}
      />
      <MuiButton
        className={classes.loadMoreButton}
        disabled={history.length === count || loading}
        onClick={loadMore}
        variant="contained"
      >
        {t('history.load_more')}
        {remainingItems > 0 && <span> ({remainingItems})</span>}
      </MuiButton>
    </div>
  );
};

export default History;
