import { useTranslate } from 'react-admin';
import { HistoryUpdatedField } from '../config/types/history';
import { historyTranslateFields } from '../constants/history';

// translations arg before, after
export const translationField = (
  itemValue: string,
  translation_key: HistoryUpdatedField | string
) => {
  const t = useTranslate();

  const translate_label =
    historyTranslateFields[translation_key] !== undefined &&
    historyTranslateFields[translation_key].translation_prefix
      ? t(
          `${
            historyTranslateFields[translation_key].translation_prefix
          }.${itemValue}${
            historyTranslateFields[translation_key].translation_suffix
              ? `.${historyTranslateFields[translation_key].translation_suffix}`
              : ''
          }`
        )
      : itemValue;

  return translate_label;
};
