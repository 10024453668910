import { ComponentType, FunctionComponent } from 'react';
import Password from '../components/Login/password';
import { Login as LoginType } from '../config/types';
import Sso from '../components/Login/sso';
import { SsoProps } from '../components/Login/sso';
import { LoginComponent as RaLoginComponentType } from 'ra-core';

export type ExtractPropsFromComponentType<P> = P extends ComponentType<infer T>
  ? T
  : never;

type LoginPropsMap = {
  password: RaLoginComponentType;
  sso: SsoProps;
};

export type LoginComponent<T> = T extends keyof LoginPropsMap
  ? FunctionComponent<LoginPropsMap[T]>
  : never;

export default class LoginFactory {
  private static loginMap: {
    [prop: string]: LoginComponent<LoginType>;
  } = {
    password: Password,
    sso: Sso,
  };

  static getLoginComponent(type: string): LoginComponent<LoginType> {
    if (Object.keys(LoginFactory.loginMap).includes(type))
      return LoginFactory.loginMap[type];
    throw new Error(`Login type ${type} does not exists`);
  }
}
