import * as React from 'react';
import {
  EditProps as RaEditProps,
  Edit as RaEdit,
  useRedirect,
  useNotify,
  useUpdate,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { FunctionComponent, useCallback } from 'react';
import ActionButtons from '../ActionButtons';
import Form from '../Form';
import { transformValuesBeforeSubmit } from '../../helpers/form';
import { handleError } from '../../helpers/error';

const useStyles = makeStyles({
  card: {
    boxShadow: 'none',
    background: 'none',
  },
});

const Edit: FunctionComponent<RaEditProps> = (props) => {
  const classes = useStyles();
  const redirectTo = useRedirect();
  const notify = useNotify();
  const [update] = useUpdate();

  const { basePath, resource: resourceName } = props;
  const page = 'edit';

  /// Override default behaviour to transform values before submit
  const handleSave = useCallback(
    (resource, record) => (values: Record<string, any>, redirect: string) => {
      const updatedValues = transformValuesBeforeSubmit(resource, values);

      update(resourceName, values.id, updatedValues, record, {
        onSuccess: ({ data: newRecord }) => {
          let basePathName = basePath;
          let recordId = record.id;
          if (typeof resource.pages.edit.singleRedirect !== 'undefined') {
            const configRedirect = resource.pages.edit.singleRedirect;
            redirect = configRedirect.page;
            basePathName = configRedirect.basePath;
            recordId = record[configRedirect.recordId];
          }
          if (typeof resource.pages.edit.multipleRedirect !== 'undefined') {
            const configRedirect = resource.pages.edit.multipleRedirect.reduce(
              (acc: Record<string, any>, currentValue: Record<string, any>) => {
                if (currentValue.entityType === record.entity_type) {
                  acc = {
                    redirect: currentValue.page,
                    basePathName: currentValue.basePath,
                    recordId: record[currentValue.recordId],
                  };
                }

                return acc;
              },
              { redirect: '', basePathName: '' }
            );
            redirect = configRedirect.redirect;
            basePathName = configRedirect.basePathName;
            recordId = configRedirect.recordId;
          }
          redirectTo(redirect, basePathName, recordId, newRecord);
        },
        onFailure: (error: Record<string, any>) => {
          handleError(error, notify);
        },
      });
    },
    [redirectTo, basePath, update, transformValuesBeforeSubmit]
  );

  return (
    <RaEdit
      {...props}
      classes={classes}
      undoable={false}
      actions={
        <ActionButtons
          basePath={basePath}
          resource={props.resource}
          page={page}
        />
      }
    >
      <Form
        {...props}
        page={page}
        handleSave={handleSave}
        resource={resourceName}
        withRight
      />
    </RaEdit>
  );
};

export default Edit;
