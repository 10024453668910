/* eslint-disable react/display-name */
import Extensions from './index';
import { ComponentType } from 'react';
import { Extension } from '../config/types';

const withExtensions =
  (extensions: Extension[] | undefined) => (Component: ComponentType<any>) => {
    if (!extensions || !extensions.length) return Component;

    return extensions.reduce((acc, extension) => {
      if (Extensions[extension.key]) {
        return Extensions[extension.key](acc, extension.params);
      }

      return acc;
    }, Component);
  };

export default withExtensions;
