import { FunctionComponent } from 'react';
import * as Icons from '@material-ui/icons';

export const getIconComponentFromName = (
  iconName: string | undefined
): FunctionComponent | undefined => {
  if (!iconName) return undefined;
  if ({ ...Icons }?.[iconName]) {
    return (Icons as Record<string, FunctionComponent>)[iconName];
  }

  return undefined;
};
