import React, { ComponentType, FunctionComponent } from 'react';
import { modifiedInputLabel } from '../../helpers/label';

/**
 * Modify label according to conditions
 *
 *
 * @param Component
 */
function withModifiedLabel(Component: ComponentType<any>): FunctionComponent {
  const Extension: FunctionComponent<Record<string, any>> = (props) => {
    const { record, source } = props;

    if (!record || !source) return null;

    return (
      <Component {...props} label={modifiedInputLabel(props.label, record)} />
    );
  };

  Extension.displayName = `withExtension(ModifiedLabel)`;

  return Extension;
}

export default withModifiedLabel;
