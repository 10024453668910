import { makeStyles, Typography as MuiTypography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { HistoryItemsChildsUpdatedProps } from '../../types';
import {
  History as HistoryType,
  HistoryDetails,
} from '../../../../../config/types/history';
import { isEmpty } from 'lodash';
import RangeField from '../HistoryFields/RangeField';
import TextField from '../HistoryFields/TextField';
import DateField from '../HistoryFields/DateField';
import TagField from '../HistoryFields/TagField';
import ArrayField from '../HistoryFields/ArrayField';
import WysiwygField from '../HistoryFields/WysiwigField';
import BoolField from '../HistoryFields/BoolField';
import {
  UPDATED_FIELD_ARRAY,
  UPDATED_FIELD_BOOL,
  UPDATED_FIELD_DATE,
  UPDATED_FIELD_RANGE,
  UPDATED_FIELD_TAG,
  UPDATED_FIELD_TEXT,
  UPDATED_FIELD_WYSIWYG,
} from '../../../../../constants/history';
import { getLocaleDateString } from '../../../../../helpers/date';

const useStyles = makeStyles({
  childs: {
    margin: '0px',
  },
  child: {
    margin: '2px',
    marginLeft: '-20px',
  },
  date: {
    marginLeft: '5px',
  },
});

// dispatch of different display of details (text, date, range, array, etc...)
const getChildUpdatedDetailsTranslation = (
  details: HistoryDetails
): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      {details.type === UPDATED_FIELD_TEXT && <TextField details={details} />}
      {details.type === UPDATED_FIELD_RANGE && <RangeField details={details} />}
      {details.type === UPDATED_FIELD_BOOL && <BoolField details={details} />}
      {details.type === UPDATED_FIELD_WYSIWYG && (
        <WysiwygField details={details} />
      )}
      {details.type === UPDATED_FIELD_DATE && <DateField details={details} />}
      {details.type === UPDATED_FIELD_TAG && <TagField details={details} />}
      {details.type === UPDATED_FIELD_ARRAY && <ArrayField details={details} />}
      {details.args &&
        typeof details.args.date !== 'undefined' &&
        details.args.date && (
          <span className={classes.date}>
            {getLocaleDateString(details.args.date as string)}
          </span>
        )}
    </>
  );
};

// Recurcivity of display history childs updated
const HistoryItemsChildsUpdated: FunctionComponent<HistoryItemsChildsUpdatedProps> =
  (props) => {
    const classes = useStyles();
    const { childs } = props;

    return (
      <ul className={classes.childs}>
        {childs.map((c: HistoryType) => (
          <li className={classes.child} key={c.id}>
            <MuiTypography>
              {c.details && getChildUpdatedDetailsTranslation(c.details)}
              {!isEmpty(c.childs) && (
                <HistoryItemsChildsUpdated childs={c.childs} />
              )}
            </MuiTypography>
          </li>
        ))}
      </ul>
    );
  };

export default HistoryItemsChildsUpdated;
