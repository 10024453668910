import { Resource as ResourceType } from '../types';

export const candidate: ResourceType = {
  name: 'candidates',
  label: 'resource.candidates.label',
  hideResource: true,
  attributes: [
    {
      key: 'id',
      label: 'resource.candidates.field.id.label',
      type: 'text',
    },
    {
      key: 'attachments',
      label: 'resource.missions.field.attachments.label',
      type: 'buttons',
    },
    {
      key: 'collaborator',
      label: 'resource.candidates.field.last_name.label',
      type: 'object',
      shape: [
        {
          key: 'last_name',
          label: 'resource.candidates.field.last_name.label',
          type: 'text',
        },
        {
          key: 'first_name',
          label: 'resource.candidates.field.first_name.label',
          type: 'text',
        },
        {
          key: 'job_name',
          label: 'resource.candidates.field.job_name.label',
          type: 'text',
        },
      ],
    },
    {
      key: 'daily_rate',
      label: 'resource.candidates.field.daily_rate.label',
      type: 'number',
    },
    {
      key: 'admin_daily_rate',
      label: 'resource.candidates.field.admin_daily_rate.label',
      type: 'number',
    },
    {
      key: 'outsourcing',
      label: 'resource.candidates.field.outsourcing.label',
      type: 'select',
      choices: [
        {
          id: 'employee',
          name: 'resource.candidates.field.outsourcing.choices.employee.label',
        },
        {
          id: 'subcontractor',
          name: 'resource.candidates.field.outsourcing.choices.subcontractor.label',
        },
      ],
    },
  ],
  pages: {
    list: {
      hasEditButton: true,
      hasCreateButton: false,
      fields: [],
    },
    edit: {
      singleRedirect: {
        page: 'edit',
        basePath: '/candidacies',
        recordId: 'candidacy_id',
      },
      left: {
        tabs: [
          {
            key: 'candidate',
            title: 'resource.candidate.tab.information.title',
            sections: [
              {
                key: 'entity',
                title: 'resource.candidate.section.information.title',
                layout: [
                  ['collaborator.last_name', 'collaborator.first_name'],
                  ['collaborator.job_name', 'outsourcing'],
                  ['daily_rate', 'admin_daily_rate'],
                  ['attachments'],
                ],
                inputs: {
                  'collaborator.last_name': {
                    width: 6,
                    disabled: true,
                  },
                  'collaborator.first_name': {
                    width: 6,
                    disabled: true,
                  },
                  'collaborator.job_name': {
                    width: 6,
                    disabled: true,
                  },
                  daily_rate: {
                    width: 6,
                    step: 10,
                    min: 150,
                    max: 2000,
                    validators: [
                      { key: 'required' },
                      { key: 'minValue', value: 150 },
                      { key: 'maxValue', value: 2000 },
                    ],
                  },
                  admin_daily_rate: {
                    width: 6,
                    step: 10,
                    min: 150,
                    max: 2000,
                    validators: [
                      { key: 'required' },
                      { key: 'minValue', value: 150 },
                      { key: 'maxValue', value: 2000 },
                    ],
                  },
                  outsourcing: {
                    width: 6,
                  },
                },
                fields: {
                  attachments: {
                    addLabel: true,
                    extensions: [
                      {
                        key: 'attachmentsFiles',
                      },
                    ],
                  },
                },
              },
            ],
          },
        ],
      },
    },
  },
};
