import { AuthProvider } from 'react-admin';
import {
  deleteCookies,
  getToken,
  handleCookies,
  isAdmin,
  isTokenExpired,
} from '../helpers/auth';
import { config } from '../helpers/config';

type Auth = {
  username: string;
  password: string;
};

const authProvider: AuthProvider = {
  login: async ({ username, password }: Auth): Promise<void> => {
    const response = await fetch(`${config.apiUrl}/sign_in`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({
        email: username,
        password,
      }),
    });

    const { token, scopes, user_id, expired_at } = await response.json();
    handleCookies(token, scopes, user_id, new Date(expired_at));

    if (!isAdmin(scopes)) {
      return Promise.reject(new Error('auth.error.notAdmin'));
    }

    return Promise.resolve();
  },
  logout: async (): Promise<void> => {
    await fetch(`${config.apiUrl}/sign_out`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    deleteCookies();

    return Promise.resolve();
  },
  checkError: ({ status }): Promise<void> => {
    if (status === 401 || status === 403) {
      deleteCookies();

      return Promise.reject();
    }

    return Promise.resolve();
  },
  checkAuth: (): Promise<void> => {
    if (isTokenExpired() || !isAdmin()) {
      deleteCookies();
      // Even if the token is expired the app can bug and not redirect to the login
      if (window.location.pathname !== '/login') {
        window.location.assign('/login');
      }

      return Promise.reject();
    }

    return Promise.resolve();
  },
  getPermissions: (): Promise<void> => Promise.reject('Unknown method'),
};

export default authProvider;
