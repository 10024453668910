import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import React, { FunctionComponent } from 'react';
import { useTranslate } from 'react-admin';
import { WysiwygFieldProps } from './type';

const WysiwygField: FunctionComponent<WysiwygFieldProps> = (props) => {
  const { details } = props;
  const t = useTranslate();
  const beforeStrippedHtml =
    details.args !== undefined && !isEmpty(details.args.before)
      ? details.args.before.replace(/<[^>]+>/g, '')
      : '';
  const afterStrippedHtml =
    details.args !== undefined && !isEmpty(details.args.after)
      ? details.args.after.replace(/<[^>]+>/g, '')
      : '';

  return (
    <div>
      {t(`${details.translation_key}.details`)}
      {details.args !== undefined && (
        <>
          {!isEmpty(details.args.before) ? (
            <Accordion>
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{`${beforeStrippedHtml.slice(
                  0,
                  50
                )}...`}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: details.args.before,
                    }}
                  ></div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          ) : (
            details.args.before !== null &&
            t('history.company.field_updated.no_tags')
          )}
          {details.args.before !== null && ' → '}
          {!isEmpty(details.args.after) ? (
            <Accordion>
              <AccordionSummary
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>{`${afterStrippedHtml.slice(
                  0,
                  50
                )}...`}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: details.args.after,
                    }}
                  ></div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          ) : (
            t('history.company.field_updated.no_tags')
          )}
        </>
      )}
    </div>
  );
};

export default WysiwygField;
