import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Login as RaLogin } from 'react-admin';
import useConfig from '../../../hooks/useConfig';

const useStyles = makeStyles({
  main: {
    background: 'white !important',
  },
});

const Password: FunctionComponent = () => {
  const classes = useStyles();
  const { theme } = useConfig();

  return <RaLogin backgroundImage="" theme={theme} classes={classes} />;
};

export default Password;
