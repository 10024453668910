import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  FileInput as RaFileInput,
  FileField as RaFileField,
} from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { v4 as uuidv4 } from 'uuid';
import { httpClient } from '../../providers/dataProvider';
import { FileInputProps } from './types';
import useConfig from '../../hooks/useConfig';
import axios from 'axios';

const FileInput: FunctionComponent<FileInputProps> = (
  props: FileInputProps
) => {
  const { source, resource, label, record } = props;
  const accept =
    props?.options?.accept || '.doc,.docx,.pdf,.ptt,.pptx,.xls,.xlsx';
  const kind = props?.options?.kind || 'attachment';
  const config = useConfig();
  const form = useForm();
  const formState = useFormState();
  const [attachments, setAttachments] = useState<string[]>([]);
  const [attachmentsUploaded, setAttachmentsUploaded] = useState<
    Record<string, any>[]
  >([]);

  const formAttachments = formState.values[source];

  const handlePostAttachment = (attachment: Record<string, any>) =>
    httpClient(`${config.apiUrl}/attachments`, {
      method: 'POST',
      body: JSON.stringify({
        read_url: attachment.src,
        name: attachment.title,
        mime_type: attachment.rawFile.type,
        size: attachment.rawFile.size,
        kind: kind,
        file_uuid: uuidv4(),
      }),
    });

  const handlePutAttachment = async (
    attachment: Record<string, any>,
    formAttachmentAdded: Record<string, any>
  ) =>
    await axios.put(attachment.upload_url, formAttachmentAdded.rawFile, {
      headers: {
        'Content-Type': formAttachmentAdded.rawFile.type,
      },
    });

  useEffect(() => {
    if (record && record[source]) {
      // Init state with record values (useful in edit mode)
      setAttachments(record[source].map((a: Record<string, any>) => a.id));
      setAttachmentsUploaded(
        record[source].map((a: Record<string, any>) => ({
          id: a.id,
          src: a.src,
        }))
      );
    }
  }, [record]);

  useEffect(() => {
    if (formAttachments) {
      // Declare an array of Promise that contains the id and src and the attachment sent by the API
      const attachmentPromise = (formAttachments || []).map(
        (formAttachment: Record<string, any>) => {
          if (formAttachment.rawFile) {
            // Check if the attachment src is already includes in the attachmentsUploaded array
            // to avoid sending a duplicate attachment
            if (
              !attachmentsUploaded
                .map((a) => a.src)
                .includes(formAttachment.src)
            ) {
              return handlePostAttachment(formAttachment)
                .then(({ json: data }: any) => {
                  // Upload the attachment to Amazon S3
                  handlePutAttachment(data, formAttachment);

                  return { id: data.id, src: formAttachment.src };
                })
                .catch((error: Record<string, any>) => ({ error }));
            }
          }
        }
      );
      Promise.all(attachmentPromise).then((values: any) => {
        // Remove undefined values(when an attachment is already uploaded, the Promise value is undefined)
        const newValues: Record<string, any>[] = values.filter(
          (v: Record<string, any> | undefined) => v !== undefined
        );
        // Declare an array with the previous attachmentsUploaded + the new one(s)
        const newAttachmentsUploaded: Record<string, any>[] = [
          ...attachmentsUploaded,
          ...newValues,
        ];
        const formAttachmentsSrc: string[] = formAttachments.map(
          (a: Record<string, any>) => a.src
        );
        // Declare the updated array of ids
        const newAttachmentsIds: string[] = newAttachmentsUploaded.reduce(
          (acc: string[], a: Record<string, string>) => {
            if (formAttachmentsSrc.includes(a.src)) {
              return [...acc, a.id];
            }

            return [...acc];
          },
          []
        );
        setAttachments(newAttachmentsIds);
        setAttachmentsUploaded(newAttachmentsUploaded);
      });
    }
  }, [formAttachments]);

  useEffect(() => {
    // Update values in the form
    form.change(`${resource}_${source}`, attachments);
  }, [attachments]);

  return (
    <RaFileInput source={source} label={label} accept={accept} multiple>
      <RaFileField source="src" title="title" />
    </RaFileInput>
  );
};

export default FileInput;
