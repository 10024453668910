import React, { ComponentType, FunctionComponent } from 'react';
import { useFormState } from 'react-final-form';
import { Choice } from '../../config/types/field';

/**
 * Disable choices not corresponding to current value or value 'mixed'
 *
 * @param Component
 */
function withUserKindDisabledChoices(
  Component: ComponentType<any>
): FunctionComponent {
  const Extension: FunctionComponent<Record<string, any>> = (props) => {
    const { values } = useFormState();
    const { choices, source } = props;
    const value = values[source];

    const transformedChoices = choices.map((choice: Choice) => {
      if (choice.id !== 'mixed' && choice.id !== value)
        return {
          ...choice,
          disabled: true,
        };

      return choice;
    });

    return <Component {...props} choices={transformedChoices} />;
  };

  Extension.displayName = `withExtension(UserKindDisabledChoices)`;

  return Extension;
}

export default withUserKindDisabledChoices;
