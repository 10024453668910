import React, { FunctionComponent } from 'react';
import { Notification as RaNotification, NotificationProps } from 'react-admin';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  notification: {
    whiteSpace: 'pre-wrap',
  },
});

const Notification: FunctionComponent<NotificationProps> = (props) => {
  const classes = useStyles();

  return (
    <RaNotification
      {...props}
      autoHideDuration={10000}
      className={classes.notification}
    />
  );
};

export default Notification;
