import React, { Fragment, FunctionComponent } from 'react';
import { BulkActionButtonsProps } from './types';
import ActionButtonFactory from '../../factories/ActionButtonFactory';
import { useTranslate } from 'react-admin';
import { ExtractPropsFromFC } from '../../types';

const BulkActionButtons: FunctionComponent<BulkActionButtonsProps> = (
  props
) => {
  const t = useTranslate();
  const { bulkActions, ...restProps } = props;

  return (
    <Fragment>
      {bulkActions.map((bulkActionButton) => {
        const { type, label, name, ...otherProps } = bulkActionButton;
        const Button = ActionButtonFactory.getButtonComponent(type);

        return (
          <Button
            key={name}
            label={t(label)}
            {...restProps}
            {...(otherProps as ExtractPropsFromFC<typeof Button>)}
          />
        );
      })}
    </Fragment>
  );
};

export default BulkActionButtons;
