import React, { FunctionComponent, useState } from 'react';
import {
  useTranslate,
  FormWithRedirect as RaFormWithRedirect,
  SaveButton as RaSaveButton,
  useResourceContext,
  useRecordContext,
} from 'react-admin';
import { makeStyles, TextField as MuiTextField } from '@material-ui/core';
import { CreateNoteProps } from './types';
import { httpClient } from '../../../providers/dataProvider';
import { config } from '../../../helpers/config';

const useStyles = makeStyles({
  input: {
    marginBottom: '0.5rem',
    '& p': {
      display: 'none',
    },
  },
  button: {
    marginBottom: '1rem',
    marginLeft: 'auto',
    display: 'flex',
    fontSize: '11px',
  },
});

const CreateNote: FunctionComponent<CreateNoteProps> = (props) => {
  const resource = useResourceContext();
  const record = useRecordContext();
  const classes = useStyles();
  const [note, setNote] = useState('');
  const t = useTranslate();

  const handleSubmit = (values: Record<string, any>) => {
    const { id } = values;
    httpClient(`${config.apiUrl}/histories`, {
      method: 'POST',
      body: JSON.stringify({
        note,
        id: props.filterAttribute ? record[props.filterAttribute] : id,
        resource: props.filterResource ? props.filterResource : resource,
      }),
    })
      .then(() => {
        props.updateHistory();
        setNote('');
      })
      .catch((error: Record<string, any>) => ({ error, id }));
  };

  return (
    <RaFormWithRedirect
      resource="histories"
      render={({ handleSubmitWithRedirect, saving }) => (
        <>
          <div>
            <MuiTextField
              label={t('history.note.label')}
              variant="filled"
              name={'note'}
              value={note}
              multiline
              fullWidth
              onChange={(e) => setNote(e.target.value)}
              className={classes.input}
              margin="dense"
            />
          </div>
          <RaSaveButton
            handleSubmitWithRedirect={handleSubmitWithRedirect}
            saving={saving}
            onSave={handleSubmit}
            size="small"
            className={classes.button}
          />
        </>
      )}
    />
  );
};

export default CreateNote;
