import input from './input';
import list from './list';
import field from './field';
import button from './button';
import { ObjectOf } from '../types';
import { ComponentType, FunctionComponent } from 'react';

const extensions: ObjectOf<
  (
    component: ComponentType<unknown>,
    params: Record<string, any> | undefined
  ) => FunctionComponent<unknown>
> = {
  ...field,
  ...input,
  ...list,
  ...button,
};

export default extensions;
