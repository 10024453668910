import React, { ComponentType, FunctionComponent } from 'react';

/**
 * Transform pdfs object into clickable button to download a pdf for each language
 *
 * @param Component
 */
function withMultipleLangFiles(
  Component: ComponentType<any>
): FunctionComponent {
  const Extension: FunctionComponent<Record<string, any>> = (props) => {
    const { record, source } = props;

    if (!record || !Array.isArray(record[source])) return null;

    const buttons = record[source].map((pdf: Record<string, any>) => ({
      id: pdf.id,
      href: pdf.public_url,
      label: pdf.attachment_information.language_alpha2,
    }));

    return <Component {...props} record={{ ...record, [source]: buttons }} />;
  };

  Extension.displayName = `withExtension(MultipleLangFiles)`;

  return Extension;
}

export default withMultipleLangFiles;
