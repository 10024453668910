import React, { FunctionComponent, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Login as RaLogin,
  Notification as RaNotification,
  useNotify,
} from 'react-admin';
import { SsoProps } from './types';
import { handleCookies, isAdmin, isTokenExpired } from '../../../helpers/auth';
import { parse } from 'query-string';
import { isProduction } from '../../../helpers/environment';
import SsoButton from './components/SsoButton';
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
} from '@material-ui/core';

const useStyles = makeStyles({
  main: {
    background: 'white !important',
    position: 'relative',
    '& .MuiPaper-root': {
      marginTop: '0px',
    },
  },
  ssoLoginCard: {
    width: 300,
    height: 200,
    position: 'absolute',
    top: '30%',
    left: '40%',
  },
  ssoLoginLogo: {
    width: '70%',
    margin: '30px 0 30px 0',
  },
  ssoLoginCardContent: {
    textAlign: 'center',
  },
  ssoLoginButtonContainer: {
    textAlign: 'center',
    margin: '24px',
  },
});

const Sso: FunctionComponent<SsoProps> = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const { history } = props;

  useEffect(() => {
    redirectIfAuthenticated();
  }, []);

  const redirectIfAuthenticated = () => {
    // Get token, and scope variables from url query, in the case of SSO login
    const { token, scopes, user_id, error } = parse(history.location.search);

    // If token and scope are passed through url params and store them in a cookie
    if (token && scopes) {
      if (!isAdmin(scopes)) {
        notify('auth.error.notAdmin');
      }
      handleCookies(token as string, scopes, user_id as string);
    }

    if ((token && scopes) || !isTokenExpired()) {
      history.push('/');
    } else if (error) {
      notify('Error');
      history.push('/login');
    }
  };

  return (
    <div>
      {isProduction ? (
        <MuiCard className={classes.ssoLoginCard} variant="outlined">
          <RaNotification type="error" />
          <MuiCardContent className={classes.ssoLoginCardContent}>
            <img
              className={classes.ssoLoginLogo}
              src="kicklox/logo.png"
              alt="Logo"
            />
            <SsoButton />
          </MuiCardContent>
        </MuiCard>
      ) : (
        <div>
          <SsoButton classname={classes.ssoLoginButtonContainer} />
          <RaLogin backgroundImage="" className={classes.main} />
        </div>
      )}
    </div>
  );
};

export default Sso;
