import * as React from 'react';
import { cloneElement, FunctionComponent } from 'react';
import {
  sanitizeListRestProps,
  TopToolbar as RaToolbar,
  useListContext,
  useRecordContext,
} from 'react-admin';
import { CreateButton as RaCreateButton } from 'ra-ui-materialui/lib/button';
import ExportButton from '../ExportButton';
import useConfig from '../../../../hooks/useConfig';
import ActionButtons from '../../../ActionButtons';
import { ListActionsProps } from './types';

const ListActions: FunctionComponent<ListActionsProps> = (props) => {
  const { className, filters, ...restProps } = props;
  const {
    resource: resourceName,
    displayedFilters,
    filterValues,
    showFilter,
  } = useListContext();
  const config = useConfig();
  const resource = config.getResource(resourceName);
  const record = useRecordContext();

  return (
    <RaToolbar className={className} {...sanitizeListRestProps(restProps)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {!props.fromTabs && <ExportButton />}
      {resource.hasCreatePage() && !props.fromTabs && (
        <RaCreateButton
          {...(typeof resource.getEditAndCreateBasePath() !== undefined && {
            basePath: resource.getEditAndCreateBasePath(),
          })}
        />
      )}
      {props.actions && props.actions.length > 0 && (
        <ActionButtons
          actions={props.actions}
          data={record}
          fromTabs={props.fromTabs}
        />
      )}
    </RaToolbar>
  );
};

export default ListActions;
