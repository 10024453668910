import React, { FunctionComponent } from 'react';
import { useTranslate, FunctionField } from 'react-admin';
import { TranslatedTextFieldProps } from './types';

const TranslatedTextField: FunctionComponent<TranslatedTextFieldProps> = (
  props
) => {
  const t = useTranslate();

  return (
    <FunctionField
      render={(
        record: Record<string, string> | undefined,
        source: string | undefined
      ) => {
        if (
          typeof record === 'undefined' ||
          typeof source === 'undefined' ||
          !record[source]
        ) {
          return null;
        }

        return t(record[source]);
      }}
      {...props}
    />
  );
};

export default TranslatedTextField;
