import { Resource as ResourceType } from '../types';

export const match: ResourceType = {
  name: 'matches',
  label: 'resource.matches.label',
  hideResource: true,
  attributes: [
    {
      key: 'id',
      type: 'reference',
      label: 'resource.matches.field.mission_id.label',
      reference: 'missions',
    },
    {
      key: 'owner_id',
      label: 'resource.matches.field.owner_id.label',
      type: 'reference',
      reference: 'users',
    },
    {
      key: 'percent',
      label: 'resource.matches.field.percent.label',
      type: 'text',
    },
  ],
  pages: {
    list: {
      fields: [],
    },
    edit: {
      left: {
        tabs: [],
      },
      right: [],
    },
  },
};
