import React, { FunctionComponent } from 'react';
import { Grid as MuiGrid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Identifier, useTranslate, Record as RaRecord } from 'react-admin';
import ArrayField from '../../ArrayField';

const useStyles = makeStyles({
  container: {
    paddingLeft: '48px',
    paddingTop: '0px',
    paddingBottom: '3px',
  },
  child: {
    marginTop: '0px',
    marginLeft: '0px',
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
    '& > *': {
      margin: '2px',
    },
  },
  label: {
    fontWeight: 500,
    marginBottom: '5px',
  },
  description: {
    marginBottom: '10px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

const SkillsCartography: FunctionComponent<{
  basePath: string;
  id: Identifier;
  record: RaRecord;
  resource: string;
}> = (props) => {
  const t = useTranslate();
  const classes: any = useStyles();

  return (
    <MuiGrid key="Skills" className={classes.container} container>
      <MuiGrid xs={12}>
        <div className={classes.label}>
          {t('resource.skills.field.description.label')}
        </div>
        <div className={classes.description}>{props.record.description}</div>
      </MuiGrid>
      <MuiGrid xs={12}>
        <div className={classes.label}>
          {t('resource.skills.field.childs.label')}
        </div>
        <ArrayField
          {...props}
          source="childs"
          itemSource="name"
          translationPrefix=""
          className={classes.child}
        />
      </MuiGrid>
    </MuiGrid>
  );
};

export default SkillsCartography;
