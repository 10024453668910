import React, {
  createElement,
  FunctionComponent,
  useState,
  useEffect,
} from 'react';
import {
  FormWithRedirect as RaFormWithRedirect,
  SaveButton as RaSaveButton,
  useRedirect,
  useTranslate,
  useDelete,
  useNotify,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box as MuiBox,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Grid as MuiGrid,
  Toolbar as MuiToolbar,
  Typography as MuiTypography,
} from '@material-ui/core';
import { throttle } from 'lodash';
import Sections from '../Sections';
import Tabs from '../Tabs';
import { Block } from '../../config/types';
import { FormProps } from './types';
import Resource from '../../models/Resource';
import useConfig from '../../hooks/useConfig';
import checkConditions from '../../helpers/conditions';
import { Button as RaButton } from 'ra-ui-materialui/lib/button';
import ConfirmationModal from '../modals/ConfirmationModal';
import { getIconComponentFromName } from '../../helpers/icons';
import { MISSIONS, USERS, PROPOSALS } from '../../constants/resources';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    marginTop: '24px',
  },
  spacing: {
    marginBottom: '24px',
  },
  button: {
    color: 'red',
  },
});

const Form: FunctionComponent<FormProps> = (props) => {
  const classes = useStyles();
  const t = useTranslate();
  const config = useConfig();
  const redirectTo = useRedirect();
  const [showDialog, setShowDialog] = useState(false);

  const { handleSave: handleSaveClosure, page, withRight, record } = props;

  const resource: Resource = config.getResource(props.resource as string);
  const tabs = resource.getTabs(page);
  const handleSave = handleSaveClosure(resource, record);
  const IconComponent = getIconComponentFromName('Delete');
  const notify = useNotify();

  // With large images (only in missions, users and proposals resource) the form layout size can be bugged, the following logic help to resize the form
  const triggerDynamicWidth = [MISSIONS, USERS, PROPOSALS].includes(
    resource.name
  );

  const [dynamicWidth, setDynamicWidth] = useState(
    window.innerWidth - 240 - 30
  );

  const handleResize = throttle(() => {
    // 240 and 30 represent the width of the left menu and padding
    setDynamicWidth(window.innerWidth - 240 - 30);
  }, 400);

  useEffect(() => {
    if (triggerDynamicWidth) {
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  const disabled =
    resource.deleteButton && resource.deleteButton.disabled && record
      ? checkConditions(
          {
            rules: resource.deleteButton.disabled,
            satisfy: 'ALL',
          },
          record
        )
      : false;

  const [deleteOne] = useDelete(resource.name, record.id, null, {
    onFailure: () => {
      if (resource.name === 'milestones') {
        notify('notify.error.milestone.deleted', 'error');
      }
    },
  });

  function handleClick() {
    deleteOne();
    setShowDialog(false);
    redirectTo(
      resource.deleteButton.redirect.page,
      resource.deleteButton.redirect.basePath,
      record[resource.deleteButton.redirect.recordId]
    );
  }

  function handleOpenModal() {
    setShowDialog(true);
  }

  function handleCloseModal() {
    setShowDialog(false);
  }

  return (
    <RaFormWithRedirect
      {...props}
      {...(props.mutators && {
        mutators: props.mutators,
      })}
      render={(formProps) => (
        <form>
          <MuiGrid
            className={classes.root}
            {...(triggerDynamicWidth && {
              style: { maxWidth: `${dynamicWidth}px` },
            })}
          >
            <MuiGrid container spacing={3}>
              <MuiGrid item xs={12}>
                <MuiTypography variant="h4">{t(resource.label)}</MuiTypography>
              </MuiGrid>
              <MuiGrid item xs={12} sm={7}>
                {tabs.length === 1 ? (
                  <MuiCard key={0} className={classes.spacing}>
                    <MuiCardContent>
                      <Sections
                        sections={tabs[0].sections}
                        resource={resource}
                      />
                    </MuiCardContent>
                  </MuiCard>
                ) : (
                  <Tabs
                    tabs={tabs}
                    renderItem={(tab) => (
                      <Sections sections={tab.sections} resource={resource} />
                    )}
                  />
                )}
                <MuiToolbar>
                  <MuiBox
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <RaSaveButton
                      saving={formProps.saving}
                      handleSubmitWithRedirect={
                        formProps.handleSubmitWithRedirect
                      }
                      onSave={handleSave}
                    />
                    {resource.deleteButton && (
                      <>
                        <RaButton
                          label={resource.deleteButton.label}
                          disabled={disabled}
                          onClick={handleOpenModal}
                          className={classes.button}
                          {...(IconComponent && {
                            startIcon: createElement(IconComponent),
                          })}
                        />
                        <ConfirmationModal
                          open={showDialog}
                          handleClick={handleClick}
                          handleClose={handleCloseModal}
                        >
                          {t(resource.deleteButton.confirmation.content)}
                        </ConfirmationModal>
                      </>
                    )}
                  </MuiBox>
                </MuiToolbar>
              </MuiGrid>
              {withRight && (
                <MuiGrid item xs={12} sm={5}>
                  {typeof resource.rightPanel !== 'undefined' &&
                    resource.rightPanel.map((block: Block, index: number) => (
                      <MuiCard key={index} className={classes.spacing}>
                        <MuiCardContent>
                          <Sections
                            sections={block.sections}
                            resource={resource}
                          />
                        </MuiCardContent>
                      </MuiCard>
                    ))}
                </MuiGrid>
              )}
            </MuiGrid>
          </MuiGrid>
        </form>
      )}
    />
  );
};

export default Form;
