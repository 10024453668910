import React from 'react';
import { I18nProvider } from 'react-admin';
import { contextTranslateKeys } from '../../helpers/contextTranslateKeys';

const withTranslate = (
  key: string,
  options: Record<string, any>,
  i18nProvider: I18nProvider
): string => {
  const selectedKeysFns = contextTranslateKeys[key];

  // If there are multiple functions, apply each one and merge the results
  if (Array.isArray(selectedKeysFns)) {
    const translatedKeys = selectedKeysFns.reduce((acc, fn) => {
      const keys = fn(i18nProvider.translate);

      return { ...acc, ...keys };
    }, {});

    return i18nProvider.translate(key, { ...options, ...translatedKeys });
  }

  // If there's a single function, apply it
  if (typeof selectedKeysFns === 'function') {
    const translatedKeys = selectedKeysFns(i18nProvider.translate);

    return i18nProvider.translate(key, { ...options, ...translatedKeys });
  }

  return i18nProvider.translate(key, options);
};

export default withTranslate;
