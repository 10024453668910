import React, { FunctionComponent } from 'react';
import { regex as raRegex, TextInput as RaTextInput } from 'react-admin';
import { UrlInputProps } from './types';

const UrlInput: FunctionComponent<UrlInputProps> = (props: UrlInputProps) => {
  const { source, ...rest } = props;

  const validateUrl = raRegex(
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+/,
    'resource.validators.url.message'
  );

  return (
    <RaTextInput
      source={source}
      type="url"
      validate={[validateUrl]}
      {...rest}
    />
  );
};

export default UrlInput;
