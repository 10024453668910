import { Resource as ResourceType } from '../types';

export const user: ResourceType = {
  name: 'users',
  label: 'resource.users.label',
  hideResource: true,
  attributes: [
    {
      key: 'id',
      label: 'resource.users.field.id.label',
      type: 'text',
    },
    {
      key: 'first_name',
      label: 'resource.users.field.first_name.label',
      type: 'text',
    },
    {
      key: 'last_name',
      label: 'resource.users.field.last_name.label',
      type: 'text',
    },
    {
      key: 'full_name',
      label: 'resource.users.field.full_name.label',
      type: 'text',
    },
    {
      key: 'email',
      label: 'resource.users.field.email.label',
      type: 'email',
    },
    {
      key: 'company_id',
      label: 'resource.users.field.company_id.label',
      type: 'reference',
      reference: 'companies',
    },
    {
      key: 'phone',
      label: 'resource.users.field.phone.label',
      type: 'phone',
    },
    {
      key: 'linkedin_vanity_name',
      label: 'resource.users.field.linkedin_vanity_name.label',
      type: 'url',
    },
    {
      key: 'kind',
      label: 'resource.users.field.kind.label',
      type: 'select',
      choices: [
        {
          id: 'client',
          name: 'resource.users.field.kind.choices.client.label',
        },
        {
          id: 'expert',
          name: 'resource.users.field.kind.choices.expert.label',
        },
      ],
    },
    {
      key: 'sign_up_company_name',
      label: 'resource.users.field.sign_up_company_name.label',
      type: 'text',
    },
    {
      key: 'company_id',
      label: 'resource.users.field.company_id.label',
      type: 'company',
      reference: 'companies',
    },
    {
      key: 'blocked',
      label: 'resource.users.field.blocked.label',
      type: 'boolean',
    },
    {
      key: 'administrator',
      label: 'resource.users.field.administrator.label',
      type: 'boolean',
    },
    {
      key: 'created_at',
      label: 'resource.users.field.created_at.label',
      type: 'date',
    },
    {
      key: 'updated_at',
      label: 'resource.users.field.updated_at.label',
      type: 'date',
    },
    {
      key: 'accepted_latest_cgu',
      label: 'resource.users.field.accepted_latest_cgu.label',
      type: 'boolean',
    },
    {
      key: 'cgu_accepted_at',
      label: 'resource.users.field.cgu_accepted_at.label',
      type: 'date',
    },
    {
      key: 'anonymized',
      label: 'resource.users.field.anonymized.label',
      type: 'boolean',
    },
    {
      key: 'job_name',
      label: 'resource.users.field.job_name.label',
      type: 'text',
    },
    {
      key: 'filled',
      label: 'resource.users.field.filled.label',
      type: 'boolean',
    },
    {
      key: 'language_alpha2',
      label: 'resource.users.field.language_alpha2.label',
      type: 'select',
      choices: [
        {
          id: 'fr',
          name: 'resource.users.field.language_alpha2.fr',
        },
        {
          id: 'en',
          name: 'resource.users.field.language_alpha2.en',
        },
      ],
    },
    {
      key: 'avatar_url',
      label: 'resource.users.field.avatar_url.label',
      type: 'image',
    },
    {
      key: 'last_signin_at',
      label: 'resource.users.field.last_signin_at.label',
      type: 'date',
    },
    {
      key: 'reset_password_token_sent_at',
      label: 'resource.users.field.reset_password_token_sent_at.label',
      type: 'dateTime',
    },
    {
      key: 'confirmation_token_sent_at',
      label: 'resource.users.field.confirmation_token_sent_at.label',
      type: 'date',
    },
    {
      key: 'confirmed',
      label: 'resource.users.field.confirmed.label',
      type: 'boolean',
    },
    {
      key: 'is_registered',
      label: 'resource.users.field.is_registered.label',
      type: 'boolean',
    },
    {
      key: 'relevance_current_step_name',
      label: 'resource.users.field.relevance_current_score.label',
      type: 'text',
    },
    {
      key: 'reset_password_link',
      label: 'resource.users.field.reset_password_link.label',
      type: 'frontOfficeLink',
      externalLink: {
        path: 'reset-password',
        params: [
          {
            source: 'reset_password_token',
            name: 'reset_token',
          },
        ],
        options: {
          copy: true,
        },
      },
    },
    {
      key: 'is_reset_password_token_expired',
      label: 'resource.users.field.reset_password_link.label',
      type: 'buttons',
    },
    {
      key: 'fo_link',
      label: 'resource.proposals.field.fo_link.label',
      type: 'frontOfficeLink',
      externalLink: {
        source: 'id',
        path: 'experts',
      },
    },
    {
      key: 'company_cv_download_url',
      label: 'resource.companies.field.cv_download_url.label',
      type: 'file',
    },
    {
      key: 'company_solo',
      label: 'resource.companies.field.solo?.label',
      type: 'boolean',
    },
    {
      key: 'company_kind',
      label: 'resource.companies.field.kind.label',
      type: 'select',
      choices: [
        {
          id: 'unknown',
          name: 'resource.companies.field.kind.choices.unknown.label',
        },
        {
          id: 'esn',
          name: 'resource.companies.field.kind.choices.esn.label',
        },
        {
          id: 'recruitment_candidate',
          name: 'resource.companies.field.kind.choices.recruitment_candidate.label',
        },
        {
          id: 'freelance',
          name: 'resource.companies.field.kind.choices.freelance.label',
        },
        {
          id: 'design_office',
          name: 'resource.companies.field.kind.choices.design_office.label',
        },
        {
          id: 'agency',
          name: 'resource.companies.field.kind.choices.agency.label',
        },
        {
          id: 'major_company',
          name: 'resource.companies.field.kind.choices.major_company.label',
        },
        {
          id: 'startup',
          name: 'resource.companies.field.kind.choices.startup.label',
        },
        {
          id: 'consulting',
          name: 'resource.companies.field.kind.choices.consulting.label',
        },
        {
          id: 'smes',
          name: 'resource.companies.field.kind.choices.smes.label',
        },
      ],
    },
    {
      key: 'authorization',
      type: 'object',
      shape: [
        {
          key: 'mission_validation',
          label: 'resource.companies.field.mission_validation.label',
          type: 'boolean',
        },
        {
          key: 'candidacy_validation',
          label: 'resource.companies.field.candidacy_validation.label',
          type: 'boolean',
        },
        {
          key: 'search_access',
          label: 'resource.companies.field.search_access.label',
          type: 'boolean',
        },
        {
          key: 'additional_information_access',
          label: 'resource.companies.field.additional_information_access.label',
          type: 'boolean',
        },
        {
          key: 'margin',
          label: 'resource.companies.field.margin.label',
          type: 'number',
        },
        {
          key: 'mission_matching_access',
          label: 'resource.companies.field.mission_matching_access.label',
          type: 'boolean',
        },
        {
          key: 'company_matching_access',
          label: 'resource.companies.field.access_matched_companies.label',
          type: 'boolean',
        },
        {
          key: 'invitations_access',
          label: 'resource.companies.field.invitations_access.label',
          type: 'boolean',
        },
      ],
    },
    {
      key: 'company',
      type: 'object',
      shape: [
        {
          key: 'id',
          label: 'resource.companies.field.company_id.label',
          type: 'text',
        },
        {
          key: 'logo_url',
          label: 'resource.companies.field.logo_url.label',
          type: 'image',
        },
        {
          key: 'about',
          label: 'resource.companies.field.about.label',
          type: 'wysiwyg',
          multiline: true,
        },
        {
          key: 'join_us',
          label: 'resource.companies.field.join_us.label',
          type: 'wysiwyg',
          multiline: true,
        },
        {
          key: 'interviews_conduct',
          label: 'resource.companies.field.interviews_conduct.label',
          type: 'wysiwyg',
          multiline: true,
        },
        {
          key: 'company_value_skills',
          label: 'resource.companies.field.company_value_skills.label',
          type: 'array',
          itemSource: 'name',
        },
        {
          key: 'employees_number',
          label: 'resource.companies.field.employees_number.label',
          type: 'number',
        },
        {
          key: 'exp_time',
          label: 'resource.companies.field.exp_time.label',
          type: 'number',
        },
        {
          key: 'skills_name',
          label: 'resource.companies.field.skills_name.label',
          type: 'array',
          itemSource: 'name',
        },
        {
          key: 'subcontractors_number',
          label: 'resource.companies.field.subcontractors_number.label',
          type: 'number',
        },
        {
          key: 'address',
          label: 'resource.companies.field.location.address.label',
          type: 'text',
        },
        {
          key: 'personal_skills_name',
          label: 'resource.companies.field.personal_skills_name.label',
          type: 'array',
          itemSource: 'name',
        },
        {
          key: 'sectors',
          label: 'resource.companies.field.sectors.label',
          type: 'multipleSelect',
          choices: [
            {
              id: 'automative',
              name: 'resource.companies.field.sectors.choices.automative.label',
            },
            {
              id: 'aeronautics',
              name: 'resource.companies.field.sectors.choices.aeronautics.label',
            },
            {
              id: 'space',
              name: 'resource.companies.field.sectors.choices.space.label',
            },
            {
              id: 'defense',
              name: 'resource.companies.field.sectors.choices.defense.label',
            },
            {
              id: 'railway',
              name: 'resource.companies.field.sectors.choices.railway.label',
            },
            {
              id: 'telecoms',
              name: 'resource.companies.field.sectors.choices.telecoms.label',
            },
            {
              id: 'energy',
              name: 'resource.companies.field.sectors.choices.energy.label',
            },
            {
              id: 'services_and_information_systems',
              name: 'resource.companies.field.sectors.choices.services_and_information_systems.label',
            },
            {
              id: 'oil_and_gas',
              name: 'resource.companies.field.sectors.choices.oil_and_gas.label',
            },
            {
              id: 'naval',
              name: 'resource.companies.field.sectors.choices.naval.label',
            },
            {
              id: 'machining_or_heavy_industry',
              name: 'resource.companies.field.sectors.choices.machining_or_heavy_industry.label',
            },
            {
              id: 'bioengineering',
              name: 'resource.companies.field.sectors.choices.bioengineering.label',
            },
            {
              id: 'bank_insurance',
              name: 'resource.companies.field.sectors.choices.bank_insurance.label',
            },
            {
              id: 'infrastructure',
              name: 'resource.companies.field.sectors.choices.infrastructure.label',
            },
            {
              id: 'digital',
              name: 'resource.companies.field.sectors.choices.digital.label',
            },
          ],
        },
        {
          key: 'availability',
          label: 'resource.companies.field.availability.label',
          type: 'select',
          choices: [
            {
              id: 'asap',
              name: 'resource.companies.field.availability.choices.asap.label',
            },
            {
              id: 'precise',
              name: 'resource.companies.field.availability.choices.precise.label',
            },
            {
              id: 'one_month',
              name: 'resource.companies.field.availability.choices.one_month.label',
            },
            {
              id: 'three_months',
              name: 'resource.companies.field.availability.choices.three_months.label',
            },
          ],
        },
        {
          key: 'available_at',
          label: 'resource.companies.field.available_at.label',
          type: 'date',
        },
        {
          key: 'search_status',
          label: 'resource.companies.field.search_status.label',
          type: 'select',
          choices: [
            {
              id: 'active',
              name: 'resource.companies.field.search_status.choices.active.label',
            },
            {
              id: 'open',
              name: 'resource.companies.field.search_status.choices.open.label',
            },
            {
              id: 'unavailable',
              name: 'resource.companies.field.search_status.choices.unavailable.label',
            },
          ],
        },
        {
          key: 'modalities',
          label: 'resource.companies.field.modalities.label',
          type: 'select',
          choices: [
            {
              id: 'remote',
              name: 'resource.companies.field.modalities.choices.remote.label',
            },
            {
              id: 'remote_can_move',
              name: 'resource.companies.field.modalities.choices.remote_can_move.label',
            },
            {
              id: 'on_site',
              name: 'resource.companies.field.modalities.choices.on_site.label',
            },
          ],
        },
        {
          key: 'on_site_locations',
          label: 'resource.companies.field.on_site_locations.label',
          type: 'array',
          itemSource: 'address',
        },
        {
          key: 'cv_download_url',
          label: 'resource.companies.field.cv_download_url.label',
          type: 'file',
        },
        {
          key: 'name',
          label: 'resource.companies.field.name.label',
          type: 'text',
        },
        {
          key: 'name_choice',
          label: 'resource.companies.field.name_choice.label',
          type: 'select',
          choices: [
            {
              id: 'same_as_user_name',
              name: 'resource.companies.field.name_choice.choices.same_as_user_name.label',
            },
            {
              id: 'different_from_user_name',
              name: 'resource.companies.field.name_choice.choices.different_from_user_name.label',
            },
            {
              id: 'portage',
              name: 'resource.companies.field.name_choice.choices.portage.label',
            },
            {
              id: 'none',
              name: 'resource.companies.field.name_choice.choices.none.label',
            },
          ],
        },
        {
          key: 'size',
          label: 'resource.companies.field.size.label',
          type: 'select',
          choices: [
            {
              id: 'tiny',
              name: 'resource.companies.field.size.choices.tiny.label',
            },
            {
              id: 'small',
              name: 'resource.companies.field.size.choices.small.label',
            },
            {
              id: 'medium',
              name: 'resource.companies.field.size.choices.medium.label',
            },
            {
              id: 'big',
              name: 'resource.companies.field.size.choices.big.label',
            },
            {
              id: 'large',
              name: 'resource.companies.field.size.choices.large.label',
            },
          ],
        },
        {
          key: 'sub_categories_id',
          label: 'resource.companies.field.sub_categories_name.label',
          type: 'referenceArray',
          reference: 'sub_categories',
        },
        {
          key: 'contract_types',
          label: 'resource.companies.field.contract_types.label',
          type: 'select',
          choices: [
            {
              id: 'fulltime_contract',
              name: 'resource.companies.field.contract_types.choices.fulltime_contract.label',
            },
            {
              id: 'internship',
              name: 'resource.companies.field.contract_types.choices.internship.label',
            },
            {
              id: 'missions',
              name: 'resource.companies.field.contract_types.choices.missions.label',
            },
            {
              id: 'missions_or_fulltime_contract',
              name: 'resource.companies.field.contract_types.choices.missions_or_fulltime_contract.label',
            },
          ],
        },
        {
          key: 'billings',
          label: 'resource.companies.field.billings.label',
          type: 'multipleSelect',
          choices: [
            {
              id: 'technical_assistance',
              name: 'resource.companies.field.billings.choices.technical_assistance.label',
            },
            {
              id: 'forfeit',
              name: 'resource.companies.field.billings.choices.forfeit.label',
            },
          ],
        },
        {
          key: 'work_days',
          label: 'resource.companies.field.work_days.label',
          type: 'number',
        },
        {
          key: 'daily_rate',
          label: 'resource.companies.field.daily_rate.label',
          type: 'range',
        },
        {
          key: 'salary',
          label: 'resource.companies.field.salary.label',
          type: 'range',
        },
        {
          key: 'salary_confidentiality',
          label: 'resource.companies.field.salary_confidentiality.label',
          type: 'boolean',
        },
        {
          key: 'created_at',
          label: 'resource.companies.field.created_at.label',
          type: 'date',
        },
        {
          key: 'updated_at',
          label: 'resource.companies.field.updated_at.label',
          type: 'date',
        },
        {
          key: 'availability_manually_updated_at',
          label:
            'resource.companies.field.availability_manually_updated_at.label',
          type: 'date',
        },
        {
          key: 'legal_information_id',
          type: 'reference',
          reference: 'legal_informations',
        },
        {
          key: 'company_recommendation_id',
          type: 'reference',
          reference: 'recommendations',
        },
        {
          key: 'cv_aden_synchro',
          label: 'resource.companies.field.cv_aden_synchro.label',
          type: 'boolean',
        },
        {
          key: 'cv_aden_id',
          label: 'resource.companies.field.cv_aden_id.label',
          type: 'text',
        },
      ],
    },
    {
      key: 'company_name',
      label: 'resource.companies.field.company_name.label',
      type: 'text',
    },
  ],
  pages: {
    list: {
      hasCreateButton: true,
      hasEditButton: true,
      sort: {
        field: 'created_at',
        order: 'DESC',
      },
      filterDefaultValues: {
        anonymized: false,
      },
      search: {
        enabled: true,
        label: 'filter.search.label',
      },
      bulkActionButtons: [
        {
          name: 'validate',
          type: 'update',
          label: 'resource.users.bulk_action_buttons.validate.label',
          values: {
            validated: true,
          },
        },
        {
          name: 'unvalidate',
          type: 'update',
          label: 'resource.users.bulk_action_buttons.unvalidate.label',
          values: {
            validated: false,
          },
        },
        {
          name: 'block',
          type: 'update',
          label: 'resource.users.bulk_action_buttons.block.label',
          icon: 'Lock',
          values: {
            blocked: true,
          },
        },
        {
          name: 'unblock',
          type: 'update',
          label: 'resource.users.bulk_action_buttons.unblock.label',
          icon: 'LockOpen',
          values: {
            blocked: false,
          },
        },
        {
          name: 'anonymize',
          type: 'update',
          label: 'resource.users.bulk_action_buttons.anonymize.label',
          values: {},
          extraUrl: '/anonymize',
          icon: 'VisibilityOff',
          // Following settings are only useful for update with confirmation
          withConfirmation: true,
          confirmation: {
            content: 'modal.bulk_anonymize.content',
          },
        },
      ],
      filters: [
        {
          source: 'kind',
          alwaysOn: true,
        },
        {
          source: 'anonymized',
        },
        {
          source: 'blocked',
        },
        {
          source: 'administrator',
        },
        {
          source: 'company_id',
          type: 'reference',
          child: {
            type: 'autocomplete',
            // Options to pass to ReferenceInput
            options: {
              perPage: 10,
              allowEmpty: false,
            },
          },
        },
        {
          source: 'company_solo',
        },
        {
          source: 'company_kind',
        },
      ],
      fields: [
        {
          source: 'first_name',
        },
        {
          source: 'last_name',
        },
        {
          source: 'email',
        },
        {
          source: 'phone',
        },
        {
          source: 'job_name',
        },
        {
          source: 'linkedin_vanity_name',
          icon: 'LinkedIn',
        },
        {
          source: 'fo_link',
          sortable: false,
          icon: 'AccountCircle',
        },
        {
          source: 'company_id',
          link: false,
          sortable: false,
          label: 'resource.companies.field.competencies_file.label',
          key: 'download',
          type: 'buttons',
          extensions: [
            {
              key: 'competenciesFiles',
              params: {
                formats: ['pdf', 'docx'],
                resourceToFetch: 'companies',
              },
            },
          ],
        },
        {
          source: 'company_cv_download_url',
          title: 'cv',
          sortable: false,
        },
        {
          source: 'relevance_current_step_name',
        },
        {
          source: 'blocked',
        },
        {
          source: 'filled',
          sortable: false,
        },
        {
          source: 'is_registered',
        },
        {
          source: 'cgu_accepted_at',
        },
        {
          source: 'created_at',
        },
      ],
    },
    edit: {
      singleRedirect: {
        page: 'edit',
        basePath: '/users',
        recordId: 'id',
      },
      actions: [
        {
          name: 'reset_password',
          type: 'update',
          label: 'resource.users.bulk_action_buttons.reset_password.label',
          values: {},
          extraUrl: '/send_reset_password_email',
          icon: undefined,
        },
        {
          name: 'sign_in_as',
          type: 'update',
          label: 'resource.users.bulk_action_buttons.sign_in_as.label',
          values: {},
          extraUrl: '/sign_in_as',
          extensions: [{ key: 'sign_in_as' }],
          icon: undefined,
        },
        {
          name: 'anonymize',
          type: 'update',
          label: 'resource.users.bulk_action_buttons.anonymize.label',
          values: {},
          extraUrl: '/anonymize',
          withConfirmation: true,
          confirmation: {
            content: 'modal.anonymize.content',
          },
          icon: 'VisibilityOff',
          disabled: [{ property: 'anonymized', op: 'eq', value: true }],
        },
        {
          name: 'redirect_to_candidacy_creation',
          type: 'redirect',
          label: 'resource.users.bulk_action_buttons.create_a_candidacy.label',
          extraUrl: '/candidacies/create',
          hiddenInput: [
            {
              key: 'owner_id',
              value: 'id',
            },
          ],
          disabled: [{ property: 'kind', op: 'eq', value: 'client' }],
        },
      ],
      left: {
        tabs: [
          {
            key: 'information',
            title: 'resource.users.tab.information.title',
            sections: [
              {
                key: 'entity',
                title: 'resource.users.section.profile.title',
                layout: [
                  ['first_name', 'last_name'],
                  ['email', 'phone'],
                  ['kind', 'language_alpha2'],
                  ['job_name'],
                  ['linkedin_vanity_name'],
                ],
                inputs: {
                  first_name: {
                    width: 6,
                    validators: [{ key: 'required' }],
                  },
                  last_name: {
                    width: 6,
                    validators: [{ key: 'required' }],
                  },
                  email: {
                    width: 6,
                    validators: [{ key: 'email' }, { key: 'required' }],
                  },
                  phone: {
                    width: 6,
                  },
                  kind: {
                    validators: [{ key: 'required' }],
                    width: 6,
                  },
                  language_alpha2: {
                    width: 6,
                  },
                  job_name: {
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'eq',
                          value: 'client',
                        },
                      ],
                    },
                  },
                  sign_up_company_name: {},
                  linkedin_vanity_name: {
                    validators: [{ key: 'linkedinUrl' }],
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'eq',
                          value: 'client',
                        },
                      ],
                    },
                  },
                },
              },
              {
                key: 'administration',
                title: 'resource.users.section.administration.title',
                layout: [
                  ['blocked', 'administrator'],
                  ['is_registered', 'authorization.search_access'],
                  [
                    'authorization.mission_matching_access',
                    'authorization.invitations_access',
                  ],
                  ['company.cv_aden_synchro', 'company.cv_aden_id'],
                ],
                inputs: {
                  blocked: {
                    width: 6,
                  },
                  administrator: {
                    width: 6,
                  },
                  is_registered: {
                    width: 6,
                    disabled: true,
                  },
                  'authorization.search_access': {
                    width: 6,
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'eq',
                          value: 'client',
                        },
                      ],
                    },
                  },
                  'authorization.mission_matching_access': {
                    width: 6,
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'eq',
                          value: 'client',
                        },
                      ],
                    },
                  },
                  'authorization.invitations_access': {
                    width: 6,
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'eq',
                          value: 'client',
                        },
                      ],
                      disabled: [
                        {
                          property: 'authorization.mission_matching_access',
                          op: 'eq',
                          value: true,
                        },
                      ],
                    },
                  },
                  'company.cv_aden_synchro': {
                    width: 6,
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'eq',
                          value: 'client',
                        },
                      ],
                    },
                  },
                  'company.cv_aden_id': {
                    width: 6,
                    disabled: true,
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'eq',
                          value: 'client',
                        },
                      ],
                    },
                  },
                },
              },
              {
                key: 'password',
                title: 'resource.users.section.password.title',
                layout: [
                  ['reset_password_token_sent_at'],
                  ['is_reset_password_token_expired', 'reset_password_link'],
                  ['confirmation_token_sent_at', 'confirmed'],
                ],
                inputs: {
                  reset_password_token_sent_at: {
                    disabled: true,
                    rules: {
                      hide: [
                        {
                          property: 'reset_password_token_sent_at',
                          op: 'eq',
                          value: null,
                        },
                      ],
                    },
                  },
                  confirmation_token_sent_at: {
                    disabled: true,
                    width: 6,
                  },
                  confirmed: {
                    disabled: true,
                    width: 6,
                  },
                },
                fields: {
                  reset_password_link: {
                    addLabel: true,
                    rules: {
                      hide: [
                        {
                          property: 'reset_password_token',
                          op: 'eq',
                          value: null,
                        },
                        {
                          property: 'is_reset_password_token_expired',
                          op: 'eq',
                          value: true,
                        },
                      ],
                    },
                  },
                  is_reset_password_token_expired: {
                    addLabel: true,
                    width: 6,
                    rules: {
                      hide: [
                        {
                          property: 'is_reset_password_token_expired',
                          op: 'eq',
                          value: false,
                        },
                      ],
                    },
                    extensions: [
                      {
                        key: 'buttonLink',
                        params: {
                          label: 'renew.reset_password_link.label',
                          resourceToFecth: 'users',
                          link: 'send_reset_password_email',
                          args: {
                            send_email: false,
                          },
                        },
                      },
                    ],
                  },
                },
              },
            ],
          },
          {
            key: 'information',
            title: 'resource.users.field.company_id.label',
            sections: [
              {
                key: 'entity',
                title: 'resource.companies.section.profile.title',
                layout: [
                  ['company.name', 'company.name_choice'],
                  [
                    'company_kind_expert',
                    'company_kind_client',
                    'company.size',
                  ],
                  ['company.logo_url', 'company.about'],
                  ['company.employees_number', 'company.subcontractors_number'],
                  ['company.join_us', 'company.interviews_conduct'],
                  ['company.company_value_skills'],
                ],
                inputs: {
                  'company.name': {
                    rules: {
                      disabled: [
                        {
                          property: 'company.name_choice',
                          op: 'includes',
                          value: ['same_as_user_name', 'portage', 'none'],
                        },
                        {
                          property: 'company.contract_types',
                          op: 'includes',
                          value: ['fulltime_contract', 'internship'],
                        },
                      ],
                    },
                    width: 6,
                    validators: [
                      {
                        key: 'required',
                      },
                    ],
                  },
                  'company.employees_number': {
                    disabled: true,
                    width: 6,
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'eq',
                          value: 'client',
                        },
                        {
                          property: 'company_solo',
                          op: 'eq',
                          value: 'true',
                        },
                      ],
                    },
                  },
                  'company.subcontractors_number': {
                    disabled: true,
                    width: 6,
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'eq',
                          value: 'client',
                        },
                        {
                          property: 'company_solo',
                          op: 'eq',
                          value: 'true',
                        },
                      ],
                    },
                  },
                  'company.name_choice': {
                    rules: {
                      hide: [
                        {
                          property: 'company_solo',
                          op: 'eq',
                          value: 'false',
                        },
                      ],
                    },
                    width: 6,
                  },
                  'company.about': {
                    width: 6,
                    validators: [
                      {
                        key: 'maxLength',
                        value: 10000,
                      },
                    ],
                  },
                  company_kind_client: {
                    source: 'company_kind',
                    choices: [
                      {
                        id: 'unknown',
                        name: 'resource.companies.field.kind.choices.unknown.label',
                      },
                      {
                        id: 'esn',
                        name: 'resource.companies.field.kind.choices.esn.label',
                      },
                      {
                        id: 'design_office',
                        name: 'resource.companies.field.kind.choices.design_office.label',
                      },
                      {
                        id: 'agency',
                        name: 'resource.companies.field.kind.choices.agency.label',
                      },
                      {
                        id: 'major_company',
                        name: 'resource.companies.field.kind.choices.major_company.label',
                      },
                      {
                        id: 'startup',
                        name: 'resource.companies.field.kind.choices.startup.label',
                      },
                      {
                        id: 'consulting',
                        name: 'resource.companies.field.kind.choices.consulting.label',
                      },
                      {
                        id: 'smes',
                        name: 'resource.companies.field.kind.choices.smes.label',
                      },
                    ],
                    width: 12,
                    rules: {
                      disabled: [
                        {
                          property: 'company.contract_types',
                          op: 'includes',
                          value: [
                            'fulltime_contract',
                            'internship',
                            'missions_or_fulltime_contract',
                          ],
                        },
                      ],
                      hide: [
                        {
                          property: 'kind',
                          op: 'eq',
                          value: 'expert',
                        },
                      ],
                    },
                    validators: [
                      {
                        key: 'required',
                      },
                    ],
                  },
                  company_kind_expert: {
                    source: 'company_kind',
                    choices: [
                      {
                        id: 'unknown',
                        name: 'resource.companies.field.kind.choices.unknown.label',
                      },
                      {
                        id: 'esn',
                        name: 'resource.companies.field.kind.choices.esn.label',
                      },
                      {
                        id: 'recruitment_candidate',
                        name: 'resource.companies.field.kind.choices.recruitment_candidate.label',
                      },
                      {
                        id: 'freelance',
                        name: 'resource.companies.field.kind.choices.freelance.label',
                      },
                      {
                        id: 'design_office',
                        name: 'resource.companies.field.kind.choices.design_office.label',
                      },
                      {
                        id: 'agency',
                        name: 'resource.companies.field.kind.choices.agency.label',
                      },
                      {
                        id: 'major_company',
                        name: 'resource.companies.field.kind.choices.major_company.label',
                      },
                      {
                        id: 'startup',
                        name: 'resource.companies.field.kind.choices.startup.label',
                      },
                      {
                        id: 'consulting',
                        name: 'resource.companies.field.kind.choices.consulting.label',
                      },
                      {
                        id: 'smes',
                        name: 'resource.companies.field.kind.choices.smes.label',
                      },
                    ],
                    width: 12,
                    rules: {
                      disabled: [
                        {
                          property: 'company.contract_types',
                          op: 'includes',
                          value: [
                            'fulltime_contract',
                            'internship',
                            'missions_or_fulltime_contract',
                          ],
                        },
                      ],
                      hide: [
                        {
                          property: 'kind',
                          op: 'eq',
                          value: 'client',
                        },
                      ],
                    },
                    validators: [
                      {
                        key: 'required',
                      },
                    ],
                  },
                  'company.size': {
                    width: 12,
                    disabled: true,
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'eq',
                          value: 'expert',
                        },
                      ],
                    },
                  },
                  'company.join_us': {
                    width: 12,
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'eq',
                          value: 'expert',
                        },
                      ],
                    },
                  },
                  'company.interviews_conduct': {
                    width: 12,
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'eq',
                          value: 'expert',
                        },
                      ],
                    },
                  },
                },
                fields: {
                  'company.logo_url': {
                    addLabel: true,
                    width: 6,
                  },
                  'company.company_value_skills': {
                    addLabel: true,
                    link: false,
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'eq',
                          value: 'expert',
                        },
                      ],
                    },
                  },
                },
              },
              {
                key: 'skills',
                title: 'resource.companies.section.skills.title',
                layout: [
                  ['job_name', 'company.exp_time'],
                  ['company.skills_name'],
                  ['company.personal_skills_name'],
                  ['company.sectors'],
                  ['company.sub_categories_id'],
                ],
                inputs: {
                  job_name: {
                    width: 6,
                    disabled: true,
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'eq',
                          value: 'client',
                        },
                      ],
                    },
                  },
                  'company.exp_time': {
                    width: 6,
                    disabled: true,
                    rules: {
                      hide: [
                        {
                          property: 'solo?',
                          op: 'eq',
                          value: false,
                        },
                        {
                          property: 'kind',
                          op: 'eq',
                          value: 'client',
                        },
                      ],
                    },
                  },
                  'company.sectors': { disabled: true },
                  'company.sub_categories_id': {
                    child: {
                      type: 'autocompleteArray',
                      optionText: 'sub_categories_name_phrase',
                      options: {
                        perPage: 145,
                        allowEmpty: false,
                      },
                      extensions: [
                        {
                          key: 'sort',
                          params: {
                            type: 'alphabetic',
                            mode: 'asc',
                          },
                        },
                      ],
                    },
                  },
                },
                fields: {
                  'company.personal_skills_name': {
                    addLabel: true,
                    link: false,
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'eq',
                          value: 'client',
                        },
                      ],
                    },
                  },
                  'company.skills_name': {
                    addLabel: true,
                    link: false,
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'eq',
                          value: 'client',
                        },
                      ],
                    },
                  },
                },
              },
              {
                key: 'modalities',
                title: 'resource.companies.section.modalities.title',
                rules: {
                  hide: [
                    {
                      property: 'kind',
                      op: 'eq',
                      value: 'client',
                    },
                  ],
                },
                layout: [
                  ['company.contract_types', 'company.search_status'],
                  ['company.availability', 'company.available_at'],
                  ['company.billings', 'company.work_days'],
                  ['company.address', 'company.modalities'],
                  ['company.on_site_locations'],
                  ['company.daily_rate', 'company.salary'],
                  ['company.salary_confidentiality'],
                ],
                inputs: {
                  'company.contract_types': {
                    width: 6,
                    disabled: true,
                  },
                  'company.availability': {
                    width: 6,
                    disabled: true,
                    rules: {
                      hide: [
                        {
                          property: 'company.search_status',
                          op: 'eq',
                          value: 'unavailable',
                        },
                      ],
                    },
                  },
                  'company.available_at': {
                    width: 6,
                    disabled: true,
                    rules: {
                      hide: [
                        {
                          property: 'company.search_status',
                          op: 'eq',
                          value: 'unavailable',
                        },
                      ],
                    },
                  },
                  'company.search_status': {
                    width: 6,
                    disabled: true,
                  },
                  'company.billings': {
                    width: 6,
                    disabled: true,
                  },
                  'company.modalities': {
                    width: 6,
                    disabled: true,
                  },
                  'company.address': {
                    width: 6,
                    disabled: true,
                  },
                  'company.work_days': {
                    width: 6,
                    disabled: true,
                  },
                  'company.daily_rate': {
                    width: 6,
                    disabled: true,
                    options: {
                      min: 150,
                      max: 2000,
                      step: 10,
                    },
                  },
                  'company.salary': {
                    width: 6,
                    disabled: true,
                    options: {
                      min: 20000,
                      max: 150000,
                      step: 1000,
                    },
                  },
                  'company.salary_confidentiality': { disabled: true },
                },
                fields: {
                  'company.on_site_locations': {
                    addLabel: true,
                    link: false,
                  },
                },
              },
              {
                key: 'client_administration',
                title: 'resource.companies.section.client_administration.title',
                layout: [
                  [
                    'authorization.mission_validation',
                    'authorization.candidacy_validation',
                    'authorization.margin',
                  ],
                  [
                    'authorization.search_access',
                    'authorization.additional_information_access',
                  ],
                  ['authorization.company_matching_access'],
                ],
                rules: {
                  hide: [
                    {
                      property: 'kind',
                      op: 'eq',
                      value: 'expert',
                    },
                  ],
                },
                inputs: {
                  'authorization.mission_validation': {
                    width: 4,
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'eq',
                          value: 'expert',
                        },
                      ],
                    },
                  },
                  'authorization.candidacy_validation': {
                    width: 4,
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'eq',
                          value: 'expert',
                        },
                      ],
                    },
                  },
                  'authorization.margin': {
                    width: 4,
                    extensions: [
                      {
                        key: 'multiplyByHundredInput',
                      },
                    ],
                    validators: [
                      {
                        key: 'required',
                      },
                      {
                        key: 'minValue',
                        value: 0,
                      },
                      {
                        key: 'maxValue',
                        value: 100,
                      },
                    ],
                    min: 0,
                    max: 100,
                    step: 0.01,
                    rules: {
                      hide: [
                        {
                          property: 'authorization.candidacy_validation',
                          op: 'eq',
                          value: true,
                        },
                      ],
                    },
                  },
                  'authorization.search_access': {
                    width: 6,
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'eq',
                          value: 'expert',
                        },
                      ],
                    },
                  },
                  'authorization.additional_information_access': {
                    width: 6,
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'eq',
                          value: 'expert',
                        },
                      ],
                    },
                  },
                  'authorization.company_matching_access': {
                    width: 6,
                  },
                },
              },
            ],
          },
          {
            key: 'matches',
            title: 'resource.companies.tab.matches.title',
            rules: {
              hide: [
                {
                  property: 'company_id',
                  op: 'eq',
                  value: null,
                },
                {
                  property: 'kind',
                  op: 'eq',
                  value: 'client',
                },
              ],
            },
            sections: [
              {
                key: 'matches',
                title: 'resource.companies.section.matches.title',
                layout: [],
                inputs: {},
                list: {
                  resource_to_fetch: 'matches',
                  hasCreateButton: false,
                  filter_resource: 'company',
                  filter_attribute: 'company_id',
                  fields: [
                    {
                      source: 'id',
                      sortable: false,
                      label: 'resource.matches.field.mission.name.label',
                      child: {
                        source: 'missions.name',
                      },
                    },
                    {
                      source: 'owner_id',
                      sortable: false,
                      label: 'resource.matches.field.owner_id.label',
                      child: {
                        source: 'users.full_name',
                      },
                    },
                    {
                      source: 'percent',
                      label: 'resource.matches.field.percent.label',
                      type: 'text',
                    },
                  ],
                },
              },
            ],
          },
          {
            key: 'documents',
            title: 'resource.companies.tab.documents.title',
            rules: {
              hide: [
                {
                  property: 'company_id',
                  op: 'eq',
                  value: null,
                },
                {
                  property: 'kind',
                  op: 'eq',
                  value: 'client',
                },
              ],
            },
            sections: [
              {
                key: 'documents',
                title: 'resource.companies.section.documents.title',
                layout: [],
                inputs: {},
                list: {
                  resource_to_fetch: 'documents_companies',
                  filter_resource: 'company',
                  filter_attribute: 'company_id',
                  fields: [
                    {
                      source: 'document_name',
                      sortable: false,
                      label: 'resource.documents.field.name.label',
                      type: 'text',
                    },
                    {
                      source: 'document_kind_name',
                      sortable: false,
                      label: 'resource.documents.field.kind_name.label',
                      type: 'text',
                    },
                    {
                      source: 'document_condition',
                      sortable: false,
                      label: 'resource.documents.field.condition.option.label',
                      translationPrefix:
                        'resource.documents.field.condition.choices',
                      type: 'select',
                    },
                    {
                      source: 'status',
                      sortable: false,
                      label: 'resource.documents.field.status.label',
                      type: 'select',
                    },
                  ],
                  expand: {
                    type: 'document_file',
                  },
                },
              },
            ],
          },
        ],
      },
      right: [
        {
          sections: [
            {
              key: 'details',
              title: 'resource.users.section.details.title',
              layout: [
                ['id', 'company.id'],
                ['created_at', 'company.created_at'],
                ['updated_at', 'company.updated_at'],
                ['last_signin_at', 'company.availability_manually_updated_at'],
                ['kind'],
              ],
              inputs: {
                id: {
                  disabled: true,
                  width: 6,
                  label: 'resource.users.field.user_id.label',
                },
                'company.id': {
                  disabled: true,
                  width: 6,
                },
                created_at: {
                  disabled: true,
                  width: 6,
                },
                'company.created_at': {
                  disabled: true,
                  width: 6,
                },
                updated_at: {
                  disabled: true,
                  width: 6,
                },
                'company.updated_at': {
                  disabled: true,
                  width: 6,
                },
                last_signin_at: {
                  disabled: true,
                  width: 6,
                },
                'company.availability_manually_updated_at': {
                  disabled: true,
                  width: 6,
                  rules: {
                    hide: [
                      {
                        property: 'kind',
                        op: 'eq',
                        value: 'client',
                      },
                    ],
                  },
                },
                kind: {
                  disabled: true,
                  width: 6,
                },
              },
              fields: {
                reset_password_link: {
                  addLabel: true,
                  rules: {
                    hide: [
                      {
                        property: 'reset_password_token',
                        op: 'eq',
                        value: null,
                      },
                    ],
                  },
                },
              },
            },
            {
              key: 'cgu',
              title: 'resource.users.section.cgu.title',
              layout: [['accepted_latest_cgu'], ['cgu_accepted_at']],
              inputs: {
                accepted_latest_cgu: {
                  disabled: true,
                  width: 6,
                },
                cgu_accepted_at: {
                  disabled: true,
                  width: 6,
                },
              },
              fields: {},
            },
            {
              key: 'legal_information',
              title: 'resource.users.section.legal_information.title',
              layout: [
                ['company.legal_information_id'],
                ['company.company_recommendation_id'],
              ],
              inputs: {},
              fields: {
                'company.legal_information_id': {
                  label: 'resource.companies.field.legal_information_id.label',
                  addLabel: true,
                  child: {
                    source: 'legal_informations.id',
                    type: 'text',
                  },
                },
                'company.company_recommendation_id': {
                  label: 'resource.companies.field.recommendation_id.label',
                  addLabel: true,
                  rules: {
                    hide: [
                      {
                        property: 'kind',
                        op: 'eq',
                        value: 'client',
                      },
                    ],
                  },
                  child: {
                    source: 'recommendations.comment',
                    type: 'recommendation',
                  },
                },
              },
            },
            {
              key: 'files',
              title: 'resource.companies.section.files.title',
              rules: {
                hide: [
                  {
                    property: 'kind',
                    op: 'eq',
                    value: 'client',
                  },
                ],
              },
              layout: [['competencies_file'], ['company.cv_download_url']],
              inputs: {},
              fields: {
                competencies_file: {
                  source: 'company_id',
                  link: false,
                  sortable: false,
                  label: 'resource.companies.field.competencies_file.label',
                  key: 'download',
                  type: 'buttons',
                  extensions: [
                    {
                      key: 'competenciesFiles',
                      params: {
                        formats: ['pdf', 'docx'],
                        resourceToFetch: 'companies',
                      },
                    },
                  ],
                },
                'company.cv_download_url': {
                  addLabel: true,
                  title: 'cv',
                },
              },
            },
          ],
        },
        {
          sections: [
            {
              key: 'link',
              title: 'resource.companies.section.link.title',
              layout: [['users_link', 'invitations_link', 'candidacies_link']],
              inputs: {},
              fields: {
                users_link: {
                  source: 'id',
                  type: 'resourceLink',
                  label: 'resource.companies.link.users.label',
                  resource: 'users',
                  filters: [
                    {
                      key: 'company_id',
                      source: 'company_id',
                    },
                  ],
                },
                candidacies_link: {
                  source: 'id',
                  type: 'resourceLink',
                  label: 'resource.companies.link.candidacies.label',
                  resource: 'candidacies',
                  rules: {
                    hide: [
                      {
                        property: 'kind',
                        op: 'eq',
                        value: 'client',
                      },
                    ],
                  },
                  filters: [
                    {
                      key: 'company_id',
                      source: 'company_id',
                    },
                  ],
                },
                invitations_link: {
                  source: 'id',
                  type: 'resourceLink',
                  label: 'resource.companies.link.invitations.label',
                  resource: 'invitations',
                  rules: {
                    hide: [
                      {
                        property: 'kind',
                        op: 'eq',
                        value: 'client',
                      },
                    ],
                  },
                  filters: [
                    {
                      key: 'recipient_id',
                      source: 'company_id',
                    },
                  ],
                },
              },
            },
          ],
        },
        {
          sections: [
            {
              key: 'history',
              title: 'resource.companies.section.history.title',
              layout: [],
              inputs: {},
              history: true,
              filterResource: 'companies',
              filterAttribute: 'company_id',
            },
          ],
        },
      ],
    },
    create: {
      left: {
        tabs: [
          {
            key: 'information',
            title: 'resource.users.tab.information.title',
            sections: [
              {
                key: 'users',
                title: 'resource.users.section.create.title',
                layout: [
                  ['first_name', 'last_name'],
                  ['email', 'phone'],
                  ['kind', 'sign_up_company_name'],
                  ['job_name', 'language_alpha2'],
                  ['linkedin_vanity_name'],
                ],
                inputs: {
                  first_name: {
                    width: 6,
                    validators: [{ key: 'required' }],
                  },
                  last_name: {
                    width: 6,
                    validators: [{ key: 'required' }],
                  },
                  email: {
                    width: 6,
                    validators: [{ key: 'email' }, { key: 'required' }],
                  },
                  phone: {
                    width: 6,
                  },
                  kind: {
                    validators: [{ key: 'required' }],
                    width: 6,
                  },
                  sign_up_company_name: {
                    type: 'text',
                    label:
                      'resource.users.field.sign_up_company_name.create.label',
                    width: 6,
                    validators: [
                      {
                        key: 'required',
                        rules: [
                          {
                            property: 'kind',
                            op: 'includes',
                            value: ['client'],
                          },
                        ],
                      },
                    ],
                  },
                  job_name: {
                    width: 6,
                  },
                  language_alpha2: {
                    width: 6,
                  },
                  linkedin_vanity_name: {
                    width: 6,
                    validators: [{ key: 'linkedinUrl' }],
                  },
                },
              },
              {
                key: 'administration',
                title: 'resource.users.section.administration.create.title',
                layout: [['blocked', 'administrator']],
                inputs: {
                  blocked: {
                    width: 6,
                  },
                  administrator: {
                    width: 6,
                  },
                },
              },
            ],
          },
        ],
      },
    },
  },
};
