/**
 * This method compute and return the number of url parts
 * ex: /missions/:id return 2
 * ex: /missions return 1
 * to make the difference between index and show/edit view
 * @param string url
 * @returns {number}
 */
export const getUrlPartsNumber = (url: string) => {
  // Split the URL into its component parts
  const urlParts = url.split('/');

  // Count and return the number of path segments
  return urlParts.filter((part: any) => part !== '').length;
};

/**
 * This method return the basepath of an url
 * ex: /missions/:id return missions
 * ex: /missions return missions
 * @param string url
 * @returns {string}
 */
export const getBasepath = (url: string) => {
  // Split the URL into its component parts
  const urlParts = url.split('/');

  // Count and return the number of path segments
  return urlParts.filter((part: any) => part !== '')[0];
};
