import React, { FunctionComponent } from 'react';
import { Button as MuiButton, makeStyles } from '@material-ui/core';
import { Notification as RaNotification, useTranslate } from 'react-admin';
import { SsoButtonProps } from './types';
import useConfig from '../../../../../hooks/useConfig';

const useStyles = makeStyles({
  button: {
    color: 'white',
    backgroundColor: (config: Record<string, any>) =>
      config.palette.primary.main,
    '&:hover': {
      backgroundColor: (config: Record<string, any>) =>
        config.palette.info.main,
    },
  },
});

const SsoButton: FunctionComponent<SsoButtonProps> = (props) => {
  const config = useConfig();
  const classes = useStyles(config.theme);
  const { classname } = props;
  const t = useTranslate();
  const handleSsoLogin = () => {
    window.location.replace(
      `https:${config.authenticatorUrl}/login?callbackUrl=${window.location.origin}${window.location.pathname}`
    );

    return null;
  };

  return (
    <div className={classname}>
      <RaNotification type="error" />
      <MuiButton
        type="button"
        variant="contained"
        onClick={handleSsoLogin}
        className={classes.button}
      >
        {t('login.sso')}
      </MuiButton>
    </div>
  );
};

export default SsoButton;
