import { isEmpty } from 'lodash';

// When a resource is created with a location, after the creation
// the user is redirected to the edit view but the locations are not displayed.
// This function checks if the record is empty (true in creation mode, false in edit mode)
// and if the locations array is well formatted. Indeed the front send an array of string,
// but the GooglePlacesAutocomplete requires an array of object.
// The object contains a label and value key.
// example:
// {
//   label: 'Lyon, France',
//   value: {
//     place_id: '...',
//     description: 'Lyon, France'
//   }
// }
export const shouldRenderComponent = (
  record: Record<string, any>,
  locations: Record<string, any>[]
) => {
  const locationsHasWrongShape =
    Array.isArray(locations) &&
    locations.length > 0 &&
    locations.every((location: any) => typeof location === 'string');

  return !isEmpty(record) && locationsHasWrongShape;
};
