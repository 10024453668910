import React, {
  ComponentType,
  FunctionComponent,
  useRef,
  useState,
} from 'react';
import { useNotify, useDataProvider } from 'react-admin';
import useConfig from '../../hooks/useConfig';

/**
 * Create a clickable button for each format to download competencies
 *
 * @param Component
 * @param params
 */
function withCompetenciesFiles(
  Component: ComponentType<any>,
  params: Record<string, any> | undefined
): FunctionComponent {
  const Extension: FunctionComponent<Record<string, any>> = (props) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const [links, setLinks] = useState<Record<number, string>>({});
    const config = useConfig();
    const ref = useRef<HTMLAnchorElement>(null);

    const { resource = 'users', record, source } = props;

    if (
      !resource ||
      !record ||
      !source ||
      !params ||
      !Array.isArray(params.formats)
    )
      return null;

    const { formats } = params;

    function downloadFile(format: string, index: number) {
      const profileUrl = `${location.protocol}${config.uiUrl}/experts/${record[source]}`;
      const language = config.locale;
      const resourceToFetch = (params && params.resourceToFetch) || resource;

      setLoading(true);

      // TODO its a temporary react admin 3.19.4 fix it needs to be properly fixed
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dataProvider
        .fetch(
          resourceToFetch,
          {
            path: `${record[source]}/download?type=${format}&language=${language}&profile_url=${profileUrl}`,
          },
          {
            method: 'GET',
          }
        )
        .then(({ data }: { data: Record<string, string> }) => {
          if (ref && ref.current) {
            const element = ref.current;
            element.setAttribute('href', data.url);
            element.setAttribute('download', format);
            element.click();
            setLinks({ ...links, [index]: data.url });
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          notify('notify.error.download', 'error');
        });
    }

    const handleClick = (format: string, index: number) => () => {
      const href = links[index];
      if (!href) downloadFile(format, index);
      else if (ref && ref.current) {
        const element = ref.current;
        element.setAttribute('href', href);
        element.setAttribute('download', format);
        element.click();
      }
    };

    const buttons = formats.map((format: string, index: number) => ({
      onClick: handleClick(format, index),
      label: `formats.${format}.label`,
      key: format,
      disabled: loading,
    }));

    return (
      <>
        <Component {...props} record={{ ...record, [source]: buttons }} />
        <a ref={ref} />
      </>
    );
  };

  Extension.displayName = `withExtension(CompetenciesFiles)`;

  return Extension;
}

export default withCompetenciesFiles;
