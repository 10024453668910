import React, { FunctionComponent } from 'react';
import {
  Datagrid as RaDataGrid,
  EditButton as RaEditButton,
  List as RaList,
  ListProps,
  useRecordContext,
  useTranslate,
  useNotify,
} from 'react-admin';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/styles';
import Resource from '../../models/Resource';
import { Field as FieldType } from '../../types';
import Field from '../Field';
import { FieldProps } from '../Field';
import Filters from '../Filters';
import BulkActionButtons from '../BulkActionButtons';
import ListActions from './components/ListActions';
import useConfig from '../../hooks/useConfig';
import { EditPage, ListPage } from '../../config/types/page';
import checkRules from '../../helpers/rules';
import { labelToModifyKeys, modifiedLabel } from '../../helpers/label';
import ExpandListFactory from '../../factories/ExpandListFactory';

function buildDataGrid(fields: FieldType[]): React.ReactNode {
  const record = useRecordContext();
  const notify = useNotify();

  return fields.map((props) => {
    // To display field according to rules
    try {
      if (
        props.rules &&
        props.rules.hide &&
        checkRules(props.rules.hide, record)
      )
        return null;
    } catch (e) {
      notify(
        `"Hide" rule failed for Field ${props.key}: ${e.message}`,
        'error'
      );
    }

    return (
      <Field
        {...(props as FieldProps)}
        key={`${props.key}${
          props?.child?.source && `-${props?.child?.source}`
        }`}
        // To modify list column label for specific field
        {...(record &&
          props.label &&
          labelToModifyKeys.includes(props.label) && {
            label: modifiedLabel(props.label, record),
          })}
      />
    );
  });
}

const useStyles = makeStyles({
  list: {
    width: 'calc(100vw - 270px)',
    marginBottom: '50px',
    '& .MuiCard-root': {
      overflowX: 'scroll',
    },
    '& .MuiFormControl-marginDense': {
      marginTop: '20px',
    },
    '& .MuiTablePagination-toolbar': {
      position: 'fixed',
      right: '0px',
      bottom: '0px',
      backgroundColor: 'white',
      width: 'inherit',
      boxShadow:
        '0px 6px 6px -3px rgb(0 0 0 / 25%), 0px 2px 5px 0px rgb(0 0 0 / 19%), 0px 2px 8px 0px rgb(0 0 0 / 17%)',
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
      marginBottom: '10px',
    },
  },
  tableContainer: {
    width: 'inherit',
  },
  tabList: {
    '& .MuiCard-root': {
      overflowX: 'scroll',
    },
    '& .MuiToolbar-root': {
      minHeight: '0px',
    },
    '& .MuiPaper-root': {
      boxShadow: 'none',
    },
  },
});

const List: FunctionComponent<
  ListProps & {
    fromTabs?: boolean;
    list?: ListPage;
    edit?: EditPage;
  }
> = (props) => {
  const config = useConfig();
  const t = useTranslate();
  const resource: Resource = config.getResource(props.resource as string);
  const classes = useStyles();
  const { list } = props;
  const { filters, bulkActionButtons, search, sort, filterDefaultValues } =
    resource;

  const record = useRecordContext();

  const filter_resource =
    list !== undefined && get(list, 'filter_resource') !== undefined
      ? list.filter_resource
      : null;

  const filter_attribute =
    list !== undefined && get(list, 'filter_attribute') !== undefined
      ? list.filter_attribute
      : 'id';

  // Logic to get the fields to display in a list inside a tabs
  const fromTabs = props.fromTabs || false;
  if (fromTabs && list && !list.fields) return null;
  const fieldsToDisplay = fromTabs
    ? list && resource.getFieldsArray(list)
    : resource.getFieldsArray();

  const expand = (() => {
    if (list?.expand) return list.expand.type;
    if (resource?.listPage?.expand) return resource?.listPage?.expand.type;
  })();

  return (
    <RaList
      {...props}
      actions={
        <ListActions
          fromTabs={fromTabs}
          {...(list && {
            actions: resource.getListActionsButtons(list),
          })}
        />
      }
      classes={{ main: classes.tableContainer }}
      className={fromTabs ? classes.tabList : classes.list}
      filter={{
        ...(filter_resource &&
          filter_attribute && {
            [`${filter_resource}_id`]: record[filter_attribute],
          }),
      }}
      filters={
        resource.hasFilter && !fromTabs ? (
          <Filters search={search} filters={filters} />
        ) : undefined
      }
      // To hide the checkbox for each row in a list displayed inside a tabs or directly hide for skills_cartography index
      // TODO: Create a logic to hide the checkbox directly from the config
      bulkActionButtons={
        fromTabs || resource?.listPage?.hideBulkActions ? (
          false
        ) : (
          <BulkActionButtons bulkActions={bulkActionButtons} />
        )
      }
      sort={sort}
      filterDefaultValues={filterDefaultValues}
      empty={false}
      perPage={resource?.listPage?.perPage || 10}
    >
      <RaDataGrid
        {...(expand !== undefined && {
          expand: ExpandListFactory.getExpandComponent(expand),
        })}
      >
        {resource.hasEditButton() && (
          <RaEditButton
            label={t('list.edit.text')}
            {...(resource.getEditAndCreateBasePath() !== undefined && {
              basePath: resource.getEditAndCreateBasePath(),
            })}
          />
        )}
        {buildDataGrid(fieldsToDisplay as FieldType[])}
      </RaDataGrid>
    </RaList>
  );
};
export default List;
