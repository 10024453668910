import { get } from 'lodash';
import Resource from '../models/Resource';
import { reformatCharges } from '../helpers/relatedRecord';
import { CLIENT, EXPERT, MIXED } from '../constants/kind';

export function transformValuesBeforeSubmit(
  resource: Resource,
  values: Record<string, any>
) {
  // Ranges must be reformatted before submit because API required a range formatted as the following
  // "[<min>, <max>]"
  resource.getAttributesOfType('range').forEach((attribute) => {
    if (values[attribute.key] && typeof values[attribute.key] === 'string') {
      values[attribute.key] = `[${values[attribute.key]
        .split('..')
        .map((item: string) => parseInt(item))
        .join(',')}]`;
    } else if (values[attribute.key] && Array.isArray(values[attribute.key])) {
      values[attribute.key] = `[${values[attribute.key]
        .map((item: string) => parseInt(item))
        .join(',')}]`;
    }
  });

  // The charge_id and the charge_title of the charges have to be renamed,
  // to avoid api mistake
  if (get(values, 'charges') && values.charges.length > 0) {
    const { charges, ...restValues } = values;
    const chargesReformatted = reformatCharges(charges);

    return { ...restValues, charges: chargesReformatted };
  }

  // documents specification on creation/modification
  // if document target client user, no nees to have the contract types and
  // sub_category attributes to target him
  if (resource.name == 'documents') {
    switch (get(values, 'user_kind')) {
      case CLIENT:
        return {
          ...values,
          contract_types: [],
          sub_categories_id: [],
          company_expert_ids: null,
          company_mixed_ids: null,
          condition: null,
        };
      case EXPERT:
        return {
          ...values,
          client_condition: null,
          company_client_ids: null,
          company_mixed_ids: null,
        };
      case MIXED:
        return {
          ...values,
          company_client_ids: null,
          company_expert_ids: null,
        };
      default:
        break;
    }
  }

  return values;
}
