import React, { ComponentType, FunctionComponent, createElement } from 'react';
import { useTranslate } from 'react-admin';
import { Tooltip as MuiTooltip, Grid as MuiGrid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getIconComponentFromName } from '../../helpers/icons';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    '& svg': {
      width: '40px',
    },
  },
  svg: {
    marginTop: '18px',
    marginLeft: '8px',
  },
  tooltip: {
    fontSize: '12px',
  },
});

/**
 * Add admin picto to the input
 *
 * @param Component
 */
function withAuthorType(Component: ComponentType<any>): FunctionComponent {
  const Extension: FunctionComponent<Record<string, any>> = (props) => {
    const { record } = props;
    const classes = useStyles();
    const t = useTranslate();
    const updatedAt = new Date(record.updated_at);
    const updatedAtByAdmin = new Date(record.updated_at_by_admin);

    const icon = getIconComponentFromName('SupervisorAccount');

    if (!record.updated_at_by_admin) {
      return <Component {...props} />;
    }

    return (
      <div className={classes.root}>
        <Component {...props} />
        {updatedAtByAdmin >= updatedAt && icon && (
          <MuiTooltip
            title={t('resource.companies.field.updated_at.author_type.label')}
            placement="top-end"
            arrow
            classes={{ tooltip: classes.tooltip }}
          >
            <div className={classes.svg}>{createElement(icon)}</div>
          </MuiTooltip>
        )}
      </div>
    );
  };

  Extension.displayName = `withExtension(AuthorType)`;

  return Extension;
}

export default withAuthorType;
