import { memoize } from 'lodash';
import { isValidIBAN, isValidBIC } from 'ibantools';
import { isEmpty } from 'lodash';

// Inspired by react-admin doc https://github.com/marmelab/react-admin/blob/master/packages/ra-core/src/form/validate.ts
export const iban = memoize(
  (message = 'resource.validators.iban.message') =>
    (value: string) =>
      !isValidIBAN(value) ? message : undefined
);

export const bic = memoize(
  (message = 'resource.validators.bic.message') =>
    (value: string) =>
      !isValidBIC(value) ? message : undefined
);

export const linkedinUrl = memoize(
  (message = 'resource.validators.linkedin_url.message') =>
    (value: string) => {
      const linkedinPattern = new RegExp(
        'http(s)?://([w]+.)?linkedin.com/in/[A-z0-9_-]+/?'
      );
      if (!value) return undefined;

      return linkedinPattern.test(value) ? undefined : message;
    }
);

export const maxArrayLength = memoize(
  (max, message) => (value: string) =>
    !isEmpty(value) && value.length > max ? message : undefined
);
