import React, { FunctionComponent } from 'react';
import { useTranslate, Button as RaButton } from 'react-admin';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { RedirectButtonProps } from './types';
import { HiddenInput } from '../../../../config/types/page';

const useStyles = makeStyles({
  root: {
    marginLeft: '10px',
  },
});

const RedirectButton: FunctionComponent<RedirectButtonProps> = (props) => {
  const { label, extraUrl, data } = props;
  const t = useTranslate();
  const classes = useStyles();
  const history = useHistory();

  if (!extraUrl || !label) return null;

  const setHistoryState = (() => {
    if (props.hiddenInput && data) {
      const hiddenInputs = props.hiddenInput.reduce(
        (acc: Record<string, any>, input: HiddenInput): Record<string, any> => {
          const { key, value } = input;

          return { ...acc, [key]: data[value] };
        },
        {}
      );

      return { record: { ...hiddenInputs } };
    }

    return {};
  })();

  const handleClick = () => {
    if (history) {
      history.push(extraUrl, setHistoryState);
    }
  };

  return (
    <RaButton
      onClick={handleClick}
      label={t(label)}
      variant="outlined"
      className={classes.root}
    />
  );
};

export default RedirectButton;
