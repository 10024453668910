const MILESTONE_TOTAL_DUTY = 'resource.milestones.field.total_duty.label';
const MILESTONE_ADMIN_TOTAL_DUTY =
  'resource.milestones.field.admin_total_duty.label';

export const labelToModifyKeys = [
  MILESTONE_TOTAL_DUTY,
  MILESTONE_ADMIN_TOTAL_DUTY,
];

// Map to associate a key value to add as suffix of the label
// example: In Proposal view show/edit tab milestone,
// - initial label: resource.milestones.field.total_duty.label
// - quotation_kind = forfeit
// - modified label: resource.milestones.field.total_duty.label.forfeit
export const labelMap: Record<typeof labelToModifyKeys[number], string> = {
  [MILESTONE_TOTAL_DUTY]: 'quotation_kind',
  [MILESTONE_ADMIN_TOTAL_DUTY]: 'quotation_kind',
};

export const modifiedLabel = (label: string, record: Record<string, any>) => {
  const labelSuffix = record[labelMap[label]];

  return `${label}.${labelSuffix}`;
};

// In show/edit view the key value can have an other name
// (ex: kind in milestone list and milestone edit view)
export const labelMapInput: Record<typeof labelToModifyKeys[number], string> = {
  [MILESTONE_TOTAL_DUTY]: 'kind',
  [MILESTONE_ADMIN_TOTAL_DUTY]: 'kind',
};

export const modifiedInputLabel = (
  label: string,
  record: Record<string, any>
) => {
  const labelSuffix = record[labelMapInput[label]];

  return `${label}.${labelSuffix}`;
};
