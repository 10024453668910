import React, {
  createElement,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import {
  MenuItemLink as RaMenuItemLink,
  useTranslate,
  useNotify,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { isEmpty, get } from 'lodash';
import { getIconComponentFromName } from '../../helpers/icons';
import useConfig from '../../hooks/useConfig';
import SubMenu from '../SubMenu';
import { getUrlPartsNumber } from '../../helpers/url';
import { getAuthorizedResources } from '../../helpers/authorizedResources';
import { SubMenuItem } from '../../config/types';
import { httpClient } from '../../providers/dataProvider';
import {
  getCurrentUserId,
  setSuperAdmin,
  isAuthenticated,
} from '../../helpers/auth';
import Resource from '../../models/Resource';

const useStyles = makeStyles({
  root: {
    marginTop: '23px',
  },
  subMenuItem: {
    marginLeft: '16px',
  },
  menuItemLinkActive: {
    color: 'black',
  },
  menuItemLinkNotActive: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

type MenuLinkType = {
  link: string;
  label: string;
  key: string;
  icon: FunctionComponent<unknown> | undefined;
  className?: string;
  subMenuItem?: boolean;
  filter?: Record<string, any>;
};

const Menu = (props: any) => {
  const { onMenuClick } = props;
  const config = useConfig();
  const t = useTranslate();
  const classes = useStyles();
  const history = useHistory();
  const notify = useNotify();
  const [authorizedResources, setAuthorizedResources] = useState<Resource[]>(
    []
  );

  const { dataStudioURL } = config;
  const resources: Resource[] = config.resources;
  const IconComponent = getIconComponentFromName('ViewList');
  const DashboardIconComponent = getIconComponentFromName('Dashboard');

  useEffect(() => {
    // Logic to display in the Menu the authorized resources for the currentUser
    if (isAuthenticated()) {
      if (config.resourcesAccess) {
        httpClient(`${config.apiUrl}/users/${getCurrentUserId()}`)
          .then(({ json: data }: any) => {
            setAuthorizedResources(getAuthorizedResources(data));
            setSuperAdmin(get(data, 'super_administrator').toString());
          })
          .catch(() => {
            notify('notify.error.authorized_resources', 'error');
          });
      } else {
        setAuthorizedResources(resources);
      }
    }
  }, []);

  // Render Menu link
  const renderMenuItemLink = ({
    link,
    label,
    key,
    icon,
    className = '',
    subMenuItem = false,
  }: MenuLinkType) => (
    <RaMenuItemLink
      {...props}
      to={`/${link}`}
      primaryText={t(label)}
      onClick={onMenuClick}
      key={key}
      className={className}
      {...(icon && { leftIcon: createElement(icon) })}
      // For SubMenu link, the active class is disabled for edit/show view,
      // otherwise the wrong link can be render as active.
      // In edit/show view the subMenu toggle link can be render as active (check SubMenu component)
      {...(subMenuItem &&
        getUrlPartsNumber(get(history, 'location.pathname')) > 1 && {
          activeClassName: classes.menuItemLinkNotActive,
        })}
    />
  );

  return (
    <div className={classes.root}>
      {!isEmpty(dataStudioURL) && (
        <>
          {renderMenuItemLink({
            link: '',
            label: 'resource.insights.label',
            key: 'DataStudioUrl',
            icon: DashboardIconComponent,
          })}
        </>
      )}
      {authorizedResources.map((resource) => {
        if (resource.hasSubMenu) {
          const subMenuItems = resource.getSubMenuItems();
          if (subMenuItems && subMenuItems.length === 0) return null;

          return (
            <SubMenu
              name={resource.getSubMenuName() as string}
              label={resource.label}
              key={resource.getSubMenuName()}
              {...(IconComponent && { icon: createElement(IconComponent) })}
            >
              {subMenuItems &&
                subMenuItems.map((item: SubMenuItem) => (
                  <>
                    {renderMenuItemLink({
                      link: item.path,
                      label: item.label,
                      key: item.key,
                      icon: IconComponent,
                      className: classes.subMenuItem,
                      subMenuItem: true,
                    })}
                  </>
                ))}
            </SubMenu>
          );
        }

        // Menu without subMenu
        return (
          !resource.hideResource &&
          !resource.hasSubMenu && (
            <>
              {renderMenuItemLink({
                link: resource.name,
                label: resource.label,
                key: resource.name,
                icon: IconComponent,
              })}
            </>
          )
        );
      })}
    </div>
  );
};

export default Menu;
