import React, {
  ComponentType,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import { ObjectOf } from '../../types';
import { useNotify } from 'react-admin';
import KpiCard from '../../components/KpiCard';
import { makeStyles } from '@material-ui/core/styles';
import { httpClient } from '../../providers/dataProvider';
import { getIconComponentFromName } from '../../helpers/icons';
import useConfig from '../../hooks/useConfig';

const useStyles = makeStyles({
  kpi: {
    maxWidth: '270px',
  },
  kpiContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flex: '1 1 400px',
    },
  },
});

/**
 * Add Kpi Cards to component
 *
 * @param Component
 * @param params
 */
function withKpiCards(
  Component: ComponentType<any>,
  params: ObjectOf<any> | undefined
): FunctionComponent {
  const Extension: FunctionComponent<ObjectOf<any>> = (props) => {
    const notify = useNotify();
    const classes = useStyles();
    const path = (params && params.path) || `${props.resource}/stats`;
    const icons: ObjectOf<string> = (params && params.icons) || {};
    const config = useConfig();

    const [kpis, setKpis] = useState({});

    useEffect(() => {
      httpClient(`${config.apiUrl}/${path}`)
        .then(({ json: data }: any) => {
          setKpis(data);
        })
        .catch(() => {
          notify('notify.error.dashboard.stats', 'error');
        });
    }, []);

    return (
      <div>
        <div className={classes.kpiContainer}>
          {Object.entries(kpis).map(([key, kpi]) => {
            const icon = getIconComponentFromName(icons[key]);

            return (
              <KpiCard
                title={key}
                subtitle={kpi as number}
                key={key}
                icon={icon || getIconComponentFromName('Equalizer')}
              />
            );
          })}
        </div>
        <Component {...props} />
      </div>
    );
  };

  Extension.displayName = `withExtension(KpiCards)`;

  return Extension;
}

export default withKpiCards;
