import React, { useState, FunctionComponent, createElement } from 'react';
import { useTranslate } from 'react-admin';
import {
  Collapse as MuiCollapse,
  MenuItem as MuiMenuItem,
  ListItemIcon as MuiListItemIcon,
} from '@material-ui/core';
import { get } from 'lodash';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { SubMenuProps } from './types';
import { getIconComponentFromName } from '../../helpers/icons';
import { getUrlPartsNumber, getBasepath } from '../../helpers/url';

const useStyles = makeStyles({
  menuItem: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  menuListItemIcon: {
    minWidth: '40px',
  },
  menuItemLinkActive: {
    color: 'black',
  },
});

const SubMenu: FunctionComponent<SubMenuProps> = (props) => {
  const { children, name, label } = props;
  const t = useTranslate();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const classes = useStyles();
  const history = useHistory();

  const IconComponent = getIconComponentFromName('ViewList');
  const ToggleIconComponent = getIconComponentFromName('ExpandMore');

  const handleClick = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  // Return the active class when the url match the subMenu name
  // ex: with a /missions/:id url the missions MenuItem has the active class
  const handleMenuItemClass = (() => {
    const pathname = get(history, 'location.pathname');
    const urlPartsNumber = getUrlPartsNumber(pathname);
    if (urlPartsNumber > 1 && getBasepath(pathname) === name) {
      return classes.menuItemLinkActive;
    }

    return classes.menuItem;
  })();

  return (
    <div>
      {/* Link to open or close MuiCollapse component */}
      <MuiMenuItem onClick={handleClick} className={handleMenuItemClass}>
        <MuiListItemIcon className={classes.menuListItemIcon}>
          {IconComponent &&
            ToggleIconComponent &&
            createElement(isOpen ? ToggleIconComponent : IconComponent)}
        </MuiListItemIcon>
        {t(label)}
      </MuiMenuItem>
      {/* Nested links inside a MuiCollapse component */}
      <MuiCollapse in={isOpen} timeout="auto">
        {children}
      </MuiCollapse>
    </div>
  );
};

export default SubMenu;
