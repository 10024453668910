import DocumentFileExpand from '../components/ExpandList/DocumentFile';
import SkillsCartography from '../components/ExpandList/SkillsCartography';
import { ExpandListType } from '../config/types';

export default class ExpandListFactory {
  public static expandMap = {
    document_file: DocumentFileExpand,
    skills_cartography: SkillsCartography,
  };

  static getExpandComponent<T extends ExpandListType>(
    type: T
  ): typeof ExpandListFactory.expandMap[T] {
    if (Object.keys(ExpandListFactory.expandMap).includes(type))
      return ExpandListFactory.expandMap[type];
    throw new Error(`Expand type ${type} does not exist`);
  }
}
