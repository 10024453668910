import React, { FunctionComponent } from 'react';
import { RangeFieldProps } from './types';

const RangeField: FunctionComponent<RangeFieldProps> = (props) => {
  const { source, record } = props;

  if (
    typeof record === 'undefined' ||
    typeof source === 'undefined' ||
    !record[source] ||
    typeof record[source] !== 'string'
  ) {
    return null;
  }

  return <span>{record[source].split('..').join(' - ')}</span>;
};

export default RangeField;
