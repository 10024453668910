import React, { ComponentType, FunctionComponent } from 'react';
import { useTranslate } from 'react-admin';
import { getSuperAdminValue } from '../../helpers/auth';

/**
 * Display the input only if currentUser is super administrator
 *
 * @param Component
 * @param params
 */
function withSuperAdminPermission(
  Component: ComponentType<any>
): FunctionComponent {
  const Extension: FunctionComponent<Record<string, any>> = (props) => {
    const t = useTranslate();
    const { record, source } = props;

    if (!record || !source) return null;

    // Cookie value cannot be a boolean
    if (getSuperAdminValue() === 'false') return null;

    return <Component {...props} record={{ ...record }} />;
  };

  Extension.displayName = `withExtension(SuperAdminPermission)`;

  return Extension;
}

export default withSuperAdminPermission;
