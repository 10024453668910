import React, { ComponentType, FunctionComponent } from 'react';

/**
 * Multiply ratio value by 100 (to look like a percentage)
 *
 * @param Component
 */
function withMultiplyByHundred(
  Component: ComponentType<any>
): FunctionComponent {
  const Extension: FunctionComponent<Record<string, any>> = (props) => {
    const { record, source } = props;

    if (!record || !source) return null;

    return (
      <Component
        {...props}
        record={{ ...record, [source]: (record[source] * 100).toFixed(4) }}
      />
    );
  };

  Extension.displayName = `withExtension(MultiplyByHundred)`;

  return Extension;
}

export default withMultiplyByHundred;
