import React, { ComponentType, FunctionComponent } from 'react';
import { useTranslate } from 'react-admin';

type Choices = {
  id: string;
  name: string;
};

/**
 * Set a limited list of choices from Phrase
 *
 * @param Component
 * @param params
 */
function withLimitedList(
  Component: ComponentType<any>,
  params: Record<string, any> | undefined
): FunctionComponent {
  const Extension: FunctionComponent<Record<string, any>> = (props) => {
    if (!params) return null;
    const t = useTranslate();
    const { record, source } = props;
    const { listKey } = params;

    if (!record || !source || !listKey) return null;

    const getChoices = (): Choices[] => {
      const list = t(listKey);

      return list
        .split('|')
        .reduce(
          (acc: Choices[], value: string) => [
            ...acc,
            { id: value, name: value },
          ],
          []
        );
    };

    return (
      <Component {...props} choices={getChoices()} record={{ ...record }} />
    );
  };

  Extension.displayName = `withExtension(LimitedList)`;

  return Extension;
}

export default withLimitedList;
