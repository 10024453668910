import React, { FunctionComponent, useRef } from 'react';
import { Button as RaButton } from 'react-admin';
import { FileFieldProps } from './types';
import getSubAttribute from '../../helpers/getSubAttribute';

const FileField: FunctionComponent<FileFieldProps> = (props) => {
  const { record, source, title } = props;

  if (!record || !source) return null;

  const linkRef = useRef<HTMLAnchorElement>(null);

  function handleClick() {
    if (linkRef && linkRef.current) linkRef.current.click();
  }

  const fileLink = getSubAttribute(record, source);
  if (!fileLink) return null;

  return (
    <RaButton onClick={handleClick}>
      <>
        {title}
        <a ref={linkRef} href={fileLink} download />
      </>
    </RaButton>
  );
};

export default FileField;
