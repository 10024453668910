// get value from sub attribute object declare in config

const getSubAttribute = (record: Record<string, any>, source: any) => {
  if (typeof source === 'string') {
    return source.split('.').reduce((acc, currentValue) => {
      if (
        typeof acc !== 'undefined' &&
        typeof acc[currentValue] !== 'undefined'
      )
        return acc[currentValue];

      return acc;
    }, record);
  } else {
    return record[source];
  }
};

export default getSubAttribute;
