import React, { FunctionComponent } from 'react';
import { useTranslate, Button as RaButton } from 'react-admin';
import { ConfirmationModalProps } from './types';
import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  DialogContentText as MuiDialogContentText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  content: {
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
  actions: {
    justifyContent: 'center',
    marginBottom: '16px',
  },
});

const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = (
  props
) => {
  const t = useTranslate();
  const { title, children, handleClose, handleClick, open } = props;
  const classes = useStyles();

  return (
    <MuiDialog open={open} onClose={handleClose}>
      {title && <MuiDialogTitle>{title}</MuiDialogTitle>}
      {children && (
        <MuiDialogContent>
          <MuiDialogContentText className={classes.content}>
            {children}
          </MuiDialogContentText>
        </MuiDialogContent>
      )}
      <MuiDialogActions className={classes.actions}>
        <RaButton
          onClick={handleClose}
          variant="text"
          color="primary"
          label={t('modal.confirm.cancel')}
        />
        <RaButton
          onClick={handleClick}
          variant="contained"
          color="primary"
          label={t('modal.confirm.confirm')}
          autoFocus
        />
      </MuiDialogActions>
    </MuiDialog>
  );
};

export default ConfirmationModal;
