import React, { ComponentType, FunctionComponent, useMemo } from 'react';
import { Choice } from '../../config/types/field';
import {
  PAYMENT_CLIENT,
  FIVE_WORKING_DAYS,
  FIFTEEN_WORKING_DAYS,
  IMMEDIATE,
} from '../../constants/paymentPeriod';
import { get } from 'lodash';

/**
 * Hide choices dynamically according to a specific cutoff date
 *
 * @param Component
 */
function withDisabledByDate(Component: ComponentType<any>): FunctionComponent {
  const Extension: FunctionComponent<Record<string, any>> = (props) => {
    const { choices, record } = props;

    const newPaymentPeriodChoices = [
      PAYMENT_CLIENT,
      FIVE_WORKING_DAYS,
      FIFTEEN_WORKING_DAYS,
      IMMEDIATE,
    ];

    // Define a fixed cutoff date after which some choices will be hide
    const cutoffDate = new Date('2024-10-07');
    const contractCreatedAtDate = new Date(get(record, 'contract.created_at'));

    const transformedChoices = useMemo(() => {
      if (contractCreatedAtDate >= cutoffDate) {
        return choices.filter((choice: Choice) =>
          newPaymentPeriodChoices.includes(choice.id as string)
        );
      }

      return choices;
    }, [choices, contractCreatedAtDate]);

    return <Component {...props} choices={transformedChoices} />;
  };

  Extension.displayName = `withExtension(disabledByDate)`;

  return Extension;
}

export default withDisabledByDate;
