import React, { FunctionComponent } from 'react';
import { TranslatedTextInputProps } from './types';
import { useTranslate } from 'react-admin';
import { TextInput as RaTextInput } from 'ra-ui-materialui/lib/input';

const TranslatedTextInput: FunctionComponent<TranslatedTextInputProps> = (
  props: TranslatedTextInputProps
) => {
  const { source, ...restProps } = props;
  const t = useTranslate();

  if (typeof source === 'undefined' || !source) {
    return null;
  }

  // To translate automatically the value
  // ex: month_3 => Mars
  const textFormat = (value: string) => {
    if (value == null || value === '') return '';

    return t(value);
  };

  return (
    <RaTextInput
      source={source}
      type="text"
      format={textFormat}
      {...restProps}
    />
  );
};

export default TranslatedTextInput;
