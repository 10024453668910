import React, { FunctionComponent } from 'react';
import useConfig from '../../hooks/useConfig';
import { makeStyles } from '@material-ui/core/styles';
import { useAuthenticated } from 'react-admin';

const useStyles = makeStyles({
  iframe: {
    border: 'none',
    alignSelf: 'center',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
});

const Dashboard: FunctionComponent = () => {
  const config = useConfig();
  const classes = useStyles();
  useAuthenticated();

  return config.dataStudioURL ? (
    <iframe
      id="inlineFrameExample"
      title="Google Data Studio Insights"
      allowFullScreen={true}
      src={config.dataStudioURL}
      className={classes.iframe}
    ></iframe>
  ) : (
    <></>
  );
};

export default Dashboard;
