import React, { FunctionComponent, createElement } from 'react';
import {
  Stepper as MuiStepper,
  Step as MuiStep,
  StepContent as MuiStepContent,
  StepLabel as MuiStepLabel,
  Typography as MuiTypography,
  makeStyles,
} from '@material-ui/core';
import { useTranslate, Translate } from 'react-admin';
import { HistoryItemsProps } from './types';
import { getIconComponentFromName } from '../../../helpers/icons';
import {
  getLocaleDateString,
  getLocaleTimeString,
} from '../../../helpers/date';
import {
  History as HistoryType,
  HistoryDetails,
} from '../../../config/types/history';
import { historyIcons } from '../../../constants/history';
import HistoryItemsChildsUpdated from './components/HistoryItemsChildsUpdated/HistoryItemsChildsUpdated';
import { isEmpty } from 'lodash';
import Note from '../Note';
import { ClassNameMap } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    padding: '0px',
  },
  label: {
    color: 'black',
    fontWeight: 600,
    fontSize: '14px',
  },
  iconContainer: {
    color: 'grey',
  },
  content: {
    marginTop: '0px',
    color: 'grey',
    paddingBottom: '20px',
  },
  title: {
    color: 'black',
    fontSize: '14px',
  },
  subtitle: {
    color: 'black',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: '5px',
      width: '20px',
    },
  },
  details: {
    fontSize: '14px',
    whiteSpace: 'pre-wrap',
  },
  tag: {
    margin: '2px',
  },
  childs: {
    margin: '0px',
  },
  child: {
    margin: '2px',
    marginLeft: '-20px',
  },
  separator: {
    padding: '0px 2px',
  },
  date: {
    marginLeft: '5px',
  },
});

// For an unknown reason react-admin useTranslate hook modify the format of the date and display it in english
// this function allow to display the date outside of the useTranslate hook
const getDetailsTranslation = (
  details: HistoryDetails,
  t: Translate,
  classes: ClassNameMap<string>
): JSX.Element => {
  if (details.translation_key == undefined) {
    return <></>;
  }

  return (
    <>
      <span>{t(`${details.translation_key}.details`, details.args)}</span>
      {details.args &&
        typeof details.args.date !== 'undefined' &&
        details.args.date && (
          <span className={classes.date}>
            {getLocaleDateString(details.args.date as string)}
          </span>
        )}
    </>
  );
};

const HistoryItems: FunctionComponent<HistoryItemsProps> = (props) => {
  const { history, resource } = props;
  const t = useTranslate();
  const classes = useStyles();
  const adminIcon = getIconComponentFromName('SupervisorAccount');

  // This function returns the date and the hour formatted
  const getHistoryDate = (date: string): JSX.Element => (
    <>
      <span>{getLocaleDateString(date)}</span>
      <span className={classes.date}>{getLocaleTimeString(date)}</span>
    </>
  );

  return (
    <MuiStepper orientation="vertical" classes={{ root: classes.root }}>
      {history.map((h: HistoryType) => {
        const icon = getIconComponentFromName(historyIcons[h.key]);

        return (
          <>
            {h.kind === 'note' ? (
              <MuiStep key={h.id} active>
                <Note history={h} updateHistory={props.updateHistory} />
              </MuiStep>
            ) : (
              <MuiStep key={h.id} active>
                <MuiStepLabel
                  StepIconComponent={icon}
                  classes={{ iconContainer: classes.iconContainer }}
                >
                  <MuiTypography className={classes.label}>
                    {getHistoryDate(h.created_at)}
                  </MuiTypography>
                </MuiStepLabel>
                <MuiStepContent classes={{ root: classes.content }}>
                  <MuiTypography className={classes.title}>
                    {t(`history.${resource.name}.${h.key}.title`)}
                  </MuiTypography>
                  {h.user_fullname && (
                    <MuiTypography className={classes.subtitle}>
                      {h.user_fullname}
                      {h.admin_id && adminIcon && createElement(adminIcon)}
                    </MuiTypography>
                  )}
                  {h.details && h.details.translation_key && (
                    <MuiTypography className={classes.details}>
                      {getDetailsTranslation(h.details, t, classes)}
                    </MuiTypography>
                  )}
                  {!isEmpty(h.childs) && (
                    <HistoryItemsChildsUpdated childs={h.childs} />
                  )}
                </MuiStepContent>
              </MuiStep>
            )}
          </>
        );
      })}
    </MuiStepper>
  );
};

export default HistoryItems;
