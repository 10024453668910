import React, { ComponentType, FunctionComponent, useState } from 'react';
import { useRefresh } from 'react-admin';
import { first, isEmpty, last } from 'lodash';
import useConfig from '../../hooks/useConfig';
import { httpClient } from '../../providers/dataProvider';

/**
 * Transform pdfs object into clickable button to download a pdf for each language
 *
 * @param Component
 */
function withButtonLink(
  Component: ComponentType<any>,
  params: any | undefined
): FunctionComponent {
  const Extension: FunctionComponent<Record<string, any>> = (props) => {
    const [loading, setLoading] = useState(false);
    const config = useConfig();
    const refresh = useRefresh();
    const { record, source } = props;
    const {
      resourceToFecth,
      link,
      label,
      disabled = false,
      args = {},
    } = params;

    // here record[source] is a condition to display button link
    // boolean from api attribute or method
    // attribute config is_reset_password_token_expired = true|false
    if (!record || !source || !record[source]) return null;
    if (!resourceToFecth || !link || !label) return null;

    const handleClick = () => {
      setLoading(true);
      const paramsUrl = Object.entries(args)
        .map((value: [string, unknown]) => `${first(value)}=${last(value)}`)
        .join('&');

      httpClient(
        `${config.apiUrl}/${resourceToFecth}/${record['id']}/${link}${
          !isEmpty(args) ? `?${paramsUrl}` : ''
        }`,
        {
          method: 'PUT',
        }
      ).then(({ json: data }: Record<string, any>) => {
        data && refresh();
      });
    };

    const button = {
      onClick: () => handleClick(),
      label: label,
      key: source,
      disabled: disabled || loading,
    };

    return <Component {...props} record={{ ...record, [source]: [button] }} />;
  };

  Extension.displayName = `withExtension(ButtonLink)`;

  return Extension;
}

export default withButtonLink;
