import { Resource as ResourceType } from '../types';

export const mission: ResourceType = {
  name: 'missions',
  label: 'resource.missions.label',
  hideResource: false,
  subMenu: {
    name: 'missions',
    items: [
      {
        key: 'missions',
        path: 'missions',
        label: 'resource.missions.sub_menu.label',
      },
      {
        key: 'partner_missions',
        path: 'partner_missions',
        label: 'resource.partner_missions.sub_menu.label',
      },
    ],
  },
  attributes: [
    {
      key: 'id',
      label: 'resource.missions.field.id.label',
      type: 'text',
    },
    {
      key: 'attachments',
      label: 'resource.missions.field.attachments.label',
      type: 'buttons',
    },
    {
      key: 'begin',
      label: 'resource.missions.field.begin.label',
      type: 'select',
      choices: [
        {
          id: 'asap',
          name: 'resource.missions.field.begin.choices.asap.label',
        },
        {
          id: 'precise',
          name: 'resource.missions.field.begin.choices.precise.label',
        },
        {
          id: 'unknown',
          name: 'resource.missions.field.begin.choices.unknown.label',
        },
      ],
    },
    {
      key: 'begin_at',
      label: 'resource.missions.field.begin_at.label',
      type: 'date',
    },
    {
      key: 'billings',
      label: 'resource.companies.field.billings.label',
      type: 'select',
      choices: [
        {
          id: 'technical_assistance',
          name: 'resource.companies.field.billings.choices.technical_assistance.label',
        },
        {
          id: 'forfeit',
          name: 'resource.companies.field.billings.choices.forfeit.label',
        },
      ],
    },
    {
      key: 'budget',
      label: 'resource.missions.field.budget.label',
      type: 'range',
    },
    {
      key: 'company_confidentiality',
      label: 'resource.missions.field.confidentiality.label',
      type: 'boolean',
    },
    {
      key: 'company_id',
      label: 'resource.missions.field.company_id.label',
      type: 'reference',
      reference: 'companies',
    },
    {
      key: 'company_kinds',
      label: 'resource.missions.field.company_kinds.label',
      type: 'multipleSelect',
      choices: [
        {
          id: 'freelance',
          name: 'resource.companies.field.kind.choices.freelance.label',
        },
        {
          id: 'design_office',
          name: 'resource.companies.field.kind.choices.design_office.label',
        },
        {
          id: 'agency',
          name: 'resource.companies.field.kind.choices.agency.label',
        },
        {
          id: 'laboratory',
          name: 'resource.companies.field.kind.choices.laboratory.label',
        },
        {
          id: 'junior_company',
          name: 'resource.companies.field.kind.choices.junior_company.label',
        },
        {
          id: 'startup',
          name: 'resource.companies.field.kind.choices.startup.label',
        },
        {
          id: 'esn',
          name: 'resource.companies.field.kind.choices.esn.label',
        },
        {
          id: 'fablab',
          name: 'resource.companies.field.kind.choices.fablab.label',
        },
        {
          id: 'makerspace',
          name: 'resource.companies.field.kind.choices.makerspace.label',
        },
        {
          id: 'recruitment_candidate',
          name: 'resource.companies.field.kind.choices.recruitment_candidate.label',
        },
        {
          id: 'unknown',
          name: 'resource.companies.field.kind.choices.unknown.label',
        },
      ],
    },
    {
      key: 'company_level',
      label: 'resource.missions.field.company_level.label',
      type: 'range',
    },
    {
      key: 'context',
      label: 'resource.missions.field.context.label',
      type: 'text',
    },
    {
      key: 'context_description',
      label: 'resource.missions.field.context_description.label',
      type: 'wysiwyg',
    },
    {
      key: 'name',
      label: 'resource.missions.field.name.label',
      type: 'text',
    },
    {
      key: 'company_name',
      label: 'resource.missions.field.company_id.label',
      type: 'text',
    },
    {
      key: 'contract_types',
      label: 'resource.missions.field.contract_types.label',
      type: 'select',
      choices: [
        {
          id: 'fulltime_contract',
          name: 'resource.companies.field.contract_types.choices.fulltime_contract.label',
        },
        {
          id: 'interim',
          name: 'resource.companies.field.contract_types.choices.interim.label',
        },
        {
          id: 'internship',
          name: 'resource.companies.field.contract_types.choices.internship.label',
        },
        {
          id: 'missions',
          name: 'resource.companies.field.contract_types.choices.missions.label',
        },
        {
          id: 'missions_or_fulltime_contract',
          name: 'resource.companies.field.contract_types.choices.missions_or_fulltime_contract.label',
        },
        {
          id: 'professional_training_contract',
          name: 'resource.companies.field.contract_types.choices.professional_training_contract.label',
        },
        {
          id: 'temporary_contract',
          name: 'resource.companies.field.contract_types.choices.temporary_contract.label',
        },
        {
          id: 'thesis',
          name: 'resource.companies.field.contract_types.choices.thesis.label',
        },
      ],
    },
    {
      key: 'created_at',
      label: 'resource.missions.field.created_at.label',
      type: 'date',
    },
    {
      key: 'daily_rate',
      label: 'resource.missions.field.daily_rate.label',
      type: 'range',
    },
    {
      key: 'deliverables',
      label: 'resource.missions.field.deliverables.label',
      type: 'wysiwyg',
    },
    {
      key: 'description',
      label: 'resource.missions.field.description.label',
      type: 'wysiwyg',
    },
    {
      key: 'end',
      label: 'resource.missions.field.end.label',
      type: 'select',
      choices: [
        {
          id: 'approximative',
          name: 'resource.missions.field.end.choices.approximative.label',
        },
        {
          id: 'precise',
          name: 'resource.missions.field.end.choices.precise.label',
        },
        {
          id: 'unknown',
          name: 'resource.missions.field.end.choices.unknown.label',
        },
      ],
    },
    {
      key: 'end_at',
      label: 'resource.missions.field.end_at.label',
      type: 'date',
    },
    {
      key: 'end_months',
      label: 'resource.missions.field.end_months.label',
      type: 'number',
    },
    {
      key: 'goals',
      label: 'resource.missions.field.goals.label',
      type: 'wysiwyg',
    },
    {
      key: 'location',
      type: 'object',
      shape: [
        {
          key: 'address',
          type: 'text',
          label: 'resource.missions.field.location.address.label',
        },
      ],
    },
    {
      key: 'modalities',
      label: 'resource.companies.field.modalities.label',
      type: 'select',
      choices: [
        {
          id: 'remote',
          name: 'resource.companies.field.modalities.choices.remote.label',
        },
        {
          id: 'remote_can_move',
          name: 'resource.companies.field.modalities.choices.remote_can_move.label',
        },
        {
          id: 'on_site',
          name: 'resource.companies.field.modalities.choices.on_site.label',
        },
      ],
    },
    {
      key: 'name',
      label: 'resource.missions.field.name.label',
      type: 'text',
    },
    {
      key: 'nda',
      label: 'resource.missions.field.nda.label',
      type: 'boolean',
    },
    {
      key: 'nda_attachment',
      label: 'resource.missions.field.nda_attachments.label',
      type: 'buttons',
    },
    {
      key: 'owner_id',
      label: 'resource.missions.field.owner_id.label',
      type: 'reference',
      reference: 'users',
    },
    {
      key: 'salary',
      label: 'resource.missions.field.salary.label',
      type: 'range',
    },
    {
      key: 'sectors',
      label: 'resource.companies.field.sectors.label',
      type: 'multipleSelect',
      choices: [
        {
          id: 'automative',
          name: 'resource.companies.field.sectors.choices.automative.label',
        },
        {
          id: 'aeronautics',
          name: 'resource.companies.field.sectors.choices.aeronautics.label',
        },
        {
          id: 'space',
          name: 'resource.companies.field.sectors.choices.space.label',
        },
        {
          id: 'defense',
          name: 'resource.companies.field.sectors.choices.defense.label',
        },
        {
          id: 'railway',
          name: 'resource.companies.field.sectors.choices.railway.label',
        },
        {
          id: 'telecoms',
          name: 'resource.companies.field.sectors.choices.telecoms.label',
        },
        {
          id: 'energy',
          name: 'resource.companies.field.sectors.choices.energy.label',
        },
        {
          id: 'services_and_information_systems',
          name: 'resource.companies.field.sectors.choices.services_and_information_systems.label',
        },
        {
          id: 'oil_and_gas',
          name: 'resource.companies.field.sectors.choices.oil_and_gas.label',
        },
        {
          id: 'naval',
          name: 'resource.companies.field.sectors.choices.naval.label',
        },
        {
          id: 'machining_or_heavy_industry',
          name: 'resource.companies.field.sectors.choices.machining_or_heavy_industry.label',
        },
        {
          id: 'bioengineering',
          name: 'resource.companies.field.sectors.choices.bioengineering.label',
        },
        {
          id: 'bank_insurance',
          name: 'resource.companies.field.sectors.choices.bank_insurance.label',
        },
        {
          id: 'infrastructure',
          name: 'resource.companies.field.sectors.choices.infrastructure.label',
        },
        {
          id: 'digital',
          name: 'resource.companies.field.sectors.choices.digital.label',
        },
      ],
    },
    {
      key: 'favorite_skills_name',
      label: 'resource.missions.field.favorite_skills_name.label',
      type: 'array',
      itemSource: 'name',
    },
    {
      key: 'skills_name',
      label: 'resource.missions.field.skills_name.label',
      type: 'array',
      itemSource: 'name',
    },
    {
      key: 'status',
      label: 'resource.missions.field.status.label',
      type: 'select',
      choices: [
        {
          id: 'draft',
          name: 'resource.missions.field.status.choices.draft.label',
        },
        {
          id: 'published',
          name: 'resource.missions.field.status.choices.published.label',
        },
        {
          id: 'review',
          name: 'resource.missions.field.status.choices.review.label',
        },
        {
          id: 'wip',
          name: 'resource.missions.field.status.choices.wip.label',
        },
        {
          id: 'archived',
          name: 'resource.missions.field.status.choices.archived.label',
        },
        {
          id: 'finished',
          name: 'resource.missions.field.status.choices.finished.label',
        },
      ],
    },
    {
      key: 'sub_categories_id',
      label: 'resource.companies.field.sub_categories_name.label',
      type: 'referenceArray',
      reference: 'sub_categories',
    },
    {
      key: 'updated_at',
      label: 'resource.missions.field.updated_at.label',
      type: 'date',
    },
    {
      key: 'validated',
      label: 'resource.missions.field.validated.label',
      type: 'boolean',
    },
    {
      key: 'validated_at',
      label: 'resource.missions.field.validated_at.label',
      type: 'date',
    },
    {
      key: 'vip',
      label: 'resource.missions.field.vip.label',
      type: 'boolean',
    },
    {
      key: 'company_vip_access',
      label: 'resource.missions.field.vip.label',
      type: 'boolean',
    },
    {
      key: 'work_days',
      label: 'resource.missions.field.work_days.label',
      type: 'number',
    },
    {
      key: 'applicants_number',
      label: 'resource.missions.field.applicants_number.label',
      type: 'number',
    },
    {
      key: 'outsourcing',
      label: 'resource.missions.field.outsourcing.label',
      type: 'boolean',
    },
    {
      key: 'archived_kind',
      label: 'resource.missions.field.archived_kind.label',
      type: 'select',
      choices: [
        {
          id: 'won',
          name: 'resource.missions.field.archived_kind.choices.won.label',
        },
        {
          id: 'cancelled_by_client',
          name: 'resource.missions.field.archived_kind.choices.cancelled_by_client.label',
        },
        {
          id: 'cancelled_by_owner',
          name: 'resource.missions.field.archived_kind.choices.cancelled_by_owner.label',
        },
        {
          id: 'staffed_by_competitor',
          name: 'resource.missions.field.archived_kind.choices.staffed_by_competitor.label',
        },
        {
          id: 'no_news_from_client',
          name: 'resource.missions.field.archived_kind.choices.no_news_from_client.label',
        },
      ],
    },
    {
      key: 'archived_text',
      label: 'resource.missions.field.archived_text.label',
      type: 'text',
    },
    {
      key: 'partner_offer',
      label: 'resource.missions.field.partner_offer.label',
      type: 'boolean',
    },
    {
      key: 'partner_company_name',
      label: 'resource.missions.field.partner_company_name.label',
      type: 'text',
    },
    {
      key: 'generic_offer',
      label: 'resource.missions.field.generic_offer.label',
      type: 'boolean',
    },
  ],
  pages: {
    list: {
      hasEditButton: true,
      search: {
        enabled: true,
        label: 'filter.search.label',
      },
      filters: [
        {
          source: 'status',
          type: 'multipleSelect',
          optionsFilter: {
            className: 'multipleSelectFilter',
          },
        },
        {
          source: 'contract_types',
          type: 'multipleSelect',
          optionsFilter: {
            className: 'multipleSelectFilter',
          },
        },
        {
          source: 'generic_offer',
        },
      ],
      fields: [
        {
          source: 'owner_id',
          // Reference fields need children to display
          label: 'resource.missions.field.company_id.label',
          child: {
            source: 'users.company_name',
          },
        },
        {
          source: 'context',
        },
        {
          source: 'name',
          sortable: false,
        },
        {
          source: 'contract_types',
          type: 'array',
          translationPrefix: 'resource.companies.field.contract_types.choices',
        },
        {
          source: 'status',
        },
        {
          source: 'daily_rate',
        },
        {
          source: 'salary',
        },
        {
          source: 'generic_offer',
        },
        {
          source: 'validated_at',
        },
        {
          source: 'created_at',
        },
      ],
      bulkActionButtons: [
        {
          name: 'transfer',
          type: 'modal',
          label: 'resource.missions.bulk_action_buttons.transfer.label',
          extraUrl: '/transfer',
          confirmation: {
            title: 'modal.select_company.transfer_project',
          },
          modal: {
            inputs: {
              company_id: {
                source: 'companies',
                filter: { user_kind: 'client_or_mixed' },
                label: 'modal.select_company.label',
                child: {
                  type: 'autocomplete',
                  optionText: 'name',
                },
              },
            },
          },
        },
        {
          name: 'accept',
          type: 'update',
          label: 'resource.missions.bulk_action_buttons.accept.label',
          values: {
            validated: true,
          },
        },
        {
          name: 'archive',
          type: 'update',
          label: 'resource.missions.bulk_action_buttons.archive.label',
          values: {
            archived: true,
          },
        },
      ],
    },
    edit: {
      singleRedirect: {
        page: 'edit',
        basePath: '/missions',
        recordId: 'id',
      },
      actions: [
        {
          name: 'accept',
          type: 'update',
          label: 'resource.missions.bulk_action_buttons.accept.label',
          values: { validated: true },
          extraUrl: '/',
          disabled: [{ property: 'validated', op: 'eq', value: true }],
        },
        {
          name: 'archive',
          type: 'update',
          label: 'resource.missions.bulk_action_buttons.archive.label',
          values: { archived: true },
          extraUrl: '/',
          disabled: [{ property: 'status', op: 'eq', value: 'archived' }],
        },
      ],
      left: {
        tabs: [
          {
            key: 'information',
            title: 'resource.missions.tab.information.title',
            sections: [
              {
                key: 'profile',
                title: 'resource.missions.section.profile.title',
                layout: [
                  ['name'],
                  ['skills_name'],
                  ['favorite_skills_name'],
                  ['contract_types', 'company_kinds'],
                  ['company_level'],
                  ['description'],
                  ['sub_categories_id'],
                  ['sectors'],
                ],
                inputs: {
                  name: {
                    disabled: true,
                    validators: [{ key: 'required' }],
                  },
                  contract_types: {
                    disabled: true,
                    validators: [{ key: 'required' }],
                  },
                  company_kinds: {
                    disabled: true,
                    validators: [{ key: 'required' }],
                    rules: {
                      hide: [
                        {
                          property: 'contract_types',
                          op: 'not_includes',
                          value: ['missions', 'missions_or_fulltime_contract'],
                        },
                      ],
                    },
                  },
                  company_level: {
                    validators: [{ key: 'required' }],
                    options: {
                      min: 0,
                      max: 10,
                      step: 1,
                    },
                  },
                  description: {
                    validators: [
                      {
                        key: 'maxLength',
                        value: 10000,
                      },
                      {
                        key: 'required',
                      },
                    ],
                  },
                  sectors: {
                    disabled: true,
                    validators: [{ key: 'required' }],
                  },
                  sub_categories_id: {
                    child: {
                      type: 'autocompleteArray',
                      optionText: 'sub_categories_name_phrase',
                      options: {
                        perPage: 108,
                        allowEmpty: false,
                      },
                      extensions: [
                        {
                          key: 'sort',
                          params: {
                            type: 'alphabetic',
                            mode: 'asc',
                          },
                        },
                      ],
                    },
                  },
                },
                fields: {
                  skills_name: {
                    addLabel: true,
                    link: false,
                  },
                  favorite_skills_name: {
                    addLabel: true,
                    link: false,
                  },
                },
              },
              {
                key: 'modality',
                title: 'resource.missions.section.modality.title',
                layout: [
                  ['begin', 'begin_at'],
                  ['end', 'end_at', 'end_months'],
                  ['work_days', 'location.address', 'modalities'],
                  ['billings'],
                  ['applicants_number', 'outsourcing'],
                  ['daily_rate'],
                  ['budget'],
                  ['salary'],
                ],
                inputs: {
                  begin: {
                    width: 6,
                    validators: [{ key: 'required' }],
                  },
                  begin_at: {
                    width: 6,
                    validators: [{ key: 'required' }],
                    rules: {
                      hide: [
                        {
                          property: 'begin',
                          op: 'neq',
                          value: 'precise',
                        },
                      ],
                    },
                  },
                  end: {
                    width: 4,
                    validators: [{ key: 'required' }],
                  },
                  end_at: {
                    width: 4,
                    validators: [{ key: 'required' }],
                    rules: {
                      hide: [
                        {
                          property: 'end',
                          op: 'neq',
                          value: 'precise',
                        },
                      ],
                    },
                  },
                  end_months: {
                    width: 4,
                    validators: [{ key: 'required' }],
                    rules: {
                      hide: [
                        {
                          property: 'end',
                          op: 'neq',
                          value: 'approximative',
                        },
                      ],
                    },
                  },
                  work_days: {
                    width: 2,
                    validators: [{ key: 'required' }],
                  },
                  'location.address': {
                    width: 6,
                    disabled: true,
                    validators: [{ key: 'required' }],
                  },
                  modalities: {
                    width: 4,
                    validators: [{ key: 'required' }],
                  },
                  billings: {
                    validators: [{ key: 'required' }],
                    rules: {
                      hide: [
                        {
                          property: 'contract_types',
                          op: 'not_includes',
                          value: ['missions', 'missions_or_fulltime_contract'],
                        },
                      ],
                    },
                  },
                  applicants_number: {
                    width: 6,
                    validators: [
                      { key: 'required' },
                      { key: 'minLength', value: 1 },
                    ],
                  },
                  outsourcing: {
                    width: 6,
                  },
                  daily_rate: {
                    validators: [{ key: 'required' }],
                    options: {
                      min: 150,
                      max: 2000,
                      step: 10,
                    },
                    rules: {
                      hide: [
                        {
                          property: 'billings',
                          op: 'neq',
                          value: 'technical_assistance',
                        },
                      ],
                    },
                  },
                  budget: {
                    validators: [{ key: 'required' }],
                    options: {
                      min: 0,
                      max: 50000,
                      step: 1000,
                    },
                    rules: {
                      hide: [
                        {
                          property: 'billings',
                          op: 'neq',
                          value: 'forfeit',
                        },
                      ],
                    },
                  },
                  salary: {
                    validators: [{ key: 'required' }],
                    options: {
                      min: 25000,
                      max: 100000,
                      step: 1000,
                    },
                    rules: {
                      hide: [
                        {
                          property: 'contract_types',
                          op: 'eq',
                          value: 'missions',
                        },
                      ],
                    },
                  },
                },
              },
              {
                key: 'projet',
                title: 'resource.missions.section.projet.title',
                layout: [
                  ['context'],
                  ['context_description'],
                  ['goals'],
                  ['deliverables'],
                  ['attachments'],
                ],
                inputs: {
                  context: {
                    validators: [{ key: 'required' }],
                    rules: {
                      hide: [
                        {
                          property: 'contract_types',
                          op: 'not_includes',
                          value: ['missions', 'missions_or_fulltime_contract'],
                        },
                      ],
                    },
                  },
                  context_description: {
                    validators: [{ key: 'required' }],
                    rules: {
                      hide: [
                        {
                          property: 'contract_types',
                          op: 'not_includes',
                          value: ['missions', 'missions_or_fulltime_contract'],
                        },
                      ],
                    },
                  },
                  goals: {
                    validators: [{ key: 'required' }],
                  },
                  deliverables: {
                    validators: [{ key: 'required' }],
                    rules: {
                      hide: [
                        {
                          property: 'contract_types',
                          op: 'not_includes',
                          value: ['missions', 'missions_or_fulltime_contract'],
                        },
                      ],
                    },
                  },
                },
                fields: {
                  attachments: {
                    addLabel: true,
                    extensions: [
                      {
                        key: 'attachmentsFiles',
                      },
                    ],
                  },
                },
              },
              {
                key: 'confidentiality',
                title: 'resource.missions.section.confidentiality.title',
                layout: [
                  ['nda', 'nda_attachment'],
                  ['vip'],
                  ['company_confidentiality'],
                ],
                inputs: {
                  nda: {
                    width: 4,
                    disabled: true,
                    validators: [{ key: 'required' }],
                  },
                  vip: {
                    disabled: true,
                    validators: [{ key: 'required' }],
                    rules: {
                      hide: [
                        {
                          property: 'company_vip_access',
                          op: 'eq',
                          value: false,
                        },
                      ],
                    },
                  },
                  company_confidentiality: {
                    validators: [{ key: 'required' }],
                  },
                },
                fields: {
                  nda_attachment: {
                    addLabel: true,
                    extensions: [
                      {
                        key: 'attachmentsFiles',
                      },
                    ],
                    rules: {
                      hide: [{ property: 'nda', op: 'eq', value: false }],
                    },
                  },
                },
              },
              {
                key: 'archived',
                title: 'resource.missions.section.archived.title',
                layout: [['archived_kind'], ['archived_text']],
                inputs: {
                  archived_kind: {},
                  archived_text: {
                    disabled: true,
                  },
                },
                rules: {
                  hide: [
                    {
                      property: 'status',
                      op: 'neq',
                      value: 'archived',
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
      right: [
        {
          sections: [
            {
              key: 'details',
              title: 'resource.missions.section.details.title',
              layout: [
                ['id', 'status'],
                ['created_at', 'updated_at'],
                ['validated_at'],
                ['partner_offer'],
                ['owner_id'],
                ['company_name'],
                ['partner_company_name'],
              ],
              inputs: {
                id: {
                  width: 6,
                  disabled: true,
                },
                status: {
                  width: 6,
                  disabled: true,
                },
                created_at: {
                  disabled: true,
                  width: 6,
                },
                updated_at: {
                  disabled: true,
                  width: 6,
                },
                validated_at: {
                  disabled: true,
                },
                partner_offer: {
                  disabled: true,
                  width: 12,
                  rules: {
                    hide: [
                      {
                        property: 'partner_offer',
                        op: 'eq',
                        value: false,
                      },
                    ],
                  },
                },
              },
              fields: {
                owner_id: {
                  addLabel: true,
                  child: {
                    type: 'text',
                    source: 'users.full_name',
                  },
                },
                company_name: {
                  addLabel: true,
                  link: false,
                  rules: {
                    hide: [
                      {
                        property: 'partner_offer',
                        op: 'eq',
                        value: true,
                      },
                    ],
                  },
                },
                partner_company_name: {
                  addLabel: true,
                  link: false,
                  rules: {
                    hide: [
                      {
                        property: 'partner_offer',
                        op: 'eq',
                        value: false,
                      },
                    ],
                  },
                },
              },
            },
          ],
        },
        {
          sections: [
            {
              key: 'history',
              title: 'resource.missions.section.history.title',
              layout: [],
              inputs: {},
              history: true,
            },
          ],
        },
      ],
    },
  },
};
