import * as React from 'react';
import { FunctionComponent } from 'react';
import {
  Button as RaButton,
  useCreate,
  useListContext,
  useNotify,
  useTranslate,
} from 'react-admin';
import { getCurrentUserId } from '../../../../helpers/auth';
import DownloadIcon from '@material-ui/icons/GetApp';
import useConfig from '../../../../hooks/useConfig';

const ExportButton: FunctionComponent = () => {
  const t = useTranslate();
  const notify = useNotify();
  const { resource: resourceName } = useListContext();
  const config = useConfig();
  const resource = config.getResource(resourceName);
  const kindType = resource.getExportKindType();

  const [create] = useCreate(
    `${resourceName}/export`,
    {},
    {
      onSuccess: () => notify('notify.success.export'),
      onFailure: () => notify('notify.error.export', 'error'),
    }
  );

  const handleExport = () => {
    create({
      payload: {
        data: { admin_id: getCurrentUserId(), kind: kindType },
      },
    });
  };

  return (
    <RaButton onClick={handleExport} label={t('resource.action.export')}>
      <DownloadIcon />
    </RaButton>
  );
};

export default ExportButton;
