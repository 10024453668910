import { defaultTheme } from 'react-admin';
import { ThemeOptions as MuiThemeOptions } from '@material-ui/core';
import merge from 'lodash/merge';
import { Theme } from '../config/types/theme';

export const createTheme = (theme: Theme | undefined): MuiThemeOptions => {
  const customTheme: MuiThemeOptions = { palette: {} };

  if (customTheme.palette && theme?.primary) {
    customTheme.palette.primary = { main: theme.primary };
  }

  if (customTheme.palette && theme?.secondary) {
    customTheme.palette.secondary = { main: theme.secondary };
  }

  if (customTheme.palette && theme?.info) {
    customTheme.palette.info = { main: theme.info };
  }

  return merge({}, defaultTheme, customTheme);
};
