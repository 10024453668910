import Cookies from 'js-cookie';
import { StringOrUndefined } from '../types';

// TODO scopes type is not properly set, it should be string[] or string. We should cast it with one type only.
// The proper type should be string[] to keep backend consistency

export const COOKIE_TOKEN_NAME = 'TB_SID';
export const COOKIE_SCOPES_NAME = 'SB_SID';
export const COOKIE_USER_ID_NAME = 'UB_SID';
export const COOKIE_SUPER_ADMIN_NAME = 'SA_SID';

export const isAuthenticated = (): boolean => !!getToken();

export const getToken = (): StringOrUndefined => Cookies.get(COOKIE_TOKEN_NAME);
export const getScopes = (): StringOrUndefined =>
  Cookies.get(COOKIE_SCOPES_NAME);

const expiredInTwelveHours = new Date(
  new Date().getTime() + 12 * 60 * 60 * 1000
);

const setCookie = (name: string, value: string, expired_at?: Date): void => {
  Cookies.set(name, value, { expires: expired_at || expiredInTwelveHours });
};

export const setToken = (token: string, expired_at: Date): void => {
  setCookie(COOKIE_TOKEN_NAME, token, expired_at);
};
export const setScopes = (scopes: string, expired_at: Date): void =>
  setCookie(COOKIE_SCOPES_NAME, scopes, expired_at);

export const setUserId = (userId: string, expired_at: Date): void =>
  setCookie(COOKIE_USER_ID_NAME, userId, expired_at);

export const setSuperAdmin = (superAdmin: string): void =>
  setCookie(COOKIE_SUPER_ADMIN_NAME, superAdmin);

export const handleCookies = (
  token: string,
  scopes: string | string[],
  userId: string,
  expired_at?: Date
): void => {
  setToken(token, expired_at || expiredInTwelveHours);
  setScopes(scopes as string, expired_at || expiredInTwelveHours);
  setUserId(userId, expired_at || expiredInTwelveHours);
};

export const deleteCookies = (): void => {
  Cookies.remove(COOKIE_SCOPES_NAME);
  Cookies.remove(COOKIE_TOKEN_NAME);
  Cookies.remove(COOKIE_USER_ID_NAME);
  Cookies.remove(COOKIE_SUPER_ADMIN_NAME);
};

export const isTokenExpired = (token = getToken()): boolean => !token;

export const isAdmin = (
  scopes: StringOrUndefined | string[] = getScopes()
): boolean => {
  if (!scopes) return false;

  return scopes.includes('admin');
};

export const getCurrentUserId = (): StringOrUndefined =>
  Cookies.get(COOKIE_USER_ID_NAME);

export const getSuperAdminValue = (): StringOrUndefined =>
  Cookies.get(COOKIE_SUPER_ADMIN_NAME);
