import React, {
  createElement,
  Fragment,
  FunctionComponent,
  useState,
} from 'react';
import {
  Button as RaButton,
  useNotify,
  useRefresh,
  useTranslate,
  useUnselectAll,
  useUpdateMany,
  useCreate,
  useDeleteMany,
} from 'react-admin';
import { isEmpty, get } from 'lodash';
import { useHistory } from 'react-router';
import { ModalForm } from '../../../modals';
import { ModalFormButtonProps } from './types';
import { Input as ConfigInput } from '../../../../config/types/field';
import useConfig from '../../../../hooks/useConfig';
import getUrlPaths from '../../../../helpers/getUrlPath';
import { getIconComponentFromName } from '../../../../helpers/icons';
import { DELETE, CREATE, UPDATE } from '../../../../constants/httpMethod';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  button: {
    marginLeft: '10px',
  },
  delete: {
    border: 'none',
    color: '#DC004E',
    backgroundColor: 'rgba(220, 0, 78, 0.04)',
    '&:hover': {
      border: 'none',
      backgroundColor: 'rgba(220, 0, 78, 0.15)',
    },
  },
});

const ModalFormButton: FunctionComponent<ModalFormButtonProps> = (props) => {
  const [showDialog, setShowDialog] = useState(false);
  const config = useConfig();
  const t = useTranslate();
  const {
    selectedIds = [],
    modal,
    resource,
    extraUrl,
    label,
    confirmation,
    httpMethod = UPDATE,
  } = props;
  const classes = useStyles();
  const history = useHistory();

  if (!selectedIds || !resource) return null;

  const resourceInstance = config.getResource(resource);

  const inputs: any[] = (() => {
    if (modal?.inputs) {
      return Object.entries(modal.inputs).map(([key, input]) => ({
        ...resourceInstance.getAttribute(key),
        ...(input as ConfigInput),
        source: key,
      }));
    }

    return [];
  })();

  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();

  const urlPaths = getUrlPaths(selectedIds, extraUrl);

  const [create] = useCreate(
    resource,
    {},
    {
      onSuccess: () => {
        if (get(props, 'redirectToTabsNumber')) {
          updateTabsStateAndRedirect();
        }
        refresh();
        notify('notify.success.create');
      },
      onFailure: () => notify('notify.error.create', 'error'),
    }
  );

  const [deleteMany, { loading: loadingDelete }] = useDeleteMany();

  const [updateMany, { loading }] = useUpdateMany(
    resource,
    urlPaths,
    {},
    {
      onSuccess: () => {
        refresh();
        notify('notify.success.update');
        unselectAll(resource);
      },
      onFailure: () => notify('notify.error.update', 'error'),
    }
  );

  // Logic to add state in the history router state to select the current tabs
  // in the Tabs component after refreshing the page
  function updateTabsStateAndRedirect() {
    const currentState = get(history, 'location.pathname.state');
    const redirectToTabsNumber = get(props, 'redirectToTabsNumber');

    const newState = {
      ...currentState,
      ...(redirectToTabsNumber && { currentTabs: redirectToTabsNumber }),
    };

    history.replace(get(history, 'location.pathname'), newState);
  }

  function handleClick(formValues: Record<string, any>) {
    const formattedFormValues = {
      ...formValues,
      resource_selected_id: selectedIds,
    };

    setShowDialog(false);

    if (httpMethod === CREATE) {
      return create({
        payload: {
          data: formattedFormValues,
        },
      });
    }

    if (httpMethod === UPDATE) {
      updateMany({
        payload: {
          data: formValues,
        },
      });
    }

    if (httpMethod === DELETE) {
      const ids = [selectedIds] as string[];
      if (!isEmpty(ids)) {
        deleteMany(resource, ids);
      }
      // Refresh the page after delete to avoid errors
      refresh();
      if (get(props, 'redirectToTabsNumber')) {
        updateTabsStateAndRedirect();
      }
      notify('notify.success.delete');
    }
  }

  function handleOpenModalClick() {
    setShowDialog(true);
  }

  function handleCloseModalClick() {
    setShowDialog(false);
  }

  const IconComponent = getIconComponentFromName(get(props, 'icon'));

  function renderButton() {
    if (IconComponent) {
      return (
        <RaButton
          disabled={loading || loadingDelete}
          onClick={handleOpenModalClick}
          variant="outlined"
          className={classes.delete}
        >
          {createElement(IconComponent)}
        </RaButton>
      );
    }

    return (
      <RaButton
        disabled={loading || loadingDelete}
        label={label}
        onClick={handleOpenModalClick}
        variant="outlined"
        className={classes.button}
      />
    );
  }

  return (
    <Fragment>
      {renderButton()}
      <ModalForm
        open={showDialog}
        title={confirmation?.title && t(confirmation.title)}
        handleClick={handleClick}
        handleClose={handleCloseModalClick}
        inputs={inputs}
        resource={resource}
      />
    </Fragment>
  );
};

export default ModalFormButton;
