import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Grid as MuiGrid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Identifier, useTranslate, Record as RaRecord } from 'react-admin';
import { config } from '../../../helpers/config';
import { httpClient } from '../../../providers/dataProvider';
import { getLocaleDateString } from '../../../helpers/date';
import { useHistory } from 'react-router';
import { isEmpty } from 'lodash';

const useStyles = makeStyles({
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  bold: {
    fontWeight: 'bold',
  },
  mediumBold: {
    fontWeight: 500,
  },
  pending: {
    color: '#364CBB',
  },
  refused: {
    color: '#D01919',
  },
  accepted: {
    color: '#4BDDA6',
  },
  expired: {
    color: '#D01919',
  },
  archived: {
    color: '#A8AEB5',
  },
  marginBottom: {
    marginBottom: '5px',
  },
  hr: {
    display: 'block',
    borderBottom: '1px solid rgba(223, 223, 223, 1)',
    margin: '10px 0',
  },
  icon: {
    marginLeft: '50%',
  },
});

const DocumentFile: FunctionComponent<{
  basePath: string;
  id: Identifier;
  record: RaRecord;
  resource: string;
}> = (props) => {
  const t = useTranslate();
  const classes: any = useStyles();
  const [loaded, setLoaded] = useState(false);
  const [documentFiles, setDocumentFiles] = useState([]);
  const history = useHistory();
  const { record } = props;

  // get user id from url
  const pathname = history.location.pathname;

  const position = pathname.indexOf('users/');
  const userId = pathname.slice(
    position + 'users/'.length,
    position + 'users/'.length + 36
  );

  const handleDocumentFile = useCallback(() => {
    httpClient(
      `${config.apiUrl}/document_files?filter={"document_id":"${record.document_id}","user_id":"${userId}"}`
    )
      .then(({ json: data }: any) => {
        setDocumentFiles(data);
        setLoaded(true);
      })
      .catch((error: Record<string, any>) => ({ error }));
  }, []);

  useEffect(() => {
    if (!loaded) {
      handleDocumentFile();
    }
  }, [loaded, handleDocumentFile]);

  return (
    <>
      {loaded ? (
        <>
          {documentFiles.map(
            (documentFile: Record<string, any>, indexDocumentFile: number) => (
              <>
                <MuiGrid key={`DocumentFile-${indexDocumentFile}`} container>
                  <MuiGrid
                    container
                    spacing={1}
                    xs={6}
                    className={classes.marginBottom}
                  >
                    <MuiGrid item xs={12}>
                      <span
                        className={`${classes.label} ${classes.mediumBold}`}
                      >
                        {t(
                          'resource.document_files.field.issuing_agency.label'
                        )}
                      </span>
                      {documentFile.issuing_agency}
                    </MuiGrid>
                    <MuiGrid item xs={12}>
                      <span
                        className={`${classes.label} ${classes.mediumBold}`}
                      >
                        {t('resource.document_files.field.created_at.label')}
                      </span>
                      {getLocaleDateString(documentFile.created_at)}
                    </MuiGrid>
                  </MuiGrid>
                  <MuiGrid container xs={6}>
                    <MuiGrid item xs={12}>
                      <span
                        className={`${classes.label} ${classes.mediumBold}`}
                      >
                        {t('resource.document_files.field.issue_date.label')}
                      </span>
                      {getLocaleDateString(documentFile.issue_date)}
                    </MuiGrid>
                    <MuiGrid item xs={12}>
                      <span
                        className={`${classes.label} ${classes.mediumBold}`}
                      >
                        {t('resource.document_files.field.status.label')}
                      </span>
                      <span
                        className={`${classes[documentFile.status]} ${
                          classes.bold
                        }`}
                      >
                        {t(
                          `resource.document_files.field.status.${documentFile.status}.value`
                        )}
                      </span>
                    </MuiGrid>
                  </MuiGrid>
                  <MuiGrid container spacing={1} xs={12}>
                    <MuiGrid item xs={12}>
                      <span
                        className={`${classes.label} ${classes.mediumBold}`}
                      >
                        {t('resource.document_files.field.attachments.label')}
                      </span>
                      {documentFile.attachments.map(
                        (
                          attachment: Record<string, any>,
                          indexAttachment: number
                        ) => (
                          <>
                            {indexAttachment > 0 && ', '}
                            <a
                              key={`attachment-${indexAttachment}`}
                              href={attachment.src}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {attachment.title}
                            </a>
                          </>
                        )
                      )}
                    </MuiGrid>
                  </MuiGrid>
                </MuiGrid>
                {documentFiles.length > 0 &&
                  documentFiles.length - 1 != indexDocumentFile && (
                    <span className={classes.hr} />
                  )}
              </>
            )
          )}
          {isEmpty(documentFiles) && t('document_files.no_results')}
        </>
      ) : (
        <CircularProgress className={classes.icon} />
      )}
    </>
  );
};

export default DocumentFile;
