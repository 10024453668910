import React, { FunctionComponent } from 'react';
import { FiltersProps } from './types';
import { Filter as RaFilter, TextInput as RaTextInput } from 'react-admin';
import Input from '../Input';

const Filters: FunctionComponent<FiltersProps> = (props) => {
  const { search, filters, ...restProps } = props;
  function renderSearch() {
    if (typeof search === 'undefined' || !search.enabled) {
      return null;
    }

    const label = search?.label || 'resource.search.label';

    return <RaTextInput label={label} source="q" alwaysOn key="search" />;
  }

  function renderFilters() {
    if (typeof filters === 'undefined') {
      return null;
    }

    return [
      renderSearch(),
      ...filters.map((filterProps) => (
        <Input
          {...filterProps}
          source={filterProps.source || filterProps.key}
          key={filterProps.key}
        />
      )),
    ];
  }

  return <RaFilter {...restProps}>{renderFilters()}</RaFilter>;
};
export default Filters;
