import React, { ComponentType, FunctionComponent } from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { first, get } from 'lodash';
import {
  SORT,
  SORT_MODE_DESC,
  SORT_TYPE_ALPHABETIC,
} from '../../constants/extensions';

/**
 * Sort a list of choices
 *
 * @param Component
 * @param params
 */

const useStyles = makeStyles({
  chip: {
    // To make maxWidth dynamic according to the field width
    maxWidth: (config: Record<string, any>) => `${42 * config.width}px`,
  },
});

function withSort(
  Component: ComponentType<any>,
  params: Record<string, any> | undefined
): FunctionComponent {
  const Extension: FunctionComponent<Record<string, any>> = (props) => {
    const t = useTranslate();
    const { choices } = props;
    const extension: Record<string, any> | undefined = first(
      props.child.extensions.filter(
        (extension: Record<string, any>) => extension.key === SORT
      )
    );

    const params = extension !== undefined ? extension.params : {};
    const { type, mode } = params;
    const width = get(props, 'width', 12);
    const classes = useStyles({ width });

    function alphabetic_asc() {
      return choices.sort((a: Record<string, any>, b: Record<string, any>) =>
        a.sub_categories_name_phrase
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase() >
        b.sub_categories_name_phrase
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          ? 1
          : -1
      );
    }

    function alphabetic_desc() {
      return choices.sort((a: Record<string, any>, b: Record<string, any>) =>
        a.sub_categories_name_phrase
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase() <
        b.sub_categories_name_phrase
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          ? 1
          : -1
      );
    }

    const choicesSorted = () => {
      if (type === SORT_TYPE_ALPHABETIC) {
        return mode === SORT_MODE_DESC ? alphabetic_desc() : alphabetic_asc();
      }

      return choices;
    };

    return (
      <Component
        {...props}
        choices={choicesSorted()}
        classes={{ chip: classes.chip }}
      />
    );
  };

  Extension.displayName = `withExtension(Sort)`;

  return Extension;
}

export default withSort;
