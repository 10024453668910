import React, { FunctionComponent } from 'react';
import {
  Admin as RaAdmin,
  Resource as RaResource,
  useTranslate,
  LoginComponent,
} from 'react-admin';
import { isEmpty } from 'lodash';
import { createBrowserHistory as createHistory } from 'history';
import ConfigContext from './contexts/ConfigContext';
import dataProvider from './providers/dataProvider';
import authProvider from './providers/authProvider';
import Edit from './components/Edit';
import { config } from './helpers/config';
import List from './components/List/component';
import LoginFactory from './factories/LoginFactory';
import withExtensions from './extensions/withExtensions';
import Dashboard from './components/Dashboard';
import i18nProvider from './providers/i18nProvider';
import Create from './components/Create';
import Layout from './components/Layout';
import './App.css';

const history = createHistory();

const App: FunctionComponent = () => {
  const t = useTranslate();

  return (
    <ConfigContext.Provider value={config}>
      <RaAdmin
        theme={config.theme}
        history={history}
        dataProvider={dataProvider}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        layout={Layout}
        loginPage={
          LoginFactory.getLoginComponent(config.login) as LoginComponent
        }
        {...{
          ...(!isEmpty(config.dataStudioURL) && {
            dashboard: withExtensions([])(Dashboard),
          }),
        }}
      >
        {config.resources.map((resource) => (
          <RaResource
            key={resource.name}
            name={resource.name}
            options={{ label: t(resource.label) }}
            list={withExtensions(resource.listExtensions)(List)}
            edit={Edit}
            create={Create}
          />
        ))}
      </RaAdmin>
    </ConfigContext.Provider>
  );
};

export default App;
