import { HistoryKey, HistoryUpdatedField } from '../config/types/history';
import { Icon, TranslationElementKey } from '../types';

const USER_CREATED = 'user_created';
const RESET_PASSWORD = 'reset_password';
const COMPANY_INVITATION = 'company_invitation';
const USER_ADMIN_PROMOTED = 'user_admin_promoted';
const AVAILABILITY_UPDATED = 'availability_updated';
const CV_PARSED = 'cv_parsed';
const RECOMMENDATION_PLATFORM_REQUESTED = 'recommendation_platform_requested';
const RECOMMENDATION_PLATFORM_PUBLISHED = 'recommendation_platform_published';
const COMPANY_UPDATED = 'company_updated';
const NEW_SEARCH = 'new_search';
const MATCHING_MISSION = 'matching_mission';
const MISSION_INVITATION = 'mission_invitation';
const CANDIDACY_CREATED = 'candidacy_created';
const CANDIDACY_ACCEPTED = 'candidacy_accepted';
const CANDIDACY_MOVE_STEP_TWO = 'candidacy_move_step_two';
const CANDIDACY_MOVE_STEP_THREE = 'candidacy_move_step_three;';
const CANDIDACY_MOVE_STEP_FOUR = 'candidacy_move_step_four';
const CHANNEL_USER_JOIN = 'channel_user_join';
const MESSAGE_CREATE = 'message_create';
const NOTE_CREATE = 'note_create';
const CANDIDACY_ARCHIVED = 'candidacy_archived';
const MISSION_CREATED = 'mission_created';
const MISSION_UPDATED = 'mission_updated';
const MISSION_PUBLISHED = 'mission_published';
const MISSION_ARCHIVED_CLIENT = 'mission_archived_client';
const MISSION_SIGN_NDA = 'mission_sign_nda';
const MISSION_BLACKLISTED = 'mission_blacklisted';
const MISSION_CANDIDATE = 'mission_candidate';
const MISSION_INVITATION_REFUSED = 'mission_invitation_refused';
const POST_SIGNUP_FINISHED = 'post_signup_finished';
const USER_SIGNED_IN = 'user_signed_in';
const USER_CONFIRMED = 'user_confirmed';
const NOTE = 'note';

// https://dev.to/angular/managing-key-value-constants-in-typescript-221g
export const historyKeys = [
  USER_CREATED,
  RESET_PASSWORD,
  COMPANY_INVITATION,
  USER_ADMIN_PROMOTED,
  AVAILABILITY_UPDATED,
  CV_PARSED,
  RECOMMENDATION_PLATFORM_REQUESTED,
  RECOMMENDATION_PLATFORM_PUBLISHED,
  NEW_SEARCH,
  COMPANY_UPDATED,
  MATCHING_MISSION,
  MISSION_INVITATION,
  CANDIDACY_CREATED,
  CANDIDACY_ACCEPTED,
  CANDIDACY_MOVE_STEP_TWO,
  CANDIDACY_MOVE_STEP_THREE,
  CANDIDACY_MOVE_STEP_FOUR,
  CHANNEL_USER_JOIN,
  MESSAGE_CREATE,
  NOTE_CREATE,
  CANDIDACY_ARCHIVED,
  MISSION_CREATED,
  MISSION_UPDATED,
  MISSION_PUBLISHED,
  MISSION_ARCHIVED_CLIENT,
  MISSION_SIGN_NDA,
  MISSION_BLACKLISTED,
  MISSION_CANDIDATE,
  MISSION_INVITATION_REFUSED,
  POST_SIGNUP_FINISHED,
  USER_SIGNED_IN,
  USER_CONFIRMED,
  NOTE,
] as const;

// MUI v4 Icons, here https://v4.mui.com/components/material-icons/

export const historyIcons: Record<HistoryKey, Icon> = {
  [USER_CREATED]: 'Add',
  [RESET_PASSWORD]: 'VisibilityOff', // TODO find a valid icon for password, 'Password' is not compatible with the version 4 of Mui
  [COMPANY_INVITATION]: 'PersonAdd',
  [USER_ADMIN_PROMOTED]: 'SupervisorAccount',
  [AVAILABILITY_UPDATED]: 'Update',
  [CV_PARSED]: 'FindInPageOutlined',
  [RECOMMENDATION_PLATFORM_REQUESTED]: 'NoteAdd',
  [RECOMMENDATION_PLATFORM_PUBLISHED]: 'Description',
  [NEW_SEARCH]: 'Search',
  [COMPANY_UPDATED]: 'Edit',
  [MATCHING_MISSION]: 'Contacts', // TODO find a valid icon for matching mission, 'ConnectWithoutContact' is not compatible with the version 4 of Mui
  [MISSION_INVITATION]: 'EmojiPeople',
  [CANDIDACY_CREATED]: 'Add',
  [CANDIDACY_ACCEPTED]: 'CheckCircle',
  [CANDIDACY_MOVE_STEP_TWO]: 'DateRange', // TODO find a valid icon for candidacy move, 'CalendarViewWeek' is not compatible with the version 4 of Mui
  [CANDIDACY_MOVE_STEP_THREE]: 'DateRange', // TODO find a valid icon for candidacy move, 'CalendarViewWeek' is not compatible with the version 4 of Mui
  [CANDIDACY_MOVE_STEP_FOUR]: 'DateRange', // TODO find a valid icon for candidacy move, 'CalendarViewWeek' is not compatible with the version 4 of Mui
  [CHANNEL_USER_JOIN]: 'AddComment',
  [MESSAGE_CREATE]: 'Comment',
  [NOTE_CREATE]: 'Comment',
  [CANDIDACY_ARCHIVED]: 'Archive',
  [MISSION_CREATED]: 'Add',
  [MISSION_UPDATED]: 'Edit',
  [MISSION_PUBLISHED]: 'Description',
  [MISSION_ARCHIVED_CLIENT]: 'Archive',
  [MISSION_SIGN_NDA]: 'BorderColor', // TODO find a valid icon for mission sign nda, 'DriveFileRenameOutline' is not compatible with the version 4 of Mui
  [MISSION_BLACKLISTED]: 'ExitToAppOutlined', // TODO find a valid icon for mission blacklisted, 'Logout' is not compatible with the version 4 of Mui
  [MISSION_CANDIDATE]: 'DateRange', // TODO find a valid icon for mission candidate, 'CalendarViewWeek' is not compatible with the version 4 of Mui
  [MISSION_INVITATION_REFUSED]: 'NotInterested',
  [POST_SIGNUP_FINISHED]: 'ContactMail',
  [USER_SIGNED_IN]: 'PowerSettingsNew',
  [USER_CONFIRMED]: 'Check',
  [NOTE]: 'Chat',
} as const;

// Type of details args of childs fields updated
export const UPDATED_FIELD_TEXT = 'text';
export const UPDATED_FIELD_RANGE = 'range';
export const UPDATED_FIELD_TAG = 'tag';
export const UPDATED_FIELD_ARRAY = 'array';
export const UPDATED_FIELD_DATE = 'date';
export const UPDATED_FIELD_BOOL = 'bool';
export const UPDATED_FIELD_WYSIWYG = 'wysiwyg';

// Translation of content fields
// Company
// CREATE case (with creation keys)
const TRANSLATION_KEY_COMPANY_LANGUAGE_NAME_CREATED =
  'history.language.field_created.name';
const TRANSLATION_KEY_COMPANY_LANGUAGE_LEVEL_CREATED =
  'history.language.field_created.level';
// UPDATE case (with update keys)
const TRANSLATION_KEY_COMPANY_AVAILABILITY_UPDATED =
  'history.company.field_updated.availability';
const TRANSLATION_KEY_COMPANY_SECTORS_UPDATED =
  'history.company.field_updated.sectors';
const TRANSLATION_KEY_COMPANY_SKILLS_UPDATED =
  'history.company.field_updated.skills';
const TRANSLATION_KEY_COMPANY_SUB_CATEGORIES_UPDATED =
  'history.company.field_updated.sub_categories';
const TRANSLATION_KEY_COMPANY_SEARCH_STATUS_UPDATED =
  'history.company.field_updated.search_status';
const TRANSLATION_KEY_COMPANY_CONTRACT_TYPES_UPDATED =
  'history.company.field_updated.contract_types';
const TRANSLATION_KEY_COMPANY_KIND_UPDATED =
  'history.company.field_updated.kind';
const TRANSLATION_KEY_COMPANY_BILLINGS_UPDATED =
  'history.company.field_updated.billings';
const TRANSLATION_KEY_COMPANY_MODALITIES_UPDATED =
  'history.company.field_updated.modalities';
const TRANSLATION_KEY_COMPANY_SIZE_UPDATED =
  'history.company.field_updated.size';
const TRANSLATION_KEY_COMPANY_LANGUAGE_NAME_UPDATED =
  'history.language.field_updated.name';
const TRANSLATION_KEY_COMPANY_LANGUAGE_LEVEL_UPDATED =
  'history.language.field_updated.level';
const TRANSLATION_KEY_COMPANY_REWARDS_UPDATED =
  'history.company.field_updated.rewards';

// Translation of content fields
// Mission
// CREATE case (with creation keys)

// UPDATE case (with update keys)
const TRANSLATION_KEY_MISSION_CONTRACT_TYPES_UPDATED =
  'history.mission.field_updated.contract_types';
const TRANSLATION_KEY_MISSION_MOBILITY_UPDATED =
  'history.mission.field_updated.modalities';
const TRANSLATION_KEY_MISSION_SUB_CATEGORIES_UPDATED =
  'history.mission.field_updated.sub_categories';
const TRANSLATION_KEY_MISSION_LANGUAGES_ALPHA2 =
  'history.mission.field_updated.languages_alpha2';
const TRANSLATION_KEY_MISSION_OPTIONAL_LANGUAGES_ALPHA2 =
  'history.mission.field_updated.optional_languages_alpha2';
const TRANSLATION_KEY_MISSION_BILLINGS_UPDATED =
  'history.mission.field_updated.billings';
const TRANSLATION_KEY_MISSION_ARCHIVED_KIND_UPDATED =
  'history.mission.field_updated.archived_kind';
const TRANSLATION_KEY_MISSION_BILLINGS_METHOD_UPDATED =
  'history.mission.field_updated.billing_method';

// Translation of content fields
// Candidacy
// CREATE case (with creation keys)
const TRANSLATION_KEY_CANDIDACY_ARCHIVED_KIND_CREATED =
  'history.candidacy.field_created.archived_kind';

// UPDATE case (with update keys)
export const historyUpdatedField = [
  TRANSLATION_KEY_CANDIDACY_ARCHIVED_KIND_CREATED,
  TRANSLATION_KEY_COMPANY_AVAILABILITY_UPDATED,
  TRANSLATION_KEY_COMPANY_SECTORS_UPDATED,
  TRANSLATION_KEY_COMPANY_SKILLS_UPDATED,
  TRANSLATION_KEY_COMPANY_SUB_CATEGORIES_UPDATED,
  TRANSLATION_KEY_COMPANY_SEARCH_STATUS_UPDATED,
  TRANSLATION_KEY_COMPANY_CONTRACT_TYPES_UPDATED,
  TRANSLATION_KEY_COMPANY_KIND_UPDATED,
  TRANSLATION_KEY_COMPANY_BILLINGS_UPDATED,
  TRANSLATION_KEY_COMPANY_MODALITIES_UPDATED,
  TRANSLATION_KEY_COMPANY_SIZE_UPDATED,
  TRANSLATION_KEY_COMPANY_LANGUAGE_NAME_CREATED,
  TRANSLATION_KEY_COMPANY_LANGUAGE_LEVEL_CREATED,
  TRANSLATION_KEY_COMPANY_LANGUAGE_NAME_UPDATED,
  TRANSLATION_KEY_COMPANY_LANGUAGE_LEVEL_UPDATED,
  TRANSLATION_KEY_MISSION_CONTRACT_TYPES_UPDATED,
  TRANSLATION_KEY_MISSION_MOBILITY_UPDATED,
  TRANSLATION_KEY_MISSION_SUB_CATEGORIES_UPDATED,
  TRANSLATION_KEY_MISSION_BILLINGS_METHOD_UPDATED,
  TRANSLATION_KEY_COMPANY_REWARDS_UPDATED,
] as const;

export const historyTranslateFields: Record<
  HistoryUpdatedField | string,
  TranslationElementKey
> = {
  [TRANSLATION_KEY_COMPANY_AVAILABILITY_UPDATED]: {
    translation_prefix: 'resource.companies.field.availability.choices',
    translation_suffix: 'label',
  },
  [TRANSLATION_KEY_COMPANY_SECTORS_UPDATED]: {
    translation_prefix: 'resource.companies.field.sectors.choices',
    translation_suffix: 'label',
  },
  [TRANSLATION_KEY_COMPANY_SUB_CATEGORIES_UPDATED]: {
    translation_prefix: 'sub_categories_name',
  },
  [TRANSLATION_KEY_COMPANY_SEARCH_STATUS_UPDATED]: {
    translation_prefix: 'resource.companies.field.search_status.choices',
    translation_suffix: 'label',
  },
  [TRANSLATION_KEY_COMPANY_CONTRACT_TYPES_UPDATED]: {
    translation_prefix: 'resource.companies.field.contract_types.choices',
    translation_suffix: 'label',
  },
  [TRANSLATION_KEY_COMPANY_KIND_UPDATED]: {
    translation_prefix: 'resource.companies.field.kind.choices',
    translation_suffix: 'label',
  },
  [TRANSLATION_KEY_COMPANY_BILLINGS_UPDATED]: {
    translation_prefix: 'resource.companies.field.billings.choices',
    translation_suffix: 'label',
  },
  [TRANSLATION_KEY_COMPANY_MODALITIES_UPDATED]: {
    translation_prefix: 'resource.companies.field.modalities.choices',
    translation_suffix: 'label',
  },
  [TRANSLATION_KEY_COMPANY_SIZE_UPDATED]: {
    translation_prefix: 'resource.companies.field.size.choices',
    translation_suffix: 'label',
  },
  [TRANSLATION_KEY_COMPANY_LANGUAGE_NAME_CREATED]: {
    translation_prefix: 'fields.languages',
  },
  [TRANSLATION_KEY_COMPANY_LANGUAGE_LEVEL_CREATED]: {
    translation_prefix: 'resource.users.field.language_level',
  },
  [TRANSLATION_KEY_COMPANY_LANGUAGE_NAME_UPDATED]: {
    translation_prefix: 'fields.languages',
  },
  [TRANSLATION_KEY_COMPANY_LANGUAGE_LEVEL_UPDATED]: {
    translation_prefix: 'resource.users.field.language_level',
  },
  [TRANSLATION_KEY_MISSION_CONTRACT_TYPES_UPDATED]: {
    translation_prefix: 'resource.missions.field.contract_types',
  },
  [TRANSLATION_KEY_MISSION_MOBILITY_UPDATED]: {
    translation_prefix: 'resource.companies.field.modalities.choices',
    translation_suffix: 'label',
  },
  [TRANSLATION_KEY_MISSION_SUB_CATEGORIES_UPDATED]: {
    translation_prefix: 'sub_categories_name',
  },
  [TRANSLATION_KEY_MISSION_LANGUAGES_ALPHA2]: {
    translation_prefix: 'fields.languages',
  },
  [TRANSLATION_KEY_MISSION_OPTIONAL_LANGUAGES_ALPHA2]: {
    translation_prefix: 'fields.languages',
  },
  [TRANSLATION_KEY_MISSION_BILLINGS_UPDATED]: {
    translation_prefix: 'resource.companies.field.billings.choices',
    translation_suffix: 'label',
  },
  [TRANSLATION_KEY_MISSION_BILLINGS_METHOD_UPDATED]: {
    translation_prefix: 'resource.companies.field.billings_method.choices',
    translation_suffix: 'label',
  },
  [TRANSLATION_KEY_MISSION_ARCHIVED_KIND_UPDATED]: {
    translation_prefix: 'resource.missions.archive.kind',
  },
  [TRANSLATION_KEY_CANDIDACY_ARCHIVED_KIND_CREATED]: {
    translation_prefix: 'resource.candidacies.field.archived_kind',
  },
  [TRANSLATION_KEY_COMPANY_REWARDS_UPDATED]: {
    translation_prefix: 'resource.companies.field.rewards.choices',
  },
};

// EXTRA TAG TYPES
export const AFTER_CONTENT_ICON = 'after_content_icon';
