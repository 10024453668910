import { resolveBrowserLocale } from 'react-admin';
import raFr from 'ra-language-french';
import raEn from 'ra-language-english';
import fr from '../i18n/static/fr.json';
import en from '../i18n/static/en.json';
import RaPolyglotI18nProvider from 'ra-i18n-polyglot';
import { config } from '../helpers/config';
import { ObjectOf } from '../types';
import { TranslationMessages } from 'ra-core';
import withTranslate from '../extensions/translation/withTranslate';

const defaultLocale = config.locale || resolveBrowserLocale();
const translationsMap: ObjectOf<TranslationMessages> = {
  fr: { ...raFr, ...fr },
  en: { ...raEn, ...en },
};

const parseKeys = (locale: string): TranslationMessages =>
  translationsMap[locale] as TranslationMessages;

const i18nProvider = RaPolyglotI18nProvider(
  (locale: string): TranslationMessages => parseKeys(locale),
  defaultLocale,
  {
    // To avoid multiple warning for missing translation
    // especially for TranslatedTextField, when values are not keys for translations
    allowMissing: true,
  }
);

export default {
  ...i18nProvider,
  translate: (key: string, option: any): any =>
    withTranslate(key, option, i18nProvider),
};
