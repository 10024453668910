import { Resource as ResourceType } from '../types';

export const partnerMission: ResourceType = {
  name: 'partner_missions',
  label: 'resource.missions.label',
  hideResource: true,
  attributes: [
    {
      key: 'id',
      label: 'resource.missions.field.id.label',
      type: 'text',
    },
    {
      key: 'attachments',
      label: 'resource.missions.field.attachments.label',
      type: 'buttons',
    },
    {
      key: 'begin',
      label: 'resource.missions.field.begin.label',
      type: 'select',
      choices: [
        {
          id: 'asap',
          name: 'resource.missions.field.begin.choices.asap.label',
        },
        {
          id: 'precise',
          name: 'resource.missions.field.begin.choices.precise.label',
        },
        {
          id: 'unknown',
          name: 'resource.missions.field.begin.choices.unknown.label',
        },
      ],
    },
    {
      key: 'begin_at',
      label: 'resource.missions.field.begin_at.label',
      type: 'date',
    },
    {
      key: 'billings',
      label: 'resource.companies.field.billings.label',
      type: 'select',
      choices: [
        {
          id: 'technical_assistance',
          name: 'resource.companies.field.billings.choices.technical_assistance.label',
        },
        {
          id: 'forfeit',
          name: 'resource.companies.field.billings.choices.forfeit.label',
        },
      ],
    },
    {
      key: 'budget',
      label: 'resource.missions.field.budget.label',
      type: 'range',
    },
    {
      key: 'company_confidentiality',
      label: 'resource.missions.field.confidentiality.label',
      type: 'boolean',
    },
    {
      key: 'company_id',
      label: 'resource.missions.field.company_id.label',
      type: 'reference',
      reference: 'companies',
    },
    {
      key: 'company_kinds',
      label: 'resource.missions.field.company_kinds.label',
      type: 'multipleSelect',
      choices: [
        {
          id: 'freelance',
          name: 'resource.companies.field.kind.choices.freelance.label',
        },
        {
          id: 'design_office',
          name: 'resource.companies.field.kind.choices.design_office.label',
        },
        {
          id: 'agency',
          name: 'resource.companies.field.kind.choices.agency.label',
        },
        {
          id: 'laboratory',
          name: 'resource.companies.field.kind.choices.laboratory.label',
        },
        {
          id: 'junior_company',
          name: 'resource.companies.field.kind.choices.junior_company.label',
        },
        {
          id: 'startup',
          name: 'resource.companies.field.kind.choices.startup.label',
        },
        {
          id: 'esn',
          name: 'resource.companies.field.kind.choices.esn.label',
        },
        {
          id: 'fablab',
          name: 'resource.companies.field.kind.choices.fablab.label',
        },
        {
          id: 'makerspace',
          name: 'resource.companies.field.kind.choices.makerspace.label',
        },
        {
          id: 'recruitment_candidate',
          name: 'resource.companies.field.kind.choices.recruitment_candidate.label',
        },
        {
          id: 'unknown',
          name: 'resource.companies.field.kind.choices.unknown.label',
        },
      ],
    },
    {
      key: 'company_level',
      label: 'resource.missions.field.company_level.label',
      type: 'range',
    },
    {
      key: 'partner_name',
      label: 'resource.missions.field.partner_name.label',
      type: 'select',
      choices: [
        {
          id: 'adzuna',
          name: 'resource.companies.field.sectors.choices.adzuna.label',
        },
        {
          id: 'aerocontact',
          name: 'resource.companies.field.sectors.choices.aerocontact.label',
        },
        {
          id: 'energie_recrute',
          name: 'resource.companies.field.sectors.choices.energie_recrute.label',
        },
        {
          id: 'uimm',
          name: 'resource.companies.field.sectors.choices.uimm.label',
        },
      ],
    },
    {
      key: 'context_description',
      label: 'resource.missions.field.context_description.label',
      type: 'wysiwyg',
    },
    {
      key: 'name',
      label: 'resource.missions.field.name.label',
      type: 'text',
    },
    {
      key: 'company_name',
      label: 'resource.missions.field.company_id.label',
      type: 'text',
    },
    {
      key: 'contract_types',
      label: 'resource.missions.field.contract_types.label',
      type: 'select',
      choices: [
        {
          id: 'fulltime_contract',
          name: 'resource.companies.field.contract_types.choices.fulltime_contract.label',
        },
        {
          id: 'interim',
          name: 'resource.companies.field.contract_types.choices.interim.label',
        },
        {
          id: 'internship',
          name: 'resource.companies.field.contract_types.choices.internship.label',
        },
        {
          id: 'missions',
          name: 'resource.companies.field.contract_types.choices.missions.label',
        },
        {
          id: 'missions_or_fulltime_contract',
          name: 'resource.companies.field.contract_types.choices.missions_or_fulltime_contract.label',
        },
        {
          id: 'professional_training_contract',
          name: 'resource.companies.field.contract_types.choices.professional_training_contract.label',
        },
        {
          id: 'temporary_contract',
          name: 'resource.companies.field.contract_types.choices.temporary_contract.label',
        },
        {
          id: 'thesis',
          name: 'resource.companies.field.contract_types.choices.thesis.label',
        },
      ],
    },
    {
      key: 'created_at',
      label: 'resource.missions.field.created_at.label',
      type: 'date',
    },
    {
      key: 'daily_rate',
      label: 'resource.missions.field.daily_rate.label',
      type: 'range',
    },
    {
      key: 'deliverables',
      label: 'resource.missions.field.deliverables.label',
      type: 'wysiwyg',
    },
    {
      key: 'description',
      label: 'resource.missions.field.description.label',
      type: 'wysiwyg',
    },
    {
      key: 'end',
      label: 'resource.missions.field.end.label',
      type: 'select',
      choices: [
        {
          id: 'approximative',
          name: 'resource.missions.field.end.choices.approximative.label',
        },
        {
          id: 'precise',
          name: 'resource.missions.field.end.choices.precise.label',
        },
        {
          id: 'unknown',
          name: 'resource.missions.field.end.choices.unknown.label',
        },
      ],
    },
    {
      key: 'end_at',
      label: 'resource.missions.field.end_at.label',
      type: 'date',
    },
    {
      key: 'end_months',
      label: 'resource.missions.field.end_months.label',
      type: 'number',
    },
    {
      key: 'goals',
      label: 'resource.missions.field.goals.label',
      type: 'wysiwyg',
    },
    {
      key: 'location',
      type: 'object',
      shape: [
        {
          key: 'address',
          type: 'text',
          label: 'resource.missions.field.location.address.label',
        },
      ],
    },
    {
      key: 'modalities',
      label: 'resource.companies.field.modalities.label',
      type: 'select',
      choices: [
        {
          id: 'remote',
          name: 'resource.companies.field.modalities.choices.remote.label',
        },
        {
          id: 'remote_can_move',
          name: 'resource.companies.field.modalities.choices.remote_can_move.label',
        },
        {
          id: 'on_site',
          name: 'resource.companies.field.modalities.choices.on_site.label',
        },
      ],
    },
    {
      key: 'name',
      label: 'resource.missions.field.name.label',
      type: 'text',
    },
    {
      key: 'nda',
      label: 'resource.missions.field.nda.label',
      type: 'boolean',
    },
    {
      key: 'nda_attachment',
      label: 'resource.missions.field.nda_attachments.label',
      type: 'buttons',
    },
    {
      key: 'owner_id',
      label: 'resource.missions.field.owner_id.label',
      type: 'reference',
      reference: 'users',
    },
    {
      key: 'salary',
      label: 'resource.missions.field.salary.label',
      type: 'range',
    },
    {
      key: 'sectors',
      label: 'resource.companies.field.sectors.label',
      type: 'multipleSelect',
      choices: [
        {
          id: 'automative',
          name: 'resource.companies.field.sectors.choices.automative.label',
        },
        {
          id: 'aeronautics',
          name: 'resource.companies.field.sectors.choices.aeronautics.label',
        },
        {
          id: 'space',
          name: 'resource.companies.field.sectors.choices.space.label',
        },
        {
          id: 'defense',
          name: 'resource.companies.field.sectors.choices.defense.label',
        },
        {
          id: 'railway',
          name: 'resource.companies.field.sectors.choices.railway.label',
        },
        {
          id: 'telecoms',
          name: 'resource.companies.field.sectors.choices.telecoms.label',
        },
        {
          id: 'energy',
          name: 'resource.companies.field.sectors.choices.energy.label',
        },
        {
          id: 'services_and_information_systems',
          name: 'resource.companies.field.sectors.choices.services_and_information_systems.label',
        },
        {
          id: 'oil_and_gas',
          name: 'resource.companies.field.sectors.choices.oil_and_gas.label',
        },
        {
          id: 'naval',
          name: 'resource.companies.field.sectors.choices.naval.label',
        },
        {
          id: 'machining_or_heavy_industry',
          name: 'resource.companies.field.sectors.choices.machining_or_heavy_industry.label',
        },
        {
          id: 'bioengineering',
          name: 'resource.companies.field.sectors.choices.bioengineering.label',
        },
        {
          id: 'bank_insurance',
          name: 'resource.companies.field.sectors.choices.bank_insurance.label',
        },
        {
          id: 'infrastructure',
          name: 'resource.companies.field.sectors.choices.infrastructure.label',
        },
        {
          id: 'digital',
          name: 'resource.companies.field.sectors.choices.digital.label',
        },
      ],
    },
    {
      key: 'skills_name',
      label: 'resource.missions.field.skills_name.label',
      type: 'array',
      itemSource: 'name',
    },
    {
      key: 'status',
      label: 'resource.missions.field.status.label',
      type: 'select',
      choices: [
        {
          id: 'draft',
          name: 'resource.missions.field.status.choices.draft.label',
        },
        {
          id: 'published',
          name: 'resource.missions.field.status.choices.published.label',
        },
        {
          id: 'review',
          name: 'resource.missions.field.status.choices.review.label',
        },
        {
          id: 'wip',
          name: 'resource.missions.field.status.choices.wip.label',
        },
        {
          id: 'archived',
          name: 'resource.missions.field.status.choices.archived.label',
        },
        {
          id: 'finished',
          name: 'resource.missions.field.status.choices.finished.label',
        },
      ],
    },
    {
      key: 'sub_categories_id',
      label: 'resource.companies.field.sub_categories_name.label',
      type: 'referenceArray',
      reference: 'sub_categories',
    },
    {
      key: 'updated_at',
      label: 'resource.missions.field.updated_at.label',
      type: 'date',
    },
    {
      key: 'validated',
      label: 'resource.missions.field.validated.label',
      type: 'boolean',
    },
    {
      key: 'validated_at',
      label: 'resource.missions.field.validated_at.label',
      type: 'date',
    },
    {
      key: 'vip',
      label: 'resource.missions.field.vip.label',
      type: 'boolean',
    },
    {
      key: 'work_days',
      label: 'resource.missions.field.work_days.label',
      type: 'number',
    },
    {
      key: 'applicants_number',
      label: 'resource.missions.field.applicants_number.label',
      type: 'number',
    },
    {
      key: 'outsourcing',
      label: 'resource.missions.field.outsourcing.label',
      type: 'boolean',
    },
    {
      key: 'archived_kind',
      label: 'resource.missions.field.archived_kind.label',
      type: 'select',
      choices: [
        {
          id: 'won',
          name: 'resource.missions.field.archived_kind.choices.won.label',
        },
        {
          id: 'cancelled_by_client',
          name: 'resource.missions.field.archived_kind.choices.cancelled_by_client.label',
        },
        {
          id: 'cancelled_by_owner',
          name: 'resource.missions.field.archived_kind.choices.cancelled_by_owner.label',
        },
        {
          id: 'staffed_by_competitor',
          name: 'resource.missions.field.archived_kind.choices.staffed_by_competitor.label',
        },
        {
          id: 'no_news_from_client',
          name: 'resource.missions.field.archived_kind.choices.no_news_from_client.label',
        },
      ],
    },
    {
      key: 'archived_text',
      label: 'resource.missions.field.archived_text.label',
      type: 'text',
    },
    {
      key: 'partner_company_name',
      label: 'resource.missions.field.partner_company_name.label',
      type: 'text',
    },
  ],
  pages: {
    list: {
      hasEditButton: true,
      editAndCreateBasePath: '/missions',
      search: {
        enabled: true,
        label: 'filter.search.label',
      },
      filters: [
        {
          source: 'status',
          type: 'multipleSelect',
        },
        {
          source: 'partner_name',
          type: 'multipleSelect',
          optionsFilter: {
            className: 'multipleSelectFilter',
          },
        },
        {
          source: 'contract_types',
          type: 'multipleSelect',
          optionsFilter: {
            className: 'multipleSelectFilter',
          },
        },
      ],
      fields: [
        {
          source: 'owner_id',
          // Reference fields need children to display
          label: 'resource.missions.field.partner_company_name.label',
          child: {
            source: 'users.company_name',
          },
        },
        {
          source: 'partner_name',
        },
        {
          source: 'name',
          sortable: false,
        },
        {
          source: 'contract_types',
          type: 'array',
          translationPrefix: 'resource.companies.field.contract_types.choices',
        },
        {
          source: 'status',
        },
        {
          source: 'daily_rate',
        },
        {
          source: 'salary',
        },
        {
          source: 'validated_at',
        },
        {
          source: 'created_at',
        },
      ],
      bulkActionButtons: [
        {
          name: 'transfer',
          type: 'modal',
          label: 'resource.missions.bulk_action_buttons.transfer.label',
          extraUrl: '/transfer',
          confirmation: {
            title: 'modal.select_company.transfer_project',
          },
          modal: {
            inputs: {
              company_id: {
                source: 'companies',
                filter: { user_kind: 'client_or_mixed' },
                label: 'modal.select_company.label',
                child: {
                  type: 'autocomplete',
                  optionText: 'name',
                },
              },
            },
          },
        },
        {
          name: 'accept',
          type: 'update',
          label: 'resource.missions.bulk_action_buttons.accept.label',
          values: {
            validated: true,
          },
        },
        {
          name: 'archive',
          type: 'update',
          label: 'resource.missions.bulk_action_buttons.archive.label',
          values: {
            archived: true,
          },
        },
      ],
    },
    edit: {
      left: {
        tabs: [],
      },
      right: [],
    },
  },
};
