import React, { FunctionComponent } from 'react';
import { ArrayFieldProps } from './types';
import { useTranslate } from 'react-admin';
import { Chip as MuiChip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import getSubAttribute from '../../helpers/getSubAttribute';

const useStyles = makeStyles({
  root: {
    marginTop: '10px',
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
    '& > *': {
      margin: '2px',
    },
  },
});

const ArrayField: FunctionComponent<ArrayFieldProps> = (props) => {
  const { record, source, itemSource, translationPrefix } = props;
  const classes = useStyles();
  const t = useTranslate();
  if (!record || !source) return null;

  function renderArrayField() {
    if (typeof record === 'undefined') return null;
    const subAttributes = getSubAttribute(record, source);

    if (!subAttributes || !Array.isArray(subAttributes)) return null;

    return subAttributes.map((v: any) => {
      const itemValue = typeof v === 'string' ? v : v[itemSource];

      return (
        <MuiChip
          key={itemValue}
          label={
            translationPrefix
              ? t(`${translationPrefix}.${itemValue}`)
              : itemValue
          }
        />
      );
    });
  }

  return (
    <div className={props.className || classes.root}>{renderArrayField()}</div>
  );
};

export default ArrayField;
