import { Resource as ResourceType } from '../types';

export const documentsCompanies: ResourceType = {
  name: 'documents_companies',
  label: 'resource.documents.label',
  hideResource: true,
  attributes: [
    {
      key: 'status',
      label: 'resource.documents.field.status.label',
      type: 'select',
      choices: [
        {
          id: 'pending',
          name: 'resource.documents.field.status.pending.value',
        },
        {
          id: 'accepted',
          name: 'resource.documents.field.status.accepted.value',
        },
        {
          id: 'refused',
          name: 'resource.documents.field.status.refused.value',
        },
        {
          id: 'not_provided',
          name: 'resource.documents.field.status.not_provided.label',
        },
        {
          id: 'archived',
          name: 'resource.documents.field.status.archived.label',
        },
      ],
    },
    {
      key: 'document_id',
      label: 'resource.documents.field.id.label',
      type: 'text',
    },
    {
      key: 'document_condition',
      label: 'resource.documents.field.condition.label',
      type: 'select',
      choices: [
        {
          id: 'optional',
          name: 'resource.documents.field.condition.choices.optional.label',
        },
        {
          id: 'mandatory_to_apply',
          name: 'resource.documents.field.condition.choices.mandatory_to_apply.label',
        },
        {
          id: 'mandatory_to_send_proposal',
          name: 'resource.documents.field.condition.choices.mandatory_to_send_proposal.label',
        },
      ],
    },
    {
      key: 'document_name',
      label: 'resource.documents.field.name.label',
      type: 'text',
    },
    {
      key: 'document_kind_name',
      label: 'resource.documents.field.kind_name.label',
      type: 'text',
    },
    {
      key: 'company_leader_first_name',
      label: 'resource.users.field.first_name.label',
      type: 'text',
    },
    {
      key: 'company_leader_last_name',
      label: 'resource.users.field.last_name.label',
      type: 'text',
    },
    {
      key: 'company_leader_company_name',
      label: 'resource.users.field.company_name.label',
      type: 'text',
    },
    {
      key: 'company_leader_job_name',
      label: 'resource.users.field.job_name.label',
      type: 'text',
    },
    {
      key: 'company_leader_id',
      label: 'resource.users.field.full_name.label',
      type: 'reference',
      reference: 'users',
    },
  ],
};
