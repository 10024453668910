import React, { FunctionComponent, createElement } from 'react';
import {
  Card as MuiCard,
  Typography as MuiTypography,
  Box as MuiBox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KpiCardsProps } from './types';

const useStyles = makeStyles({
  card: {
    minHeight: 52,
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
    },
    margin: 10,
  },
  main: {
    overflow: 'inherit',
    padding: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const KpiCard: FunctionComponent<KpiCardsProps> = (props) => {
  const { icon, title, subtitle } = props;
  const classes = useStyles();

  return (
    <MuiCard className={classes.card}>
      <div className={classes.main}>
        {icon && (
          <MuiBox width="3em">
            {createElement(icon, { fontSize: 'large' })}
          </MuiBox>
        )}
        <MuiBox textAlign="right">
          <MuiTypography color="textSecondary">{title}</MuiTypography>
          <MuiTypography variant="h5" component="h2">
            {/* Blank space is here to keep styles clean */}
            {`${subtitle}` || ' '}
          </MuiTypography>
        </MuiBox>
      </div>
    </MuiCard>
  );
};

export default KpiCard;
