import React, { useState, FunctionComponent } from 'react';
import Slider from '@material-ui/core/Slider';
import { useForm, useFormState } from 'react-final-form';
import { RangeInputProps } from './types';
import { useTranslate } from 'react-admin';

const RangeInput: FunctionComponent<RangeInputProps> = (props) => {
  const { source, label, options, disabled } = props;
  const { min, max, step } = options;
  const { values } = useFormState();
  const form = useForm();
  const t = useTranslate();

  const initialValues = (formValues: string) => {
    // if there is no value, the slider value's array is set to the extremities
    if (!formValues) {
      return [min, max];
    }

    return formValues.split('..').map((x) => parseInt(x));
  };

  const [value, setValue] = useState<number[]>(() =>
    initialValues(values[props.source])
  );

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number | number[]
  ) => {
    setValue(newValue as number[]);
    form.change(source, newValue);
  };

  function transformValue(value: number) {
    return `${value}`;
  }

  return (
    // TODO: Modify style when design is ready
    <div>
      <span>{t(`${label}`)}</span>
      <Slider
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        getAriaValueText={transformValue}
        min={min}
        max={max}
        step={step}
        disabled={disabled}
      />
    </div>
  );
};

export default RangeInput;
