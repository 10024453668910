import { isEmpty } from 'lodash';
import React, { FunctionComponent } from 'react';
import { useTranslate } from 'react-admin';
import { translationField } from '../../../../../../helpers/history';
import { TextFieldProps } from './type';

const TextField: FunctionComponent<TextFieldProps> = (props) => {
  const t = useTranslate();
  const { details } = props;

  const before =
    details.args !== undefined && !isEmpty(details.args.before)
      ? translationField(details.args.before, details.translation_key)
      : t('history.company.field_updated.no_tags');
  const after =
    details.args !== undefined && !isEmpty(details.args.after)
      ? translationField(details.args.after, details.translation_key)
      : t('history.company.field_updated.no_tags');

  return (
    <span>{t(`${details.translation_key}.details`, { before, after })}</span>
  );
};

export default TextField;
