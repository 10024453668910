import { resolveBrowserLocale } from 'react-admin';
import { config } from './config';

const defaultLocale = config.locale || resolveBrowserLocale();

/**
 *
 * @param {string} date
 * @returns {string} Output example: 01/10/2018
 */
export const getLocaleDateString = (date: string) => {
  const currentDate = new Date(date);

  return currentDate.toLocaleDateString(defaultLocale);
};

/**
 *
 * @param {string} date
 * @returns {string} Output example: 15:26
 */
export const getLocaleTimeString = (date: string) => {
  const currentDate = new Date(date);

  return currentDate.toLocaleTimeString(defaultLocale, { timeStyle: 'short' });
};
