import React, { createElement, FunctionComponent, useState } from 'react';
import {
  Link as MuiLink,
  Typography as MuiTypography,
  makeStyles,
  Tooltip as MuiTooltip,
} from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { FrontOfficeLinkFieldProps } from './types';
import useConfig from '../../hooks/useConfig';
import { getIconComponentFromName } from '../../helpers/icons';
import { Params } from '../../config/types';
import { get } from 'lodash';

const useStyles = makeStyles({
  container: {
    display: 'flex',
  },
  linkText: {
    fontSize: '12px',
  },
  copyLink: {
    position: 'relative',
    left: '6px',
    bottom: '3px',
  },
  tooltip: {},
});

const FrontOfficeLinkField: FunctionComponent<FrontOfficeLinkFieldProps> = (
  props
) => {
  const { externalLink, record, icon } = props;
  const { path, source, options } = externalLink;
  const config = useConfig();
  const classes = useStyles();
  const [tooltipCopyLinkStatusLabel, setTooltipCopyLinkStatusLabel] =
    useState('info');
  const t = useTranslate();

  if (typeof record === 'undefined') return null;

  const IconComponent = getIconComponentFromName(icon);
  const IconCopyLinkComponent = getIconComponentFromName('FileCopyRounded');
  const baseURL = `${location.protocol}${config.uiUrl}/${path}`;

  // This function return an empty string if there is no params,
  // otherwise it returns a string with all the params and their value.
  // ex: ?firstParam=firstParamValue&secondParam=secondParamValue
  const urlParameters = (): string => {
    if (typeof externalLink.params !== 'undefined') {
      const parameters = externalLink.params
        .reduce(
          (acc: string[], param: Params) => [
            ...acc,
            `${param.name}=${record[param.source]}`,
          ],
          []
        )
        .join('&');

      return `?${parameters}`;
    }

    return '';
  };

  const urlPath = (): string => {
    if (source && record[source]) {
      return `/${record[source]}`;
    }

    return '';
  };

  const link = `${baseURL}${urlPath()}${urlParameters()}`;

  const handleCopyLink = (e: any) => {
    e.preventDefault();
    navigator.clipboard.writeText(link);
    setTooltipCopyLinkStatusLabel('copied');
  };

  return (
    <div className={classes.container}>
      <MuiLink href={link} target="_blank" rel="noopener">
        {IconComponent ? (
          createElement(IconComponent)
        ) : (
          <MuiTypography className={classes.linkText}>{link}</MuiTypography>
        )}
      </MuiLink>
      {get(options, 'copy', false) && (
        <MuiTooltip
          title={t(`link.copy.${tooltipCopyLinkStatusLabel}`)}
          placement="top"
          arrow
          classes={{ tooltip: classes.tooltip }}
        >
          <MuiLink
            className={classes.copyLink}
            href=""
            onClick={handleCopyLink}
            target="_blank"
            rel="noopener"
          >
            {IconCopyLinkComponent && createElement(IconCopyLinkComponent)}
          </MuiLink>
        </MuiTooltip>
      )}
    </div>
  );
};

export default FrontOfficeLinkField;
