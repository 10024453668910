import React, { FunctionComponent } from 'react';
import { ButtonsFieldProps } from './types';
import { Button } from 'react-admin';

const ButtonsField: FunctionComponent<ButtonsFieldProps> = (
  props: ButtonsFieldProps
) => {
  const { record, source } = props;

  if (!record || !source || !Array.isArray(record[source])) return null;

  const buttons = record[source];

  return (
    <div>
      {buttons.map((buttonProps: Record<string, any>) => (
        <Button key={buttonProps.id} {...buttonProps} />
      ))}
    </div>
  );
};

export default ButtonsField;
