import React, { FunctionComponent, useRef, ReactElement } from 'react';
import FieldFactory from '../../factories/FieldFactory';
import { FieldProps } from './types';
import {
  useRecordContext,
  useResourceContext,
  Labeled,
  useNotify,
} from 'react-admin';
import { ExtractPropsFromFC } from '../../types';
import withExtensions from '../../extensions/withExtensions';
import checkRules from '../../helpers/rules';
import { useFormState } from 'react-final-form';

const Field: FunctionComponent<FieldProps> = (props) => {
  const {
    type,
    child,
    extensions,
    addLabel = false,
    label,
    rules,
    source,
    ...restProps
  } = props;
  const ComponentRef = useRef(
    withExtensions(extensions || [])(FieldFactory.getFieldComponent(type))
  );

  const Component = ComponentRef.current;
  const resource = useResourceContext();
  const record = useRecordContext();
  const formValues = (() => {
    try {
      return useFormState().values;
    } catch (e) {
      return {};
    }
  })();
  const notify = useNotify();

  // Child attribute must have a source,
  // because it reference another attribute resource
  const childProps = (function () {
    if (child && child.source)
      return {
        ...child,
        source: child.source.split('.')[1],
      } as FieldProps;

    return null;
  })();

  // Check if input has conditions to be displayed
  try {
    if (rules && rules.hide && checkRules(rules.hide, formValues)) return null;
  } catch (e) {
    notify(`"Hide" rule failed for field ${source}: ${e.message}`, 'error');
  }

  const disabled = (() => {
    try {
      return !!(
        rules &&
        rules.disabled &&
        checkRules(rules.disabled, formValues)
      );
    } catch (e) {
      notify(
        `"Disabled" rule failed for field ${source}: ${e.message}`,
        'error'
      );

      return false;
    }
  })();

  function addLabelToField(children: ReactElement) {
    return <Labeled label={label}>{children}</Labeled>;
  }

  function renderField() {
    return (
      <Component
        resource={resource}
        source={source}
        record={record}
        translateChoice={true}
        disabled={disabled}
        label={label}
        {...(restProps as ExtractPropsFromFC<typeof type>)}
      >
        {childProps && <Field {...childProps} />}
      </Component>
    );
  }

  if (addLabel && label) return addLabelToField(renderField());

  return renderField();
};

export default Field;
