import React, { ComponentType, FunctionComponent } from 'react';
import ModalFormButton from '../../components/BulkActionButtons/components/ModalFormButton';

/**
 * Create a clickable button for each format to download competencies
 *
 * @param Component
 * @param params
 */
function withModalForm(
  Component: ComponentType<any>,
  params: Record<string, any> | undefined
): FunctionComponent {
  const Extension: FunctionComponent<Record<string, any>> = (props) => {
    const { resource, record } = props;
    if (!params) return null;
    const { source, httpMethod, modalFormButtonParams } = params;

    return (
      <div>
        <ModalFormButton
          resource={resource}
          selectedIds={record[source]}
          {...modalFormButtonParams}
        />
      </div>
    );
  };

  Extension.displayName = `withExtension(ModalForm)`;

  return Extension;
}

export default withModalForm;
