import React from 'react';
import { FunctionComponent } from 'react';
import { ResourceLinkFieldProps } from './types';
import { Link as MuiLink } from '@material-ui/core';
import { useRecordContext, useTranslate } from 'react-admin';
import { ResourceLinkFilter } from '../../config/types/field';

const ResourceLinkField: FunctionComponent<ResourceLinkFieldProps> = (
  props
) => {
  const { label, resource, filters } = props;
  const record = useRecordContext();
  const t = useTranslate();
  const kind = record.kind;

  function resourceLink() {
    if (resource === 'users') {
      return kind === 'expert' ? 'experts' : 'clients';
    }

    return resource;
  }

  if (!label) return null;

  function buildFilterQueryParams() {
    return JSON.stringify(
      filters.reduce(
        (acc: Record<string, string>, filter: ResourceLinkFilter) => ({
          ...acc,
          [filter.key]: record[filter.source],
        }),
        {}
      )
    );
  }

  return (
    <MuiLink href={`/${resourceLink()}?filter=${buildFilterQueryParams()}`}>
      {t(label)}
    </MuiLink>
  );
};

export default ResourceLinkField;
