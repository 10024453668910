import { useGetOne } from 'react-admin';
import checkConditions from './conditions';
import { Rule } from '../config/types/field';

// Some conditions depends on data not present in the record
// This function return an object with the missing data
const getDataFromReference = (
  rules: Rule[],
  record: Record<string, string>
) => {
  if (!rules) return null;

  return rules.reduce((acc: any, rule: any) => {
    if (!rule.reference) return acc;
    const { resource, key } = rule.reference;
    const { data, error } = useGetOne(resource, record[key]);
    if (error && !rule.hideError) throw new Error(error);
    if (!data) return acc;
    const { property } = rule;

    return {
      ...acc,
      [property]: data[property],
    };
  }, {});
};

const checkRules = (
  rules: Rule[],
  record: Record<string, string>,
  satisfy: 'ALL' | 'ANY' = 'ANY'
): boolean =>
  rules && record
    ? checkConditions(
        {
          rules: rules,
          satisfy,
        },
        {
          ...record,
          ...getDataFromReference(rules, record),
        }
      )
    : false;

export default checkRules;
