import React, { ComponentType, FunctionComponent, useState } from 'react';
import { Confirm as RaConfirm } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { ObjectOf } from '../../types';

/**
 * Trigger a confirmation modal when input value is equal to 'client'
 * Reset value to previous if confirmation modal is canceled or close
 *
 * @param Component
 */
function withUserKindValidationModal(
  Component: ComponentType<any>
): FunctionComponent {
  const Extension: FunctionComponent<ObjectOf<any>> = (props) => {
    const form = useForm();
    const { values } = useFormState();

    const [open, setOpen] = useState(false);
    const [previousValue, setPreviousValue] = useState(values[props.source]);

    const handleConfirm = () => {
      setOpen(false);
    };

    const handleDialogClose = () => {
      setOpen(false);
      form.mutators.change(props.source, previousValue);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      if (inputValue === 'client') {
        setOpen(true);
      } else {
        setPreviousValue(inputValue);
      }
    };

    return (
      <>
        <Component {...props} onChange={handleChange} />
        <RaConfirm
          isOpen={open}
          title={'extension.with_user_kind_validation_modal.title'}
          content={'extension.with_user_kind_validation_modal.content'}
          onConfirm={handleConfirm}
          onClose={handleDialogClose}
        />
      </>
    );
  };
  Extension.displayName = `withExtension(UserKindValidationModal)`;

  return Extension;
}

export default withUserKindValidationModal;
