import React, { ComponentType, FunctionComponent, useMemo } from 'react';
import { Choice } from '../../config/types/field';
import {
  UNDER_REVIEW,
  WAITING_FOR_VALIDATION,
  STEP_TWO,
  STEP_THREE,
  STEP_FOUR,
} from '../../constants/candidacyStatus';

/**
 * Disable choices dynamically according to the current status
 *
 * @param Component
 */
function withDisabledCandidacyStatus(
  Component: ComponentType<any>
): FunctionComponent {
  const Extension: FunctionComponent<Record<string, any>> = (props) => {
    const { choices, source, record } = props;
    const value = record[source];

    // Define the statuses that should disable other
    const disabledStatusWhenCandidacyNotValidated = [
      STEP_TWO,
      STEP_THREE,
      STEP_FOUR,
    ];
    const disabledStatuses = [UNDER_REVIEW, WAITING_FOR_VALIDATION];

    const transformedChoices = useMemo(() => {
      if (disabledStatuses.includes(value)) {
        // If the current status is 'under_review' or 'waiting_for_validation',
        // disable 'step_two', 'step_three', and 'step_four'
        return choices.map((choice: Choice) => ({
          ...choice,
          disabled: disabledStatusWhenCandidacyNotValidated.includes(
            choice.id as string
          ),
        }));
      } else {
        // If the current status is not 'under_review' or 'waiting_for_validation',
        // disable 'under_review' and 'waiting_for_validation'
        return choices.map((choice: Choice) => ({
          ...choice,
          disabled: disabledStatuses.includes(choice.id as string),
        }));
      }
    }, [choices, value]);

    return <Component {...props} choices={transformedChoices} />;
  };

  Extension.displayName = `withExtension(DisabledCandidacyStatus)`;

  return Extension;
}

export default withDisabledCandidacyStatus;
