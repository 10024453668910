import { get } from 'lodash';

export function getErrorMessage(error: Record<string, any>) {
  const errorBody = get(error, 'error.body')
    ? get(error, 'error.body')
    : get(error, 'body');
  const errorMessage =
    get(errorBody, 'error') === 'RuntimeError'
      ? get(errorBody, 'message')
      : get(errorBody, 'error');

  return errorMessage;
}

export function handleError(error: Record<string, any>, notify: any) {
  const errorMessage = getErrorMessage(error);
  if (
    typeof errorMessage !== 'undefined' &&
    errorMessage !== 'ActiveRecord::RecordInvalid'
  ) {
    notify(`notify.error.${errorMessage}`, 'error', {
      id: error.id,
    });
  }
  if (errorMessage === 'ActiveRecord::RecordInvalid') {
    notify(error.message, 'error');
  } else {
    notify('notify.error.update', 'error');
  }
}
