import * as React from 'react';
import {
  CreateProps as RaCreateProps,
  Create as RaCreate,
  useRedirect,
  useNotify,
  useCreate,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { FunctionComponent, useCallback } from 'react';
import ActionButtons from '../ActionButtons';
import Form from '../Form';
import { transformValuesBeforeSubmit } from '../../helpers/form';
import { handleError } from '../../helpers/error';

const useStyles = makeStyles({
  card: {
    boxShadow: 'none',
    background: 'none',
  },
});

const Create: FunctionComponent<RaCreateProps> = (props) => {
  const classes = useStyles();
  const redirectTo = useRedirect();
  const notify = useNotify();
  const [create] = useCreate();

  const { basePath, resource: resourceName } = props;
  const page = 'create';

  const handleSave = useCallback(
    (resource) => (values: Record<string, any>, redirect: string) => {
      const updatedValues = transformValuesBeforeSubmit(resource, values);

      create(resourceName, updatedValues, {
        onSuccess: ({ data: newRecord }) => {
          redirectTo(redirect, basePath, newRecord.id, newRecord);
        },
        onFailure: (error: Record<string, any>) => {
          handleError(error, notify);
        },
      });
    },
    [redirectTo, basePath, create, transformValuesBeforeSubmit]
  );

  return (
    <RaCreate
      {...props}
      classes={classes}
      actions={
        <ActionButtons
          basePath={basePath}
          resource={props.resource}
          page={page}
        />
      }
    >
      <Form
        {...props}
        page={page}
        handleSave={handleSave}
        resource={resourceName}
      />
    </RaCreate>
  );
};

export default Create;
