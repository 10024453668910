import {
  EmailField as RaEmailField,
  TextField as RaTextField,
  ReferenceField as RaReferenceField,
  BooleanField as RaBooleanField,
  DateField as RaDateField,
  SelectField as RaSelectField,
  NumberField as RaNumberField,
  UrlField as RaUrlField,
  ImageField as RaImageField,
  ArrayField as RaArrayField,
  RichTextField as RaRichTextField,
  ReferenceArrayField as RaReferenceArrayField,
} from 'react-admin';
import { FieldType } from '../config/types/field';
import PhoneField from '../components/PhoneField';
import FileField from '../components/FileField';
import RangeField from '../components/RangeField';
import CompanyField from '../components/CompanyField';
import ButtonsField from '../components/ButtonsField';
import TranslatedTextField from '../components/TranslatedTextField';
import ArrayField from '../components/ArrayField';
import ResourceLinkField from '../components/ResourceLinkField';
import FrontOfficeLinkField from '../components/FrontOfficeLinkField';
import UrlField from '../components/UrlField';
import RecommendationField from '../components/RecommendationField';
import LocationInput from '../components/LocationInput';

export default class FieldFactory {
  static fieldMap = {
    array: ArrayField,
    autocomplete: RaTextField,
    autocompleteArray: RaArrayField,
    boolean: RaBooleanField,
    buttons: ButtonsField,
    company: CompanyField,
    country: RaSelectField,
    date: RaDateField,
    dateTime: RaDateField,
    email: RaEmailField,
    file: FileField,
    frontOfficeLink: FrontOfficeLinkField,
    image: RaImageField,
    location: LocationInput,
    multipleSelect: RaArrayField,
    number: RaNumberField,
    phone: PhoneField,
    range: RangeField,
    reference: RaReferenceField,
    referenceArray: RaReferenceArrayField,
    resourceLink: ResourceLinkField,
    select: RaSelectField,
    text: RaTextField,
    // TODO: change the tag component if the tag become more complex
    tag: RaTextField,
    translatedText: TranslatedTextField,
    url: UrlField,
    wysiwyg: RaTextField,
    recommendation: RecommendationField,
  };

  static getFieldComponent<T extends FieldType>(
    type: T
  ): typeof FieldFactory.fieldMap[T] {
    if (Object.keys(FieldFactory.fieldMap).includes(type))
      return FieldFactory.fieldMap[type];
    throw new Error(`Field type ${type} does not exists`);
  }
}
