// Change title and id name into charge_title and charge_id to avoid react-admin mistake
// in the ModalForm. Otherwise react-admin give to the id and the title the values of
// the contract_milestone id and contract_milestone title
export const formatCharges = (charges: Record<string, any>[] = []) =>
  charges.reduce((acc: Record<string, any>[], charge: Record<string, any>) => {
    const { title, id, ...rest } = charge;

    return [
      ...acc,
      {
        ...rest,
        charge_id: charge.id,
        charge_title: charge.title,
      },
    ];
  }, []);

// Before sending the charges data to the API
// the formatCharges function effect have to be undo
export const reformatCharges = (charges: Record<string, any>[] = []) =>
  charges.reduce((acc: Record<string, any>[], charge: Record<string, any>) => {
    const { charge_title, charge_id, ...rest } = charge;

    return [
      ...acc,
      {
        ...rest,
        ...(charge_id && {
          id: charge.charge_id,
        }),
        title: charge.charge_title,
      },
    ];
  }, []);
