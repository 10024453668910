import { isEmpty } from 'lodash';
import React, { FunctionComponent } from 'react';
import { useTranslate } from 'react-admin';
import { DateFieldProps } from './type';

const DateField: FunctionComponent<DateFieldProps> = (props) => {
  const { details } = props;
  const t = useTranslate();

  return (
    <span>
      {t(`${details.translation_key}.details`, {
        before:
          details.args !== undefined && !isEmpty(details.args.before)
            ? new Date(details.args.before).toLocaleDateString('fr')
            : t('history.company.field_updated.no_tags'),
        after:
          details.args !== undefined && !isEmpty(details.args.after)
            ? new Date(details.args.after).toLocaleDateString('fr')
            : t('history.company.field_updated.no_tags'),
      })}
    </span>
  );
};

export default DateField;
