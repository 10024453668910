import { Config as ConfigType } from './types';
import { user } from './kicklox/user';
import { expert } from './kicklox/expert';
import { client } from './kicklox/client';
import { company } from './kicklox/company';
import { mission } from './kicklox/mission';
import { invitation } from './kicklox/invitation';
import { candidate } from './kicklox/candidate';
import { candidacy } from './kicklox/candidacy';
import { proposal } from './kicklox/proposal';
import { quotation } from './kicklox/quotation';
import { charge } from './kicklox/charge';
import { milestone } from './kicklox/milestone';
import { contract } from './kicklox/contract';
import { contractMilestone } from './kicklox/contractMilestone';
import { invoice } from './kicklox/invoice';
import { recommendation } from './kicklox/recommendation';
import { legalInformation } from './kicklox/legalInformation';
import { cgu } from './kicklox/cgu';
import { match } from './kicklox/match';
import { document } from './kicklox/document';
import { subCategory } from './kicklox/subCategory';
import { documentFile } from './kicklox/documentFile';
import { documentsCompanies } from './kicklox/documentsCompanies';
import { tag } from './kicklox/tag';
import { partnerMission } from './kicklox/partnerMission';
import { clientInvoice } from './kicklox/clientInvoice';
import { expertInvoice } from './kicklox/expertInvoice';

export const config: ConfigType = {
  login: 'password',
  hash: '4rr0tdosatvzb70almrhxtkbk',
  locale: 'fr',
  name: 'kicklox',
  dashboard: true,
  theme: {
    primary: '#0C073C',
    secondary: '#364CBB',
    info: '#f8dddd',
  },
  defaultCountry: 'fr',
  preferredCountries: ['fr', 'ma', 'ch', 'tn'],
  resources: [
    expert,
    client,
    user,
    subCategory,
    company,
    mission,
    partnerMission,
    invitation,
    candidate,
    candidacy,
    proposal,
    quotation,
    charge,
    milestone,
    contract,
    contractMilestone,
    invoice,
    recommendation,
    legalInformation,
    document,
    documentFile,
    documentsCompanies,
    cgu,
    match,
    tag,
    expertInvoice,
    clientInvoice,
  ],
};

export default config;
