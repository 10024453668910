import { ThemeOptions as MuiThemeOptions } from '@material-ui/core';
import {
  Config as ConfigType,
  Login as LoginType,
  Resource as ResourceType,
} from '../config/types';
import { Attribute } from '../config/types/field';
import { createTheme } from '../helpers/theme';
import { ObjectOf } from '../types';
import Resource from './Resource';

export default class Config {
  private readonly _resources: ObjectOf<Resource>;
  private readonly _login: LoginType;
  private readonly _locale: string;
  private readonly _name: string;
  private readonly _dashboard: boolean;
  private readonly _hash: string;
  private readonly _theme: MuiThemeOptions;
  private readonly _defaultCountry: string | undefined;
  private readonly _preferredCountries: string[] | undefined;
  private readonly _resourcesAccess: boolean;

  constructor(config: ConfigType) {
    // Transform array of Resources into an object of Resources
    // To simplify resource searches
    this._resources = config.resources.reduce(
      (acc: ObjectOf<Resource>, curr: ResourceType) => ({
        ...acc,
        [curr.name]: new Resource(curr, this),
      }),
      {}
    );
    this._login = config.login;
    this._locale = config.locale;
    this._name = config.name;
    this._dashboard = config.dashboard;
    this._hash = config.hash;
    this._theme = createTheme(config.theme);
    this._defaultCountry = config.defaultCountry;
    this._preferredCountries = config.preferredCountries;
    this._resourcesAccess = config.resourcesAccess || false;
  }

  get resources(): Resource[] {
    return Object.values(this._resources);
  }

  get dashboard(): boolean {
    return this._dashboard;
  }

  get theme(): MuiThemeOptions {
    return this._theme;
  }

  get defaultCountry(): string | undefined {
    return this._defaultCountry;
  }

  get preferredCountries(): string[] | undefined {
    return this._preferredCountries;
  }

  get resourcesAccess(): boolean {
    return this._resourcesAccess;
  }

  getResource(resource: string): Resource {
    if (!this._resources[resource]) {
      throw new Error(`Resource ${resource} does not exists`);
    }

    return this._resources[resource];
  }

  /**
   * Get an attribute for any resource
   *
   * @param name Should have the following structure : <resource>.<attributeKey>
   */
  getAttribute(name: string): Attribute {
    const [resource, attribute] = name.split('.');

    return this.getResource(resource).getAttribute(attribute);
  }

  get apiUrl(): string {
    if (process.env.REACT_APP_API_URL) {
      return process.env.REACT_APP_API_URL.replace('#{tenant}', this._name);
    }
    throw new Error('API url is not defined');
  }

  get authenticatorUrl(): string {
    if (process.env.REACT_APP_AUTHENTICATOR_URL) {
      return process.env.REACT_APP_AUTHENTICATOR_URL.replace(
        '#{hash}',
        this._hash
      );
    }
    throw new Error('Authenticator url is not defined');
  }

  get uiUrl(): string {
    if (process.env.REACT_APP_UI_URL) {
      return process.env.REACT_APP_UI_URL.replace('#{tenant}', this._name);
    }

    return '';
  }

  get dataStudioURL(): string {
    if (
      typeof process.env[
        `REACT_APP_DATA_STUDIO_${this._name.toUpperCase()}_URL`
      ] !== 'undefined'
    ) {
      return process.env[
        `REACT_APP_DATA_STUDIO_${this._name.toUpperCase()}_URL`
      ]!;
    }

    return '';
  }

  get locale(): string {
    return this._locale;
  }

  get name(): string {
    return this._name;
  }

  get login(): LoginType {
    return this._login;
  }
}
