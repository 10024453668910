import { Resource as ResourceType } from '../types';

export const tag: ResourceType = {
  name: 'tags',
  label: 'resource.categories.label',
  hideResource: true,
  attributes: [
    {
      key: 'id',
      label: 'resource.candidates.field.id.label',
      type: 'text',
    },
    {
      key: 'name',
      label: 'resource.candidates.field.name.label',
      type: 'text',
    },
    {
      key: 'kind_name',
      label: 'resource.candidates.field.kind_name.label',
      type: 'text',
    },
  ],
  pages: {
    list: {
      fields: [],
    },
    edit: {
      left: {
        tabs: [
          {
            key: '',
            title: '',
            sections: [
              {
                key: '',
                title: '',
                layout: [],
                inputs: {},
                fields: {},
              },
            ],
          },
        ],
      },
    },
  },
};
