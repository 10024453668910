import React, { FunctionComponent } from 'react';
import { ReferenceArrayInputProps } from './types';
import { ReferenceArrayInput as RaReferenceArrayInput } from 'react-admin';
import Input from '../Input';

const ReferenceArrayInput: FunctionComponent<ReferenceArrayInputProps> = (
  props: ReferenceArrayInputProps
) => {
  const { child, source, enableGetChoices } = props;
  const optionsChild = child?.options || {};

  const childProps = child?.type
    ? {
        ...child,
        source,
        key: '',
      }
    : null;

  return (
    <RaReferenceArrayInput
      {...props}
      {...optionsChild}
      child={child}
      {...(enableGetChoices && {
        enableGetChoices: ({ q }: { q: string }) => (q ? q.length >= 2 : false),
      })}
    >
      {childProps && (
        <Input
          {...childProps}
          {...(enableGetChoices && {
            shouldRenderSuggestions: (value: string) => value.length >= 2,
          })}
        />
      )}
    </RaReferenceArrayInput>
  );
};
export default ReferenceArrayInput;
