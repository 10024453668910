import React, { FunctionComponent } from 'react';
import { SelectInput as RaSelectInput } from 'react-admin';
import { CountryInputProps } from './types';
import countries from '../../constants/countries';

const CountryInput: FunctionComponent<CountryInputProps> = (props) => {
  const { source, ...restProps } = props;

  return <RaSelectInput source={source} choices={countries} {...restProps} />;
};

export default CountryInput;
