import React, { ComponentType, FunctionComponent } from 'react';

/**
 * Transform attachments object into clickable button to download a pdf for each attachment
 *
 * @param Component
 */
function withAttachmentsFiles(
  Component: ComponentType<any>
): FunctionComponent {
  const Extension: FunctionComponent<Record<string, any>> = (props) => {
    const { record, source } = props;

    if (!record || !record[source]) return null;

    const buttons = (
      Array.isArray(record[source]) ? record[source] : [record[source]]
    ).map((attachment: Record<string, any>) => ({
      id: attachment.name,
      href: attachment.public_url,
      label: attachment.name,
      target: '_blank',
      rel: 'noopener',
    }));

    return <Component {...props} record={{ ...record, [source]: buttons }} />;
  };

  Extension.displayName = `withExtension(AttachmentsFiles)`;

  return Extension;
}

export default withAttachmentsFiles;
