import React, { ComponentType, FunctionComponent, useCallback } from 'react';

/**
 * Concatenate two params in a select choice
 *
 * @param Component
 * @param params
 */
function withConcatenateParams(
  Component: ComponentType<any>,
  params: Record<string, any> | undefined
): FunctionComponent {
  const Extension: FunctionComponent<Record<string, any>> = (props) => {
    if (!params) return null;
    const { first, second } = params;
    const optionRenderer = useCallback((choice: Record<string, any>) => {
      if (choice) return `${choice[first]} - ${choice[second]}`;

      return null;
    }, []);

    return <Component {...props} optionText={optionRenderer} />;
  };

  Extension.displayName = `withExtension(ConcatenateParams)`;

  return Extension;
}

export default withConcatenateParams;
