import React, { createElement, FunctionComponent, useRef } from 'react';
import { Link as MuiLink } from '@material-ui/core';
import { UrlFieldProps } from './types';
import { getIconComponentFromName } from '../../helpers/icons';

const UrlField: FunctionComponent<UrlFieldProps> = (props) => {
  const { record, source, icon } = props;

  if (!record || !source || !record[source]) return null;

  const IconComponent = getIconComponentFromName(icon);
  const link = record[source];

  return (
    <MuiLink href={link} target="_blank" rel="noopener">
      {IconComponent ? createElement(IconComponent) : link}
    </MuiLink>
  );
};

export default UrlField;
