import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PhoneNumber from 'awesome-phonenumber';
import ReactCountryFlag from 'react-country-flag';
import { PhoneFieldProps } from './types';

const useStyles = makeStyles({
  flag: {
    height: '1em',
    verticalAlign: 'middle',
    marginRight: '8px',
  },
  phone: {
    textDecoration: 'none',
    color: 'inherit',
    verticalAlign: 'middle',
  },
});

const PhoneField: FunctionComponent<PhoneFieldProps> = (
  props: PhoneFieldProps
) => {
  const { record, source } = props;
  const classes = useStyles();

  if (!record || !source || !record[source]) return null;

  const pn = new PhoneNumber(record[source]);
  const regionCode = pn.getRegionCode();

  return (
    <div>
      {regionCode && (
        <ReactCountryFlag
          countryCode={regionCode.toUpperCase()}
          svg
          className={classes.flag}
          title={regionCode.toUpperCase()}
        />
      )}
      <a className={classes.phone} href={`tel:${pn.getNumber('e164')}`}>
        {pn.getNumber('national')}
      </a>
    </div>
  );
};

export default PhoneField;
