import React, { FunctionComponent, useState, createElement } from 'react';
import {
  StepLabel as MuiStepLabel,
  Typography as MuiTypography,
  Box as MuiBox,
  Button as MuiButton,
  IconButton as MuiIconButton,
  makeStyles,
} from '@material-ui/core';
import {
  useTranslate,
  FormWithRedirect as RaFormWithRedirect,
  SaveButton as RaSaveButton,
  TextInput as RaTextInput,
} from 'react-admin';
import { NoteProps } from './types';
import { getIconComponentFromName } from '../../../helpers/icons';
import {
  getLocaleDateString,
  getLocaleTimeString,
} from '../../../helpers/date';
import { historyIcons } from '../../../constants/history';
import { httpClient } from '../../../providers/dataProvider';
import { config } from '../../../helpers/config';

const useStyles = makeStyles({
  root: {
    marginBottom: '1rem',
  },
  label: {
    color: 'black',
    fontWeight: 600,
    fontSize: '14px',
  },
  iconContainer: {
    color: 'grey',
  },
  content: {
    marginTop: '0px',
    color: 'grey',
    paddingBottom: '20px',
  },
  title: {
    color: 'black',
    fontSize: '14px',
  },
  subtitle: {
    color: 'black',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: '5px',
      width: '20px',
    },
  },
  details: {
    fontSize: '14px',
    backgroundColor: (config: Record<string, any>) => config.palette.info.main,
    padding: '0.5rem',
    borderRadius: '4px',
    cursor: 'pointer',
    width: '80%',
  },
  separator: {
    padding: '0px 2px',
  },
  date: {
    marginLeft: '5px',
  },
  input: {
    marginBottom: '0.5rem',
    '& p': {
      display: 'none',
    },
  },
  note: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  cancel: {
    marginRight: '1rem',
    color: '#DC004E',
    backgroundColor: 'transparent',
    fontSize: '11px',
    '&:hover': {
      backgroundColor: 'rgba(220, 0, 78, 0.15)',
    },
  },
  save: {
    fontSize: '11px',
  },
  delete: {
    marginLeft: 'auto',
    display: 'flex',
    color: '#DC004E',
    backgroundColor: 'rgba(220, 0, 78, 0.04)',
    '&:hover': {
      backgroundColor: 'rgba(220, 0, 78, 0.15)',
    },
  },
  buttons: {
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const Note: FunctionComponent<NoteProps> = (props) => {
  const { history } = props;
  const t = useTranslate();
  const classes = useStyles(config.theme);
  const [edit, setEdit] = useState<boolean>(false);

  const icon = getIconComponentFromName(historyIcons['note']);
  const deleteIcon = getIconComponentFromName('Delete');

  // Logic when a note is updated
  const handleSubmit = (values: Record<string, any>) => {
    const { note } = values;
    httpClient(`${config.apiUrl}/histories/${props.history.id}`, {
      method: 'PUT',
      body: JSON.stringify({ note }),
    })
      .then(() => {
        props.updateHistory();
        setEdit(false);
      })
      .catch((error: Record<string, any>) => ({ error }));
  };

  // Logic when a note is deleted
  const handleDelete = () => {
    httpClient(`${config.apiUrl}/histories/${props.history.id}`, {
      method: 'DELETE',
    })
      .then(() => {
        props.updateHistory();
      })
      .catch((error: Record<string, any>) => ({ error }));
  };

  // This function returns the date and the hour formatted
  const getHistoryDate = (date: string): JSX.Element => (
    <>
      <span>{getLocaleDateString(date)}</span>
      <span className={classes.date}>{getLocaleTimeString(date)}</span>
    </>
  );

  // Function to return the initial value of the note, necessary for the update
  const handleInitialValue = ((): string => {
    if (
      history.details &&
      history.details.args &&
      history.details.args.content
    ) {
      return history.details.args.content;
    }

    return '';
  })();

  const StepLabel = (): JSX.Element => (
    <MuiStepLabel
      StepIconComponent={icon}
      classes={{ iconContainer: classes.iconContainer }}
    >
      <MuiTypography className={classes.label}>
        <span>{t('history.note', { admin: history.admin_fullname })}</span>
        <span className={classes.date}>
          {getHistoryDate(history.created_at)}
        </span>
      </MuiTypography>
    </MuiStepLabel>
  );

  return (
    <div className={classes.root}>
      {!edit ? (
        <>
          {StepLabel()}
          <div className={classes.note}>
            {history.details &&
              history.details.args &&
              history.details.args.content && (
                <>
                  <MuiBox
                    className={classes.details}
                    onClick={() => {
                      setEdit(true);
                    }}
                  >
                    {history.details.args.content}
                  </MuiBox>
                </>
              )}
            <MuiIconButton
              size="small"
              onClick={handleDelete}
              className={classes.delete}
            >
              {deleteIcon && createElement(deleteIcon)}
            </MuiIconButton>
          </div>
        </>
      ) : (
        <>
          {StepLabel()}
          <RaFormWithRedirect
            resource="histories"
            initialValues={{ note: handleInitialValue }}
            render={({ handleSubmitWithRedirect, saving }) => (
              <>
                <RaTextInput
                  source="note"
                  multiline
                  resettable
                  fullWidth
                  className={classes.input}
                />
                <div className={classes.buttons}>
                  <MuiButton
                    variant="text"
                    color="primary"
                    size="small"
                    onClick={() => {
                      setEdit(false);
                    }}
                    className={classes.cancel}
                  >
                    {t('history.note.cancel')}
                  </MuiButton>
                  <RaSaveButton
                    handleSubmitWithRedirect={handleSubmitWithRedirect}
                    saving={saving}
                    onSave={handleSubmit}
                    size="small"
                    className={classes.save}
                  />
                </div>
              </>
            )}
          />
        </>
      )}
    </div>
  );
};

export default Note;
