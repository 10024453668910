import { fetchUtils, UpdateManyParams, UpdateManyResult } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import { config } from '../helpers/config';
import { getToken, isAuthenticated } from '../helpers/auth';

export const httpClient = (
  url: string,
  options: fetchUtils.Options = {}
): Promise<{
  status: number;
  headers: Headers;
  body: string;
  json: unknown;
}> => {
  options.headers = fetchUtils.createHeadersFromOptions({
    ...options,
    user: {
      authenticated: isAuthenticated(),
      token: `Bearer ${getToken()}`,
    },
  });

  return fetchUtils.fetchJson(url, options);
};

const dataProvider = {
  ...simpleRestProvider(config.apiUrl, httpClient),
  fetch(
    resource: string,
    params: { path: string },
    options: Record<string, unknown> = {}
  ): Promise<unknown> {
    const url = `${config.apiUrl}/${resource}/${params.path}`;
    const headers = new Headers({ Accept: 'application/json' });
    headers.set('Authorization', `Bearer ${getToken()}`);

    return fetchUtils
      .fetchJson(url, { ...options, headers })
      .then(({ json }) => ({ data: json }));
  },
  updateMany: (
    resource: string,
    params: UpdateManyParams
  ): Promise<UpdateManyResult> =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${config.apiUrl}/${resource}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        })
          .then((response: Record<string, any>) => ({ response, id }))
          .catch((error: Record<string, any>) => ({ error, id }))
      )
    ).then((responses: Record<string, any>) => {
      const errors = responses.filter((response: Record<string, any>) => {
        if (response.error) return response;
      });
      if (errors.length) {
        throw errors;
      }

      return {
        data: responses.map((response: Record<string, any>) => response),
      };
    }),
};

export default dataProvider;
