import React, {
  ComponentType,
  FunctionComponent,
  useRef,
  useState,
} from 'react';
import { useNotify, useDataProvider } from 'react-admin';

/**
 * Create a clickable button to download minutes file in docx
 * Extension inspired by the CompetenciesFile's one
 *
 * @param Component
 * @param params
 */
function withMinuteFile(
  Component: ComponentType<any>,
  params: Record<string, any> | undefined
): FunctionComponent {
  const Extension: FunctionComponent<Record<string, any>> = (props) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const [links, setLinks] = useState<Record<number, string>>({});
    const ref = useRef<HTMLAnchorElement>(null);

    const { resource, record, source } = props;

    if (!resource || !record || !source) return null;

    function downloadFile(format: string, index: number) {
      const resourceToFetch = (params && params.resourceToFetch) || resource;

      setLoading(true);

      // TODO its a temporary react admin 3.19.4 fix it needs to be properly fixed
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dataProvider
        .fetch(
          resourceToFetch,
          {
            path: `${record[source]}/download_minutes_template`,
          },
          {
            method: 'GET',
          }
        )
        .then(({ data }: { data: Record<string, string> }) => {
          if (ref && ref.current) {
            const element = ref.current;
            element.setAttribute('href', data.url);
            element.setAttribute('download', format);
            element.click();
            setLinks({ ...links, [index]: data.url });
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          notify('notify.error.download', 'error');
        });
    }

    const handleClick = (format: string, index: number) => () => {
      const href = links[index];
      if (!href) downloadFile(format, index);
      else if (ref && ref.current) {
        const element = ref.current;
        element.setAttribute('href', href);
        element.setAttribute('download', format);
        element.click();
      }
    };

    const buttons = [
      {
        onClick: handleClick('docx', 0),
        label: `formats.docx.label`,
        key: 'docx',
        disabled: loading,
      },
    ];

    return (
      <>
        <Component {...props} record={{ ...record, [source]: buttons }} />
        <a ref={ref} />
      </>
    );
  };

  Extension.displayName = `withExtension(MinuteFile)`;

  return Extension;
}

export default withMinuteFile;
