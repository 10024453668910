import {
  required as RaRequired,
  minLength as RaMinLength,
  maxLength as RaMaxLength,
  minValue as RaMinValue,
  maxValue as RaMaxValue,
  number as RaNumber,
  email as RaEmail,
  regex as RaRegex,
} from 'react-admin';
import { Validator as RaValidator } from 'ra-core';
import { InputValidatorType } from '../config/types/field';
import { iban, bic, linkedinUrl, maxArrayLength } from '../helpers/validation';

export type OneParamValidator = (_message: string) => RaValidator;
export type TwoParamValidator = (
  _value: number | string,
  _message: string
) => RaValidator;

type propsMap = {
  required: OneParamValidator;
  minLength: TwoParamValidator;
  maxLength: TwoParamValidator;
  minValue: TwoParamValidator;
  maxValue: TwoParamValidator;
  number: OneParamValidator;
  email: OneParamValidator;
  url: TwoParamValidator;
  iban: OneParamValidator;
  bic: OneParamValidator;
  linkedinUrl: OneParamValidator;
  maxArrayLength: TwoParamValidator;
};

export type Validator<T> = T extends keyof propsMap ? propsMap[T] : never;

export default class ValidatorFactory {
  private static validatorMap: {
    [prop: string]: Validator<InputValidatorType>;
  } = {
    required: RaRequired,
    minLength: RaMinLength,
    maxLength: RaMaxLength,
    minValue: RaMinValue,
    maxValue: RaMaxValue,
    number: RaNumber,
    email: RaEmail,
    url: RaRegex,
    iban: iban,
    bic: bic,
    linkedinUrl: linkedinUrl,
    maxArrayLength: maxArrayLength,
  };

  static getValidator(key: string): Validator<InputValidatorType> {
    if (Object.keys(ValidatorFactory.validatorMap).includes(key))
      return ValidatorFactory.validatorMap[key];
    throw new Error(`Validator ${key} does not exists`);
  }
}
