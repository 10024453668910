import { makeStyles } from '@material-ui/core';
import { isEmpty, isNull } from 'lodash';
import React, { FunctionComponent, createElement } from 'react';
import { useTranslate } from 'react-admin';
import { TagFieldProps } from './type';
import { Chip as MuiChip } from '@material-ui/core';
import { HistoryUpdatedField } from '../../../../../../config/types/history';
import { translationField } from '../../../../../../helpers/history';
import { getIconComponentFromName } from '../../../../../../helpers/icons';
import { AFTER_CONTENT_ICON } from '../../../../../../constants/history';

const useStyles = makeStyles({
  tag: {
    margin: '2px',
  },
  icon: {
    '& svg': {
      fontSize: '0.9rem',
      verticalAlign: 'text-bottom',
      marginLeft: '4px',
    },
  },
});

const getContentExtraTag = (
  extraTagValues: Record<string, any>,
  itemValueWithoutExtraTag: string
) => {
  const classes = useStyles();
  switch (extraTagValues['type']) {
    case AFTER_CONTENT_ICON: {
      const icon = getIconComponentFromName(extraTagValues['icon']);

      return (
        <>
          {itemValueWithoutExtraTag}
          <span className={classes.icon}>
            {icon !== undefined && !isNull(icon) && createElement(icon)}
          </span>
        </>
      );
    }
    default:
      return itemValueWithoutExtraTag;
  }
};
const getItemValueWithExtraTag = (itemValue: string) => {
  // extract extra tag if present
  const execExtraTag = /EXTRA_TAG{.*?}/gm.exec(itemValue);
  if (!isNull(execExtraTag)) {
    const extraTag = execExtraTag[0];
    const itemValueWithoutExtraTag = execExtraTag.input.slice(
      0,
      execExtraTag.index
    );

    // parse extra tag content
    const extraTagContentString = /EXTRA_TAG({.*?})/gm.exec(extraTag);
    if (!isNull(extraTagContentString)) {
      const extraTagValues = JSON.parse(extraTagContentString[1]);

      return getContentExtraTag(extraTagValues, itemValueWithoutExtraTag);
    }
  }

  return itemValue;
};

const tagField = (
  itemValue: string,
  translation_key: HistoryUpdatedField | string
) => {
  const classes = useStyles();
  const translate_label = translationField(itemValue, translation_key);

  const itemValueWithExtraTag = getItemValueWithExtraTag(
    translate_label ? translate_label : itemValue
  );

  return (
    <MuiChip
      key={itemValue}
      label={itemValueWithExtraTag}
      className={classes.tag}
    />
  );
};

const showTags = (
  arrayFields: string[],
  translation_key: HistoryUpdatedField | string
) =>
  arrayFields.map((itemTag: string) => (
    <span key={itemTag}>{tagField(itemTag, translation_key)}</span>
  ));

const TagField: FunctionComponent<TagFieldProps> = (props) => {
  const t = useTranslate();
  const { details } = props;

  return (
    <span>
      {t(`${details.translation_key}.details`)}
      {details.args !== undefined && (
        <>
          {details.args.before !== null && (
            <>
              {details.args.before &&
                (!isEmpty(details.args.before)
                  ? showTags(details.args.before, details.translation_key)
                  : t('history.company.field_updated.no_tags'))}
              {' → '}
            </>
          )}

          {details.args !== undefined &&
          details.args.after &&
          !isEmpty(details.args.after)
            ? showTags(details.args.after, details.translation_key)
            : t('history.company.field_updated.no_tags')}
        </>
      )}
    </span>
  );
};

export default TagField;
