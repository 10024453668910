import React, { ComponentType, FunctionComponent } from 'react';

/**
 * Multiply ratio value by 100 (to look like a percentage), then divide the output by hundred (to avoid multiplying by 100 at every modification)
 *
 * @param Component
 */
function withMultiplyByHundred(
  Component: ComponentType<any>
): FunctionComponent {
  const Extension: FunctionComponent<Record<string, any>> = (props) => {
    const { record, source } = props;

    if (!record || !source) return null;

    const formatValue = (v: number): number => {
      if (typeof v === 'number') {
        return parseFloat((v * 100).toFixed(4));
      }

      return v;
    };

    const parseValue = (v: string): number => {
      const value = parseFloat(v);

      return parseFloat(value.toFixed(4)) / 100;
    };

    return (
      <Component
        {...props}
        record={{ ...record }}
        format={(v: number) => formatValue(v)}
        parse={(v: string) => parseValue(v)}
      />
    );
  };

  Extension.displayName = `withExtension(MultiplyByHundredInput)`;

  return Extension;
}

export default withMultiplyByHundred;
