import React, { FunctionComponent } from 'react';
import { Layout as RaLayout, LayoutProps } from 'react-admin';
import Notification from '../Notification';
import Menu from '../Menu';

const Layout: FunctionComponent<LayoutProps> = (props) => (
  <RaLayout {...props} notification={Notification} menu={Menu} />
);

export default Layout;
